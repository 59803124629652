import React, { useState, useEffect } from "react";
import toaster from "toasted-notes";
import { useQuery, useMutation, useQueryCache } from "react-query";
import CustomiserUnit from "../../../../components/CustomiserUnit";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import Select from "@atlaskit/select";
import Textfield from "@atlaskit/textfield";
import Button from "../../../../components/Button";
import { Formik } from "formik";
import { useGraphQl } from "../../../../GraphqlClient";
import { useWorkspace } from "../../../../WorkspaceContext";
import {
  queryWidgetSettings,
  updateWidgetSettings,
} from "../../../../api/widgets";
import {langOptions} from "../../../../utilities/variables"

export default function FaqPage() {
  const graphQL = useGraphQl();
  const workspace= useWorkspace();
  const queryCache = useQueryCache();

  const [currentLanguages, setCurrentLanguages] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState();
  const [initialLanguage, setInitialLanguage] = useState();


  const { data, refetch } = useQuery("queryWidgetSettings", () =>
    graphQL(queryWidgetSettings)
  );

  const updateWidget = async (values) => {
    await graphQL(updateWidgetSettings, {
      widgetSettingsId: values.id,
      widgetSettingsInput: values.config,
    });
    refetch();
  };

  const [updateWidgetMutation] = useMutation(updateWidget, {
    onSuccess: () => {
      queryCache.invalidateQueries("queryWidgetSettings");
      toaster.notify("Widget settings saved successfully", {
        duration: 2000,
      });
    },
  });

  // This useEffect triggers when selected language is changed and when data is updated after saving
  // And this combines the data from db and the default values saved in i18n so that even if user doesnt have certain language values in his db it will be filled from the default values

  useEffect(async () => {
      if (selectedLanguage && data) {
        let currentLangJSON =
          data &&
          data.widgets_cornerwidget[0].faq_lang[`${selectedLanguage}`];
        let defaultValues =
          await require(`../../../../i18n/default-values/faq-page/${selectedLanguage}.json`);
        setInitialLanguage({ ...defaultValues, ...currentLangJSON });
      }
    }, [data,selectedLanguage]);


  useEffect(() => {
    if (data) {
      let langConfig = data.widgets_cornerwidget[0].faq_lang;
      let langArray = Object.keys(langConfig);
      setCurrentLanguages(langArray);
      setSelectedLanguage(data.workspaces[0].source.default_language);
    }
  }, [data]);

  if(initialLanguage)
  return (
    <Formik
      enableReinitialize
      initialValues={initialLanguage}
      onSubmit={(values) => {
        const faq_lang = {faq_lang:{...data.widgets_cornerwidget[0].faq_lang,[`${selectedLanguage}`]:values}}
        
        updateWidgetMutation({
          id: { id: data && data.widgets_cornerwidget[0].id },
          config: faq_lang,
        });
      }}
    >
      {(props) => (
        <div className=" h-full overflow-y-auto">
          <div className=" sticky top-0 flex desktop:p-4 p-2 border-b bg-white items-center justify-between">
            <div className="px-3 py-2">
              <h2 className="text-blue-900 font-bold mb-2 desktop:text-xl text-lg">
                FAQ Page UI Text editor
              </h2>
              <p className="text-tiny desktop:text-base">
                From this page, you can edit all customer facing UI copy/text
                of the FAQ page
              </p>
              {!props.dirty ? (
                <div className="flex items-center mt-5">
                  <p className="font-semibold">Select Language</p>
                  {data && (
                    <Select
                    isSearchable={false}
                      value={langOptions.find(
                        (option) => option.value === selectedLanguage
                      )}
                      onChange={(e) => {
                        setSelectedLanguage(e.value);
                      }}
                      isDisabled={props.dirty}
                      className="w-48 ml-2"
                      classNamePrefix="react-select"
                      options={langOptions.filter((item) =>
                        currentLanguages.includes(item.value)
                      )}
                    />
                  )}
                </div>
              ) : (
                <div className="p-3 mt-5 border rounded-md">
                  <small>
                    <b>{langOptions.filter(item=>item.value===selectedLanguage)[0].label}:</b>
                    {" "}
                    Please save or discard the current changes before switching
                    languages.
                  </small>
                </div>
              )}
            </div>
            <div className="mr-4 flex shrink-0">
              {props.dirty && (
                <Button
                  type="link"
                  onClick={() => {
                    props.resetForm();
                  }}
                >
                  Discard changes
                </Button>
              )}
              <Button onClick={()=>props.submitForm()} type="primary" disabled={!props.dirty}>
                {" "}
                Save settings
              </Button>
            </div>
          </div>
          <div className="desktop:p-8 px-4 py-2 max-w-3xl overflow-y-auto">
            <CustomiserUnit
              title="Article Count Text"
              description="This text is shown where ever number of articles are shown (eg: 1 Article or 2 Articles)"
            >
              <div className="flex">
                <div className="mr-2">
                  <small>Singular</small>
                  <Textfield
                    value={props.values.article_count.singular}
                    onChange={(e) => {
                      props.setFieldValue(
                        "article_count.singular",
                        e.target.value
                      );
                    }}
                    name="basic"
                  />
                </div>
                <div>
                  <small>Plural</small>
                  <Textfield
                    value={props.values.article_count.plural}
                    onChange={(e) => {
                      props.setFieldValue(
                        "article_count.plural",
                        e.target.value
                      );
                    }}
                    name="basic"
                  />
                </div>
              </div>
            </CustomiserUnit>


            
            <CustomiserUnit
              title="Search Box Placeholder Text"
              description="This text is shown as the placeholder text in side the searchbox"
            >
              <Textfield
                value={props.values.search_box_placeholder}
                onChange={(e) => {
                  props.setFieldValue("search_box_placeholder", e.target.value);
                }}
                name="basic"
              />
            </CustomiserUnit>

            <CustomiserUnit
              title="Search Empty Result"
              description="This piece od text is shown for empty search results"
            >
              <Textfield
                value={props.values.search_empty_result}
                onChange={(e) => {
                  props.setFieldValue("search_empty_result", e.target.value);
                }}
                name="basic"
              />
            </CustomiserUnit>

            <CustomiserUnit
              title="Home Text"
              description="This text is in the breadcrumbs shown above each article. This helps user to get back to the home of faq page"
            >
              <Textfield
                value={props.values.home}
                onChange={(e) => {
                  props.setFieldValue("home", e.target.value);
                }}
                name="basic"
              />
            </CustomiserUnit>

            <CustomiserUnit
              title="Featured Articles Title"
              description="The title displayed above the featured articles"
            >
              <Textfield
                value={props.values.featured_title}
                onChange={(e) => {
                  props.setFieldValue("featured_title", e.target.value);
                }}
                name="basic"
              />
            </CustomiserUnit>

            <CustomiserUnit
              title="Similar Articles Title"
              description="Below every article related articles in its own category will be featured. This title will be displayed above it"
            >
              <Textfield
                value={props.values.similar_articles}
                onChange={(e) => {
                  props.setFieldValue("similar_articles", e.target.value);
                }}
                name="basic"
              />
            </CustomiserUnit>

            <CustomiserUnit
              title="Updated At Text"
              description="The date at which each article is last edited is shown above article. This bit of text will be shown just beside the date"
            >
              <Textfield
                value={props.values.updated_at}
                onChange={(e) => {
                  props.setFieldValue("updated_at", e.target.value);
                }}
                name="basic"
              />
            </CustomiserUnit>

            <CustomiserUnit
              title="View All Button"
              description="The text inside View all button "
            >
              <Textfield
                value={props.values.view_all}
                onChange={(e) => {
                  props.setFieldValue("view_all", e.target.value);
                }}
                name="basic"
              />
            </CustomiserUnit>
          </div>
        </div>
      )}
    </Formik>
  );
  else return <LoadingSpinner />;
}
