import React, { useState, useEffect } from "react";
import toaster from "toasted-notes";
import { useQuery, useMutation, useQueryCache } from "react-query";
import { useLocation, useRouteMatch, Link,useHistory } from "react-router-dom";
import { RiEyeLine, RiLightbulbFlashFill } from "react-icons/ri";
import PageViewContainer from "../../../../components/structure/PageViewContainer";
import Button from "../../../../components/Button";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import { Formik } from "formik";
import CornerWidgetPreview from "../Previews/CornerWidgetPreview";
import CustomiserUnit from "../../../../components/CustomiserUnit";
import {
  queryWidgetSettings,
  updateWidgetSettings,
} from "../../../../api/widgets";
import GradientPicker from "../../../../components/GradientPicker";
import { useGraphQl } from "../../../../GraphqlClient";
import { useWorkspace } from "../../../../WorkspaceContext";
import Textfield from "@atlaskit/textfield";
import { Checkbox } from "@atlaskit/checkbox";
import Popup from "@atlaskit/popup";
import Picker from "emoji-picker-react";

const CornerWidgetPage = () => {
  const { path } = useRouteMatch();
  let history = useHistory();
  const location = useLocation();
  const [previewViewState, setPreviewViewState] = useState("assistant");
  const [emojiPickerVisibility, setEmojiPickerVisibility] = useState(false);
  const pageRoute = location.pathname.split("/")[3];
  const graphQL = useGraphQl();
  const workspace = useWorkspace();
  const queryCache = useQueryCache();
  const { data, refetch } = useQuery("queryWidgetSettings", () =>
    graphQL(queryWidgetSettings)
  );

  const updateWidget = async (values) => {
    await graphQL(updateWidgetSettings, {
      widgetSettingsId: values.id,
      widgetSettingsInput: values.config,
    });
    refetch();
  };

  const [updateWidgetMutation] = useMutation(updateWidget, {
    onSuccess: () => {
      queryCache.invalidateQueries("queryWidgetSettings");
      toaster.notify("Widget settings saved successfully", {
        duration: 2000,
      });
    },
  });

  const getTitle = () => {
    switch (pageRoute) {
      case "look-and-feel":
        return "Look & Feel";
      case "preferences":
        return "Preferences";
      default:
        return "Look & Feel";
    }
  };

  useEffect(() => {
    if (workspace && workspace.data.currentAppID === 2)
      setPreviewViewState("offers");
    else setPreviewViewState("assistant");
  }, [workspace]);

  const alignment = [
    { label: "Right", value: "right" },
    { label: "Left", value: "left" },
  ];

  const initialValues = {
    id: data && data.widgets_cornerwidget[0].id,
    isActive: data && data.widgets_cornerwidget[0].common_config.isActive,
    contextConfigs: {
      assistant: {
        isActive:
          data &&
          data.widgets_cornerwidget[0].common_config.contextConfigs.assistant
            .isActive,
        search:
          data &&
          data.widgets_cornerwidget[0].common_config.contextConfigs.assistant
            .search,
        introEmoji:
          data &&
          data.widgets_cornerwidget[0].common_config.contextConfigs.assistant
            .introEmoji,
        contactOptions: {
          messenger:
            data &&
            data.widgets_cornerwidget[0].common_config.contextConfigs.assistant
              .contactOptions.messenger,
          whatsapp:
            data &&
            data.widgets_cornerwidget[0].common_config.contextConfigs.assistant
              .contactOptions.whatsapp,
          email:
            data &&
            data.widgets_cornerwidget[0].common_config.contextConfigs.assistant
              .contactOptions.email,
          sms:
            data &&
            data.widgets_cornerwidget[0].common_config.contextConfigs.assistant
              .contactOptions.sms,
        },
      },

      cart: {
        isActive:
          data &&
          data.widgets_cornerwidget[0].common_config.contextConfigs.cart
            .isActive,
      },
      offers: {
        isActive:
          data &&
          data.widgets_cornerwidget[0].common_config.contextConfigs.offers
            .isActive,
      },
    },
    bgStartColor:
      data && data.widgets_cornerwidget[0].common_config.bgStartColor,
    bgEndColor: data && data.widgets_cornerwidget[0].common_config.bgEndColor,
    blockedUrls: data && data.widgets_cornerwidget[0].common_config.blockedUrls,
    hideCocaBranding:
      data && data.widgets_cornerwidget[0].common_config.hideCocaBranding,
    autoTranslate:
      data && data.widgets_cornerwidget[0].common_config.autoTranslate,
    zIndex: data && data.widgets_cornerwidget[0].common_config.zIndex,
    logoUrl: data && data.widgets_cornerwidget[0].common_config.logoUrl,
    alignment: data && data.widgets_cornerwidget[0].common_config.alignment,
    trackingPixels: {
      gaPixel:
        data &&
        data.widgets_cornerwidget[0].common_config.trackingPixels.gaPixel,
      fbPixel:
        data &&
        data.widgets_cornerwidget[0].common_config.trackingPixels.fbPixel,
    },
  };
  if (data)
    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values) => {
          const commonConfig = {
            common_config: {
              id: values.id,
              isActive: values.isActive,
              contextConfigs: {
                assistant: {
                  isActive: values.contextConfigs.assistant.isActive,
                  introEmoji: values.contextConfigs.assistant.introEmoji,
                  search: values.contextConfigs.assistant.search,
                  contactOptions: {
                    whatsapp:
                      values.contextConfigs.assistant.contactOptions.whatsapp,
                    messenger:
                      values.contextConfigs.assistant.contactOptions.messenger,
                    email: values.contextConfigs.assistant.contactOptions.email,
                    sms: values.contextConfigs.assistant.contactOptions.sms,
                  },
                },
                cart: {
                  isActive: values.contextConfigs.cart.isActive,
                },
                offers: {
                  isActive: values.contextConfigs.offers.isActive,
                },
              },
              bgStartColor: values.bgStartColor,
              bgEndColor: values.bgEndColor,
              blockedUrls: values.blockedUrls,
              hideCocaBranding: values.hideCocaBranding,
              autoTranslate: values.autoTranslate,
              zIndex: values.zIndex,
              logoUrl: values.logoUrl,
              alignment: values.alignment,
              trackingPixels: {
                gaPixel: values.trackingPixels.gaPixel,
                fbPixel: values.trackingPixels.fbPixel,
              },
            },
          };
          updateWidgetMutation({
            id: { id: values.id },
            config: commonConfig,
          });
        }}
      >
        {(props) => {
          return (
            <PageViewContainer
              title={getTitle()}
              subTitle="Corner Widget"
              action={
                <div className="flex">
                  {props.dirty ? (
                    <Button
                      type="primary"
                      icon={<RiEyeLine />}
                      onClick={() => props.submitForm()}
                    >
                      Save Settings
                    </Button>
                  ) : (
                    <Button
                      className="mr-2"
                      type="ghost"
                      onClick={() => {
                        if (workspace.data)
                          window.open(`https://${workspace.data.storeUrl}`);
                      }}
                      icon={<RiEyeLine />}
                    >
                      View it on my store
                    </Button>
                  )}
                </div>
              }
            >
              <div className="flex w-full h-full ">
                <div className="laptop:max-w-xl mobile:w-full h-full shrink-0 grow px-8 overflow-y-auto">
                  <div className="flex  items-center border border-amber-200 bg-amber-100 rounded-lg mt-3 p-4">
                    <h3 className="font-bold flex items-center justify-center w-8 h-8 rounded-full bg-amber-500 text-white mr-4 shrink-0">
                      {" "}
                      <RiLightbulbFlashFill />
                    </h3>

                    <p>
                      If you wish to hide this widget completely, go to{" "}
                      <Link
                        to={`/support/widget/close-state/`}
                      >
                        <b className="border-b boder-gray-800">Closed State Settings</b>{" "}
                      </Link>
                      and select{" "}
                      <b>
                        '<i>Hide Launcher</i>'
                      </b>
                    </p>
                  </div>

                  <CustomiserUnit
                    title="Widget Color"
                    description="Select the color to match your brand's identity"
                  >
                    <GradientPicker
                      startColor={props.values.bgStartColor}
                      endColor={props.values.bgEndColor}
                      onChange={(startColor, endColor) => {
                        props.setFieldValue("bgStartColor", startColor);
                        props.setFieldValue("bgEndColor", endColor);
                      }}
                    />
                  </CustomiserUnit>

                  <CustomiserUnit
                    title="Intro Emoji"
                    description="Set an emoji to be featured on the assistant section"
                  >
                    <div className="flex">
                      <Popup
                        isOpen={emojiPickerVisibility}
                        onClose={() => setEmojiPickerVisibility(false)}
                        content={() => (
                          <Picker
                            native
                            disableSearchBar
                            onEmojiClick={(event, emojiObject) => {
                              props.setFieldValue(
                                "contextConfigs.assistant.introEmoji",
                                emojiObject.emoji
                              );
                            }}
                          />
                        )}
                        trigger={(triggerProps) => (
                          <button
                            {...triggerProps}
                            onClick={() =>
                              setEmojiPickerVisibility(!emojiPickerVisibility)
                            }
                            type="outline"
                            triggerText={
                              props.values.contextConfigs.assistant.introEmoji
                            }
                            className="text-4xl px-4 py-3 border rounded-lg"
                          >
                            {props.values.contextConfigs.assistant.introEmoji}
                          </button>
                        )}
                      />
                    </div>
                  </CustomiserUnit>

                  <CustomiserUnit
                    title="Welcome title"
                    description="To edit the title displayed in the assistant section go to the language settings page"
                  >
                    <Button
                      onClick={() => {
                        history.push(`/settings/language/corner-widget/`);
                      }}
                      type="ghost"
                      className="shrink-0"
                    >
                      Go to language settings
                    </Button>
                  </CustomiserUnit>
                  <CustomiserUnit
                    paywall={
                      !workspace.data?.feature_flag?.apps?.supportcorner
                        ?.articles_search_enabled
                    }
                    paywallPlan={5}
                    title="Article search"
                    description="Give your visitors option to search through your FAQ articles "
                  >
                    <Checkbox
                      onChange={(e) => {
                        props.setFieldValue(
                          "contextConfigs.assistant.search",
                          e.target.checked
                        );
                      }}
                      label="Show search box"
                      name="checkbox-basic"
                      defaultChecked={
                        props.values.contextConfigs.assistant.search
                      }
                    />
                  </CustomiserUnit>
                  <CustomiserUnit
                    paywall={
                      !workspace.data?.feature_flag?.apps?.supportcorner
                        ?.contact_options?.whatsapp
                    }
                    paywallPlan={5}
                    title="Whatsapp Integration"
                    description="Give your phone number with your international code, dont include spaces or + (eg: 441134960999)"
                  >
                    <Textfield
                      id="whatsapp"
                      placeholder="Phone Number"
                      autoFocus={true}
                      name="basic"
                      defaultValue={
                        props.values.contextConfigs.assistant.contactOptions
                          .whatsapp
                      }
                      onChange={(e) => {
                        props.setFieldValue(
                          "contextConfigs.assistant.contactOptions.whatsapp",
                          e.target.value
                        );
                      }}
                    />
                  </CustomiserUnit>
                  <CustomiserUnit
                    paywall={
                      !workspace.data?.feature_flag?.apps?.supportcorner
                        ?.contact_options?.messenger
                    }
                    paywallPlan={5}
                    title="Facebook Messenger Integration"
                    description="Give your m.me URL (eg: https://m.me/cornercartio)"
                  >
                    <Textfield
                      id="messenger"
                      placeholder="m.me URL"
                      autoFocus={true}
                      name="basic"
                      defaultValue={
                        props.values.contextConfigs.assistant.contactOptions
                          .messenger
                      }
                      onChange={(e) => {
                        props.setFieldValue(
                          "contextConfigs.assistant.contactOptions.messenger",
                          e.target.value
                        );
                      }}
                    />
                  </CustomiserUnit>
                  <CustomiserUnit
                    paywall={
                      !workspace.data?.feature_flag?.apps?.supportcorner
                        ?.contact_options?.email
                    }
                    paywallPlan={5}
                    title="Email Integration"
                    description="Give your contact email id for your customers  (eg: abc@xyz.com)"
                  >
                    <Textfield
                      id="email"
                      placeholder="Email"
                      autoFocus={true}
                      name="basic"
                      defaultValue={
                        props.values.contextConfigs.assistant.contactOptions
                          .email
                      }
                      onChange={(e) => {
                        props.setFieldValue(
                          "contextConfigs.assistant.contactOptions.email",
                          e.target.value
                        );
                      }}
                    />
                  </CustomiserUnit>
                  <CustomiserUnit
                    paywall={
                      !workspace.data?.feature_flag?.apps?.supportcorner
                        ?.contact_options?.sms
                    }
                    paywallPlan={5}
                    title="SMS Integration"
                    description="Give your contact number so that visitors could redirect to their sms app you from the widget (eg: +441134960999)"
                  >
                    <Textfield
                      id="sms"
                      placeholder="+441134960999"
                      autoFocus={true}
                      name="basic"
                      defaultValue={
                        props.values.contextConfigs.assistant.contactOptions.sms
                      }
                      onChange={(e) => {
                        props.setFieldValue(
                          "contextConfigs.assistant.contactOptions.sms",
                          e.target.value
                        );
                      }}
                    />
                  </CustomiserUnit>

                </div>
                <div className="grow flex-wrap h-full bg-gray-100 border-l mobile:hidden laptop:flex items-center justify-center overflow-y-auto">
                  <div className="flex w-full items-center justify-center -mt-12 pointer-events-none">
                    <div className=" scale-80">
                      <CornerWidgetPreview
                        state="open"
                        bgStartColor={props.values.bgStartColor}
                        bgEndColor={props.values.bgEndColor}
                        shape="circle"
                        size="large"
                        viewState="assistant"
                        // viewState={previewViewState}
                        foregroundColor="light"
                        hideCocaBranding={
                          workspace.data?.feature_flag?.apps?.supportcorner
                            ?.remove_corner_branding
                        }
                        hideSidebar={true}
                        search={props.values.contextConfigs.assistant.search}
                        langObject={data?.widgets_cornerwidget[0].corner_lang}
                        defaultLang={
                          data?.workspaces[0].source.default_language
                        }
                        introEmoji={
                          props.values.contextConfigs.assistant.introEmoji
                        }
                        contactOptions={
                          props.values.contextConfigs.assistant.contactOptions
                        }
                      />
                    </div>
                  </div>

                  <div className="text-sm max-w-2xl text-center text-gray-500">
                    The content here is just for demo purposes. This preview is
                    also scaled down to 80% of the original size. Visit your
                    store to view the exact widget.
                  </div>
                </div>
              </div>
            </PageViewContainer>
          );
        }}
      </Formik>
    );
  else return <LoadingSpinner />;
};

export default CornerWidgetPage;
