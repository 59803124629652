import React, { useState, useEffect } from "react";
import toaster from "toasted-notes";
import { useQuery, useMutation, useQueryCache } from "react-query";
import CustomiserUnit from "../../../../components/CustomiserUnit";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import Select from "@atlaskit/select";
import Textfield from "@atlaskit/textfield";
import Button from "../../../../components/Button";
import { Formik } from "formik";
import { useGraphQl } from "../../../../GraphqlClient";
import { useWorkspace } from "../../../../WorkspaceContext";
import {
  queryWidgetSettings,
  updateWidgetSettings,
} from "../../../../api/widgets";
import { langOptions } from "../../../../utilities/variables";

export default function CornerWidget() {
  const graphQL = useGraphQl();
  const workspace = useWorkspace();
  const queryCache = useQueryCache();

  const [currentLanguages, setCurrentLanguages] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState();
  const [initialLanguage, setInitialLanguage] = useState();

  const { data, refetch } = useQuery("queryWidgetSettings", () =>
    graphQL(queryWidgetSettings)
  );

  const updateWidget = async (values) => {
    await graphQL(updateWidgetSettings, {
      widgetSettingsId: values.id,
      widgetSettingsInput: values.config,
    });
    refetch();
  };

  const [updateWidgetMutation] = useMutation(updateWidget, {
    onSuccess: () => {
      queryCache.invalidateQueries("queryWidgetSettings");
      toaster.notify("Widget settings saved successfully", {
        duration: 2000,
      });
    },
  });

 // This useEffect triggers when selected language is changed and when data is updated after saving
  // And this combines the data from db and the default values saved in i18n so that even if user doesnt have certain language values in his db it will be filled from the default values


  useEffect(async () => {
    if (selectedLanguage && data) {
      let currentLangJSON =
        data && data.widgets_cornerwidget[0].corner_lang[`${selectedLanguage}`];
      let defaultValues =
        await require(`../../../../i18n/default-values/corner-widget/${selectedLanguage}.json`);
      setInitialLanguage({ ...defaultValues, ...currentLangJSON });
    }
  }, [data, selectedLanguage]);

  useEffect(() => {
    if (data) {
      let langConfig = data.widgets_cornerwidget[0].corner_lang;
      let langArray = Object.keys(langConfig);
      setCurrentLanguages(langArray);
      setSelectedLanguage(data.workspaces[0].source.default_language);
    }
  }, [data]);

  if (initialLanguage)
    return (
      <Formik
        enableReinitialize
        initialValues={initialLanguage}
        onSubmit={(values) => {
          const corner_lang = {
            corner_lang: {
              ...data.widgets_cornerwidget[0].corner_lang,
              [`${selectedLanguage}`]: values,
            },
          };

          updateWidgetMutation({
            id: { id: data && data.widgets_cornerwidget[0].id },
            config: corner_lang,
          });
        }}
      >
        {(props) => (
          <div className=" h-full overflow-y-auto">
            <div className=" sticky top-0 flex desktop:p-4 p-2 border-b bg-white items-center justify-between">
              <div className="px-3 py-2">
                <h2 className="text-blue-900 font-bold mb-2 desktop:text-xl text-lg">
                  Corner Widget UI Text editor
                </h2>
                <p className="text-tiny desktop:text-base">
                  In this page you could edit the text that ytou can see in the
                  UI of the Corner widget
                </p>
                {!props.dirty ? (
                  <div className="flex items-center mt-5">
                    <p className="font-semibold">Select Language</p>
                    {data && (
                      <Select
                      isSearchable={false}
                        value={langOptions.find(
                          (option) => option.value === selectedLanguage
                        )}
                        onChange={(e) => {
                          setSelectedLanguage(e.value);
                        }}
                        isDisabled={props.dirty}
                        className="w-48 ml-2"
                        classNamePrefix="react-select"
                        options={langOptions.filter((item) =>
                          currentLanguages.includes(item.value)
                        )}
                      />
                    )}
                  </div>
                ) : (
                  <div className="p-3 mt-5 border rounded-md">
                    <small>
                      <b>
                        {
                          langOptions.filter(
                            (item) => item.value === selectedLanguage
                          )[0].label
                        }
                        :
                      </b>{" "}
                      You've to save/discard the usaved changes before switching
                      language .
                    </small>
                  </div>
                )}
              </div>
              <div className="mr-4 flex shrink-0">
                {props.dirty && (
                  <Button
                    type="link"
                    onClick={() => {
                      props.resetForm();
                    }}
                  >
                    Discard changes
                  </Button>
                )}
                <Button
                  onClick={() => props.submitForm()}
                  type="primary"
                  disabled={!props.dirty}
                >
                  {" "}
                  Save settings
                </Button>
              </div>
            </div>
            <div className="desktop:p-8 px-4 py-2 max-w-3xl overflow-y-auto">
              <CustomiserUnit
                title="Welcome Text"
                description="This piece of text will be shown as the primary title in the widget below the Intro Emoji"
              >
                <Textfield
                  value={props.values.assistant.welcome_text}
                  onChange={(e) => {
                    props.setFieldValue(
                      "assistant.welcome_text",
                      e.target.value
                    );
                  }}
                  name="basic"
                />
              </CustomiserUnit>

              <CustomiserUnit
                title="Search Title"
                description="This title that appears above the search results"
              >
                <Textfield
                  value={props.values.assistant.search_results_title}
                  onChange={(e) => {
                    props.setFieldValue(
                      "assistant.search_results_title",
                      e.target.value
                    );
                  }}
                  name="basic"
                />
              </CustomiserUnit>
              <CustomiserUnit
                title="Search Box Placeholder Text"
                description="This text is shown as the placeholder text in side the searchbox"
              >
                <Textfield
                  value={props.values.assistant.search_box_placeholder}
                  onChange={(e) => {
                    props.setFieldValue(
                      "assistant.search_box_placeholder",
                      e.target.value
                    );
                  }}
                  name="basic"
                />
              </CustomiserUnit>

              <CustomiserUnit
                title="Search Empty Result"
                description="This piece od text is shown for empty search results"
              >
                <Textfield
                  value={props.values.assistant.search_empty_result}
                  onChange={(e) => {
                    props.setFieldValue(
                      "assistant.search_empty_result",
                      e.target.value
                    );
                  }}
                  name="basic"
                />
              </CustomiserUnit>

              <CustomiserUnit
                title="Category Title"
                description="This piece of text will be shown as the title above the category cards"
              >
                <Textfield
                  value={props.values.assistant.category_cards_title}
                  onChange={(e) => {
                    props.setFieldValue(
                      "assistant.category_cards_title",
                      e.target.value
                    );
                  }}
                  name="basic"
                />
              </CustomiserUnit>

              <CustomiserUnit
                title="Featured Articles Title"
                description="The title displayed above the featured articles"
              >
                <Textfield
                  value={props.values.assistant.featured_faq_title}
                  onChange={(e) => {
                    props.setFieldValue(
                      "assistant.featured_faq_title",
                      e.target.value
                    );
                  }}
                  name="basic"
                />
              </CustomiserUnit>
              <CustomiserUnit
                title="Contact us label"
                description="The text displayed just besides the contact icons"
              >
                <Textfield
                  value={props.values.assistant.contact_us_label}
                  onChange={(e) => {
                    props.setFieldValue(
                      "assistant.contact_us_label",
                      e.target.value
                    );
                  }}
                  name="basic"
                />
              </CustomiserUnit>
              <CustomiserUnit
                title="View all Button"
                description="The text displayed on view all button below the category cards"
              >
                <Textfield
                  value={props.values.assistant.view_in_faq}
                  onChange={(e) => {
                    props.setFieldValue(
                      "assistant.view_in_faq",
                      e.target.value
                    );
                  }}
                  name="basic"
                />
              </CustomiserUnit>
            </div>
          </div>
        )}
      </Formik>
    );
  else return <LoadingSpinner />;
}
