import React, { useState } from "react";

const TabRadioButton = (props) => {
  return (
    <div className="flex">
      {props.options.map((item, index) => {
        return (
          <button
            key={`${item.value}`}
            onClick={() => {
              props.onChange(item.value);
            }}
            className={`flex items-center border ${
              index === 0 && "rounded-l-md"
            } ${
              index === props.options.length - 1 && "rounded-r-md"
            }  desktop:p-3 laptop:p-2 mobile:p-1  focus:outline-none hover:shadow-lg desktop:text-base mobile:text-sm ${
              // props.values.viewType === "cards" &&
              props.defaultValue === item.value
                ? "bg-blue-100 text-blue-600 border-blue-200 font-bold shadow-lg"
                : "text-gray-600 bg-gray-100   border-gray-300"
            }`}
          >
            <span>{item.label}</span>
          </button>
        );
      })}
    </div>
  );
};

export default TabRadioButton;
