import React, { useEffect } from "react";
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom";
import { useWorkspace } from "../../../WorkspaceContext";
import GrowthWidgets from "./GrowthWidgets/GrowthWidgetsRouter";
import CornerCart from "./CornerCart";
import StickyBar from "./GrowthWidgets/AtcBar";

export default function WidgetRouter() {
  let { path } = useRouteMatch();
  const workspace = useWorkspace();

  useEffect(() => {
    if (workspace && workspace.data.currentAppID !== 3)
      workspace.data.changeCurrentAppID(3);
  }, [workspace]);

  return (
    <>
      <Switch>
        <Route exact path={`${path}/corner-cart/`} component={CornerCart} />
        <Route path={`${path}/atc-bar`} component={StickyBar} />
        {/* <Route path={`${path}/growth-widgets`} component={GrowthWidgets} /> */}
        <Route exact path={path}>
          <Redirect to={`/cart/widgets/corner-cart/`} />
        </Route>
      </Switch>
    </>
  );
}
