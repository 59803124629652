import React, { useState, useEffect } from "react";
import { useQuery, useMutation, useQueryCache } from "react-query";
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import {
  NavLink,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
} from "react-router-dom";
import {
  queryOneAccordion,
  updateAccordion,
  deleteAccordion,
} from "../../../../api/support";
import InlineEdit from "@atlaskit/inline-edit";
import Textfield from "@atlaskit/textfield";
import PageViewContainer from "../../../../components/structure/PageViewContainer";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import Select from "@atlaskit/select";
import {
  RiCheckboxBlankCircleFill,
  RiArrowDropDownLine,
  RiDeleteBin2Fill,
} from "react-icons/ri";
import AccordionListEdit from "./AccordionListEdit";
import AccordionSettings from "./AccordionSettings";
import { useGraphQl } from "../../../../GraphqlClient";
import toaster from "toasted-notes";
import Button from "../../../../components/Button";

const CustomAccordionEdit = (historyProps) => {
  const graphQL = useGraphQl();
  const queryCache = useQueryCache();

  const updateAccordions = async (input) => {
    await graphQL(updateAccordion, {
      accordionsId: { id: input.id },
      accordionsInput: input.values,
    });
  };
  const [updateAccordionMutation] = useMutation(updateAccordions, {
    onSuccess: () =>
      queryCache.invalidateQueries(
        `queryOneAccordion${historyProps.match.params.id}`
      ),
  });

  const deleteAccordions = async (input) => {
    await graphQL(deleteAccordion, {
      accordionsId: historyProps.match.params.id,
    });
  };

  const [deleteAccordionMutation] = useMutation(deleteAccordions, {
    onSuccess: () => {
      toaster.notify("Article deleted", {
        duration: 2000,
      });
      historyProps.history.push(`/support/faq/custom-accordions/`);
    },
  });

  const { data } = useQuery(
    `queryOneAccordion${historyProps.match.params.id}`,
    () =>
      graphQL(queryOneAccordion, {
        accordionsid: historyProps.match.params.id,
      })
  );

  let { url } = useRouteMatch();
  const [accordionName, setAccordionName] = useState("");
  const [isDeleteWarningOpen, setIsDeleteWarningOpen] = useState(false);

  const handleSubmision = (name, status) => {
    updateAccordionMutation({
      id: data && data.accordions[0] && data.accordions[0].id,
      values: {
        name: name,
        is_active: status,
      },
    });
  };

  const accordionStatus = [
    {
      label: (
        <div className="flex items-center">
          <RiCheckboxBlankCircleFill className="flex-none text-green-400 mr-2 text-lg" />{" "}
          Publish{" "}
        </div>
      ),
      value: true,
    },
    {
      label: (
        <div className="flex items-center">
          <RiCheckboxBlankCircleFill className="flex-none text-amber-400 mr-2 text-lg" />{" "}
          Draft{" "}
        </div>
      ),
      value: false,
    },
  ];

  useEffect(() => {
    if (data?.accordions[0]) {
      setAccordionName(data.accordions[0].name);
    }
  }, [data]);

  if (data?.accordions[0])
    return (
      <PageViewContainer
        title="Custom Accordion"
        subTitle="FAQ"
        action={
          <button
            onClick={() => {
              navigator.clipboard.writeText(
                `<div class="coca-accordion" data-accordion-type="${data.accordions[0].type}" data-accordion-id="${data.accordions[0].id}"/>`
              );
              toaster.notify("Embed code copied to clipboard ", {
                duration: 1000,
              });
            }}
            className="border  rounded-lg focus:outline-none  bg-gray-100 hover:bg-white hover:border-blue-600  p-3 laptop:block mobile:hidden text-left transition-all"
          >
            <div className="text-xs font-bold text-blue-900">
              CLICK TO COPY EMBED CODE:
            </div>
            <div className="font-mono text-sm text-blue-600 text-ellipsis overflow-hidden max-w-lg desktop:max-w-full whitespace-nowrap">{`<div class="coca-accordion" data-accordion-type="${data.accordions[0].type}" data-accordion-id="${data.accordions[0].id}"/>`}</div>
          </button>
        }
      >
        <div className="h-full overflow-y-auto">
          <div className="border-b ">
            <div className="p-6 desktop:p-8 flex items-center justify-between w-full">
              <div className="flex">
                <InlineEdit
                  defaultValue={accordionName}
                  editView={() => (
                    <Textfield
                      defaultValue={accordionName}
                      onChange={(e) => setAccordionName(e.target.value)}
                      name="basic"
                    />
                  )}
                  readView={() => (
                    <h2 className="p-2 desktop:p-5 text-blue-900 border-b-2 border-dotted border-gray-400 cursor-pointer text-base desktop:text-xl">
                      <b>{accordionName}</b>
                    </h2>
                  )}
                  onConfirm={(e) =>
                    handleSubmision(accordionName, data.accordions[0].is_active)
                  }
                />
              </div>
              <div className="flex items-center">
                {data.accordions[0].type === "static" && (
                  <Button
                    className="mt-3 mr-5"
                    icon={<RiDeleteBin2Fill />}
                    type="danger-ghost"
                    onClick={() => setIsDeleteWarningOpen(true)}
                  >
                    <span className="laptop:block mobile:hidden">
                      Delete Accordion
                    </span>
                  </Button>
                )}
                <div className="max-w-md ">
                  <InlineEdit
                    defaultValue={data.accordions[0].is_active}
                    hideActionButtons
                    editView={() => (
                      <div className="w-40">
                        <Select
                        isSearchable={false}
                          options={accordionStatus}
                          onChange={(e) =>
                            handleSubmision(accordionName, e.value)
                          }
                          autoFocus
                          openMenuOnFocus
                        />
                      </div>
                    )}
                    readView={() =>
                      data.accordions[0].is_active ? (
                        <div className="flex items-center py-3 px-4 rounded-md bg-gray-200 cursor-pointer ">
                          <RiCheckboxBlankCircleFill className="flex-none text-green-400 mr-2 text-lg" />{" "}
                          Published{" "}
                          <RiArrowDropDownLine className="flex-none ml-2 text-lg" />{" "}
                        </div>
                      ) : (
                        <div className="flex items-center py-3 px-4 rounded-md bg-gray-200 cursor-pointer ">
                          <RiCheckboxBlankCircleFill className="flex-none text-amber-400 mr-2 text-lg" />{" "}
                          Draft{" "}
                          <RiArrowDropDownLine className="flex-none ml-2 text-lg" />{" "}
                        </div>
                      )
                    }
                    onConfirm={(e) => console.log("is_published", e)}
                  />
                </div>
              </div>
            </div>
            <div className="flex px-2 desktop:px-8 items-end">
              <NavLink
                to={`${url}/edit`}
                activeClassName="border-b-2 border-blue-600 text-blue-600 font-bold"
                className=" mx-3 desktop:p-3 p-1 text-sm desktop:text-base"
              >
                Articles
              </NavLink>
              <NavLink
                to={`${url}/settings`}
                activeClassName="border-b-2 border-blue-600 text-blue-600 font-bold"
                className=" mx-3 desktop:p-3 p-1 text-sm desktop:text-base"
              >
                Other Settings
              </NavLink>
            </div>
          </div>
          <Switch>
            <Route
              exact
              path={`${url}/edit`}
              component={() => (
                <AccordionListEdit
                  accordionId={historyProps.match.params.id}
                  accordionType={data.accordions[0].type}
                />
              )}
            />
            <Route
              exact
              path={`${url}/settings`}
              component={(props) => (
                <AccordionSettings
                  accordion={data.accordions[0]}
                  onChange={(changedValues) => {
                    updateAccordionMutation({
                      id: data && data.accordions[0] && data.accordions[0].id,
                      values: changedValues,
                    });
                  }}
                />
              )}
            />
            <Route exact path={url}>
              <Redirect to={`${url}/edit`} />
            </Route>
          </Switch>
        </div>
        <ModalTransition>
          {isDeleteWarningOpen && (
            <Modal
              actions={[
                {
                  text: "Delete it",
                  onClick: () => {
                    deleteAccordionMutation();
                    setIsDeleteWarningOpen(false);
                  },
                },
                {
                  text: "Cancel",
                  onClick: () => {
                    setIsDeleteWarningOpen(false);
                  },
                },
              ]}
              onClose={() => {
                setIsDeleteWarningOpen(false);
              }}
              heading="Are you sure?"
              appearance="danger"
            >
              <p>Are you sure you want to delete this accordion.</p>
            </Modal>
          )}
        </ModalTransition>
      </PageViewContainer>
    );
  else return <LoadingSpinner />;
};

export default CustomAccordionEdit;
