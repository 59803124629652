import React from "react";
import { useWorkspace } from "../../../../../WorkspaceContext";
import Textfield from "@atlaskit/textfield";
import TabRadioButton from "../../../../../components/TabRadioButton";
import { Checkbox } from "@atlaskit/checkbox";
import CustomiserUnit from "../../../../../components/CustomiserUnit";

/**
 * open settings
 *
 * @component
 * @param {Object} values - current values inside the form
 * @param {Function} setFieldValue - Function to set values in each field
 */
export default function MiniCartOpenSettings({ values, setFieldValue }) {
  const workspace = useWorkspace();
  const cartAdditionTriggerSelect = [
    { label: "Open Cart Widget", value: true },
    { label: "Cart Widget stays closed", value: false },
  ];
  const showHideSelect = [
    { label: "Show", value: true },
    { label: "Hide", value: false },
  ];
  const layoutStyles = [
    { label: "Carousel", value: "carousel" },
    { label: "Cards", value: "cards" },
    { label: "List", value: "list" },
  ];

  return (
    <div className="flex grow flex-col">
      {/* On Cart Addition */}
      <CustomiserUnit
        title="Add to Cart Behaviour"
        description="Select what happens when an item is added to the cart"
      >
        <TabRadioButton
          defaultValue={values.cart_config.general.cartOpenOnAddition}
          options={cartAdditionTriggerSelect}
          onChange={(selectedValue) => {
            setFieldValue(
              "cart_config.general.cartOpenOnAddition",
              selectedValue
            );
          }}
        />
      </CustomiserUnit>

      {/* Optional Note */}
      <CustomiserUnit
        paywall={
          !workspace.data?.feature_flag.apps?.cornercart?.cornerwidget
            .cornerwidget_optional_note
        }
        paywallPlan={13}
        title="Order Notes"
        description="Allow visitors to add an optional note to the order. You can view this note along with the order in the Shopify dasboard."
      >
        <TabRadioButton
          defaultValue={values.cart_config.general.showOptionalNotes}
          options={showHideSelect}
          onChange={(selectedValue) => {
            setFieldValue(
              "cart_config.general.showOptionalNotes",
              selectedValue
            );
          }}
        />
      </CustomiserUnit>

      {/* Cart Upsells */}
      <CustomiserUnit
        paywall={
          !workspace.data?.feature_flag.apps?.cornercart?.cornerwidget
            .cornerwidget_related_products
        }
        paywallPlan={13}
        title="Cart Upsells"
        description={
          <span>
            Based on the items added to the cart, the widget will suggest few
            more products which could be added to the cart. (The related
            products are obtained automaticaly using
            <a
              href="https://shopify.dev/api/ajax/reference/product-recommendations"
              target="_blank"
              className="text-primary-500"
            >
              {" "}
              Shopify's Recomendation API{" "}
            </a>
            )
          </span>
        }
      >
        <TabRadioButton
          defaultValue={values.cart_config.general.showRelatedProducts}
          options={showHideSelect}
          onChange={(selectedValue) => {
            setFieldValue(
              "cart_config.general.showRelatedProducts",
              selectedValue
            );
          }}
        />
        {values.cart_config.general.showRelatedProducts && (
          <div className="mt-3 border rounded-lg p-3">
            <div>
              <p className="text-sm font-bold">Upsell product count</p>
              <p className="text-xs text-gray-500">
                (Maximum Number of products shown as related products)
              </p>
              <div className="w-24">
                <Textfield
                  defaultValue={
                    values.cart_config.general.relatedProductCount || 10
                  }
                  onChange={(e) => {
                    setFieldValue(
                      "cart_config.general.relatedProductCount",
                      e.target.value
                    );
                  }}
                  name="basic"
                  type="number"
                />
              </div>
            </div>
            <div className="mt-3">
              <p className="text-sm font-bold">Layout</p>
              <p className="text-xs text-gray-500">
                (Decide how the products are layed out)
              </p>
              <TabRadioButton
                defaultValue={
                  values.cart_config.general.relatedProductStyle || "carousel"
                }
                options={layoutStyles}
                onChange={(selectedValue) => {
                  setFieldValue(
                    "cart_config.general.relatedProductStyle",
                    selectedValue
                  );
                }}
              />
            </div>
          </div>
        )}
      </CustomiserUnit>

      {/* terms */}
      <CustomiserUnit
        paywall={
          !workspace.data?.feature_flag.apps?.cornercart?.cornerwidget
            .cornerwidget_custom_attributes
        }
        paywallPlan={13}
        title="Conditions Checkbox"
        description="Add a checkbox field to the order. You can use this for e.g to require visitors to accept your T&Cs or confirm they are above 18 years old etc."
      >
        <TabRadioButton
          defaultValue={values.cart_config.termsCheckbox.isActive}
          options={showHideSelect}
          onChange={(selectedValue) => {
            setFieldValue("cart_config.termsCheckbox.isActive", selectedValue);
          }}
        />

        {values.cart_config.termsCheckbox.isActive && (
          <div className="border p-3 rounded-lg mt-3">
            <div>
              <div className="mt-3">
                <Checkbox
                  onChange={(e) => {
                    setFieldValue(
                      "cart_config.termsCheckbox.defaultChecked",
                      e.target.checked
                    );
                  }}
                  label="Checked by default"
                  name="checkbox-basic"
                  defaultChecked={
                    values.cart_config.termsCheckbox.defaultChecked
                  }
                />
              </div>

              <div className="mt-3">
                <Checkbox
                  onChange={(e) => {
                    setFieldValue(
                      "cart_config.termsCheckbox.isRequired",
                      e.target.checked
                    );
                  }}
                  label="Required for checkout"
                  name="checkbox-basic"
                  defaultChecked={values.cart_config.termsCheckbox.isRequired}
                />
              </div>

              <div className="mt-3">
                <p className="text-sm font-bold">Checkbox label </p>
                <p className="text-xs text-gray-500">
                  (Suports plain text and HTML)
                </p>
                <Textfield
                  defaultValue={values.cart_config.termsCheckbox.content}
                  onChange={(e) => {
                    setFieldValue(
                      "cart_config.termsCheckbox.content",
                      e.target.value
                    );
                  }}
                  name="basic"
                />
              </div>
              <div className="mt-3">
                <p className="text-sm font-bold">Attribute name </p>
                <p className="text-xs text-gray-500">
                  (This name will be used to identify the status of this
                  checkbox along with the order in the Shopify dashboard)
                </p>
                <Textfield
                  defaultValue={values.cart_config.termsCheckbox.attributeName}
                  onChange={(e) => {
                    setFieldValue(
                      "cart_config.termsCheckbox.attributeName",
                      e.target.value
                    );
                  }}
                  name="basic"
                />
              </div>
            </div>
          </div>
        )}
      </CustomiserUnit>

      {/* Continue Shopping Button */}
      <CustomiserUnit
        paywallPlan={13}
        title="Continue Shopping Button"
        description="A button below the CTA button which closes the cart widget"
      >
        <TabRadioButton
          defaultValue={
            values.cart_config.general.showContinueShoppingBtn || false
          }
          options={showHideSelect}
          onChange={(selectedValue) => {
            setFieldValue(
              "cart_config.general.showContinueShoppingBtn",
              selectedValue
            );
          }}
        />
      </CustomiserUnit>
      {/* Go to Cart Page Button */}
      <CustomiserUnit
        paywallPlan={13}
        title="Go to Cart Page Button"
        description="A button which redirects user to themes cart page"
      >
        <TabRadioButton
          defaultValue={values.cart_config.general.showGoToCartBtn || false}
          options={showHideSelect}
          onChange={(selectedValue) => {
            setFieldValue("cart_config.general.showGoToCartBtn", selectedValue);
          }}
        />
      </CustomiserUnit>
    </div>
  );
}
