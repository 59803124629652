import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "react-query";
import Select from "@atlaskit/select";
import Button from "../../../components/Button";
import {
  queryWidgetSettings,
  updateWidgetSettings,
  queryCornerConvertWidgets,
  updatCornerStickybarWidget,
} from "../../../api/widgets";
import { updateSourceDetails } from "../../../api/workspace";
import { useWorkspace } from "../../../WorkspaceContext";
import { useGraphQl } from "../../../GraphqlClient";
import { langOptions, latestLangVersions } from "../../../utilities/variables";
import { useHistory } from "react-router-dom";

export default function LanguageSelector() {
  let history = useHistory();
  const workspace = useWorkspace();
  const [languageCode, setlanguageCode] = useState("en");
  const graphQL = useGraphQl();
  const { data: cowiData } = useQuery("queryWidgetSettings", () =>
    graphQL(queryWidgetSettings)
  );

  const { data: stickybarData, refetch } = useQuery(
    "queryCornerConvertWidgets",
    () => graphQL(queryCornerConvertWidgets)
  );

  const updateDefaultLanguage = async () => {
    await graphQL(updateSourceDetails, {
      sourceId: { id: workspace.data.sourceid },
      sourceInput: {
        default_language: languageCode,
        translated_languages: [languageCode],
      },
    });
  };
  const [updateDefaultLanguageMutation] = useMutation(updateDefaultLanguage, {
    onSuccess: () => {
      history.push("/get-started/2/");
    },
  });

  const updateStickyBarWidget = async (values) => {
    await graphQL(updatCornerStickybarWidget, {
      stickyBarId: values.id,
      stickyBarInput: values.config,
    });
  };

  const [updatCornerStickybarMutation] = useMutation(updateStickyBarWidget, {
    onSuccess: () => {
      updateDefaultLanguageMutation();
    },
    onError: () => {},
  });

  const updateWidget = async (values) => {
    await graphQL(updateWidgetSettings, {
      widgetSettingsId: values.id,
      widgetSettingsInput: values.config,
    });
  };

  const [updateWidgetMutation] = useMutation(updateWidget, {
    onSuccess: async () => {
      let stickybarLang =
        await require(`../../../i18n/default-values/stickybar/${languageCode}.json`);
      updatCornerStickybarMutation({
        id: { id: stickybarData && stickybarData.widget_stickybar[0].id },
        config: {
          sticky_atc_lang: {
            [`${languageCode}`]: stickybarLang,
            version: latestLangVersions.stickybarLang,
          },
        },
      });
    },
  });

  const handleSubmit = async () => {
    let cartCowiLang =
      await require(`../../../i18n/default-values/cart-cowi/${languageCode}.json`);
    updateWidgetMutation({
      id: { id: cowiData && cowiData.widgets_cornerwidget[0].id },
      config: {
        corner_cart_lang: {
          [`${languageCode}`]: cartCowiLang,
          version: latestLangVersions.cartCowiLang,
        },
      },
    });
  };

  return (
    <div className="laptop:flex mobile:block items-center h-full w-full mt-8 bg-white rounded-lg shadow-lg">
      <div className="p-8 shrink grow max-w-xl">
        <div className="mb-8  text-center ">
          <h1 className="font-bold text-blue-900 mb-3">
            Select default store language
          </h1>
          <p>
            You can always change this later. You can also add more language
            translations from the settings page
          </p>
        </div>
        {cowiData && stickybarData && (
          <div className="max-w-s flex justify-center mb-8">
            <Select
              isSearchable={false}
              value={langOptions.find(
                (option) => option.value === languageCode
              )}
              onChange={(e) => {
                setlanguageCode(e.value);
              }}
              className="w-48 mr-2"
              classNamePrefix="react-select"
              options={langOptions}
            />
          </div>
        )}
        <div className="flex justify-center px-8">
          <Button type="primary" onClick={() => handleSubmit()}>
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
}
