import React, { useState } from "react";
import Popup from "@atlaskit/popup";
import { RiCheckLine, RiCloseLine, RiQuestionLine } from "react-icons/ri";
import Tooltip from "@atlaskit/tooltip";

export default function TrustTag({ clickable, riskItem }) {
  // The higher the riskscore that we get from db means higher the risk. But in the UI we need to inverse it and make it higher the number we could trust it more. For this reason we subract the calculated number from one
  const calculatedPoints = 1 - riskItem.aggregateScore.point;
  const Trust = () => {
    if (calculatedPoints) {
      if (calculatedPoints <= 0.4)
        return (
          <span className="flex items-center justify-center text-red-600 px-2 py-1 border border-red-300 bg-red-100 rounded-lg bg-opacity-50 flex items-baseline hover:border-2">
            <span className="font-bold laptop:mr-1">
              {Math.round(calculatedPoints * 10)}
            </span>
            <span className="text-xs laptop:block mobile:hidden "> / 10</span>{" "}
          </span>
        );
      else if (calculatedPoints > 0.4 && calculatedPoints <= 0.7)
        return (
          <span className="flex items-center justify-center text-amber-600 px-2 py-1 border border-amber-300 bg-amber-100 rounded-lg bg-opacity-50 flex items-baseline hover:border-2">
            <span className="font-bold laptop:mr-1">
              {Math.round(calculatedPoints * 10)}
            </span>
            <span className="text-xs laptop:block mobile:hidden "> / 10</span>{" "}
          </span>
        );
      else if (calculatedPoints > 0.7 && calculatedPoints <= 0.9)
        return (
          <span className="flex items-center justify-center text-green-600 px-2 py-1 border border-green-300 bg-green-100 rounded-lg bg-opacity-50 flex items-baseline hover:border-2">
            <span className="font-bold laptop:mr-1">
              {Math.round(calculatedPoints * 10)}
            </span>
            <span className="text-xs laptop:block mobile:hidden"> / 10</span>
          </span>
        );
      else if (calculatedPoints > 0.9)
        return (
          <span className="flex items-center justify-center text-green-600 px-2 py-1 border border-green-300 bg-green-100 rounded-lg bg-opacity-50 flex items-baseline hover:border-2">
            <span className="font-bold laptop:mr-1">
              {Math.round(calculatedPoints * 10)}
            </span>
            <span className="text-xs laptop:block mobile:hidden"> / 10</span>{" "}
          </span>
        );
    } else
      return (
        <span className="text-gray-700 px-2 py-1 border border-gray-300 bg-gray-100 rounded-lg bg-opacity-50 flex items-baseline">
          Not enough Data
        </span>
      );
  };

  const TrustTitle = () => {
    if (calculatedPoints) {
      if (calculatedPoints <= 0.4)
        return (
          <span className="text-red-600 flex items-center justify-center ">
            <span>Suspicious </span>
          </span>
        );
      else if (calculatedPoints > 0.4 && calculatedPoints <= 0.7)
        return (
          <span className="text-yellow-700 flex items-center justify-center ">
            <span>Medium</span>
          </span>
        );
      else if (calculatedPoints > 0.7 && calculatedPoints <= 0.9)
        return (
          <span className="text-green-700 flex items-center justify-center ">
            <span>High</span>
          </span>
        );
      else if (calculatedPoints > 0.9)
        return (
          <span className="text-green-700 flex items-center justify-center ">
            <span>Trustworthy</span>
          </span>
        );
    } else
      return (
        <span className="text-gray-800 flex items-center justify-center ">
          <span>Not enough Data</span>
        </span>
      );
  };

  const [isRiskDetailsOpen, setIsRiskDetailsOpen] = useState(false);

  return (
    <Popup
      isOpen={isRiskDetailsOpen}
      placement="bottom-start"
      onClose={() => setIsRiskDetailsOpen(false)}
      content={() => (
        <div className=" p-6 max-w-sm max-h-60 overflow-y-auto">
          <div className="flex justify-center my-2">
            <div className={`text-center felx items-baseline`}>
              <span className="text-xl">
                <Trust showScore />
              </span>{" "}
            </div>
          </div>
          <div className="flex items-center justify-center text-center">
            <h2 className="font-bold text-base text-blue-900 mb-3">
              Trustability: <TrustTitle />
            </h2>
            <p className=" text-gray-600 mb-4 text-sm">
              Lower the score its more probable that the referrer itself made
              the purchase as the referee. The trustability score is calculated
              out of ten based on the following data
            </p>
          </div>
          <div>
            <table className="table-auto text-xs w-full">
              <thead className="py-2">
                <tr>
                  <th className="bg-gray-100 py-4 px-3 border-b text-left">
                    Metrics
                  </th>
                  <th className="bg-gray-100 py-4 px-3 border-b text-left"></th>
                </tr>
              </thead>
              <tbody className="">
                {/* Customer Id */}
                {riskItem.customerId && (
                  <tr>
                    <td className="py-1 px-3 border-b text-left">
                      {" "}
                      <span>
                        <Tooltip
                          position="top"
                          content="Comparing Customer ID of both referrer and referee"
                        >
                          <span className="cursor-help text-xs ">
                            Customer ID Comparison
                          </span>
                        </Tooltip>
                      </span>{" "}
                    </td>
                    <td className="py-1 px-3 border-b text-left">
                      {riskItem.customerId.isCredible ? (
                        <RiCheckLine className="text-green-500 text-xl" />
                      ) : (
                        <RiCloseLine className="text-red-500 text-xl" />
                      )}
                    </td>
                  </tr>
                )}
                {/* orderTimeLessThanThreshold */}
                {riskItem.orderTimeLessThanThreshold && (
                  <tr>
                    <td className="py-1 px-3 border-b text-left">
                      {" "}
                      <span>
                        <Tooltip
                          position="top"
                          content="Inspecting time difference between refferer's purchase and referee's purchase"
                        >
                          <span className="cursor-help text-xs ">
                            Order delay Threshold
                          </span>
                        </Tooltip>
                      </span>{" "}
                    </td>
                    <td className="py-3 px-3 border-b text-left">
                      {riskItem.orderTimeLessThanThreshold.isCredible ? (
                        <RiCheckLine className="text-green-500 text-xl" />
                      ) : (
                        <RiCloseLine className="text-red-500 text-xl" />
                      )}
                    </td>
                  </tr>
                )}
                {/* ipAddress */}
                {riskItem.ipAddress && (
                  <tr>
                    <td className="py-1 px-3 border-b text-left">
                      {" "}
                      <span>
                        <Tooltip
                          position="top"
                          content="Comparing IP Adresses of referrer and referee"
                        >
                          <span className="cursor-help text-xs ">
                            Comparing IP Address
                          </span>
                        </Tooltip>
                      </span>{" "}
                    </td>
                    <td className="py-3 px-3 border-b text-left">
                      {riskItem.ipAddress.isCredible ? (
                        <RiCheckLine className="text-green-500 text-xl" />
                      ) : (
                        <RiCloseLine className="text-red-500 text-xl" />
                      )}
                    </td>
                  </tr>
                )}

                {/* Shopify Risk Score */}
                {riskItem.shopifyRisk && (
                  <tr>
                    <td className="py-1 px-3 border-b text-left">
                      {" "}
                      <span>
                        <Tooltip
                          position="top"
                          content="Risk score given from shopify's side"
                        >
                          <span className="cursor-help text-xs ">
                            Shopify Transaction Risk Score
                          </span>
                        </Tooltip>
                      </span>{" "}
                    </td>
                    <td className="py-1 px-3 border-b text-left">
                      {riskItem.shopifyRisk.isCredible ? (
                        <RiCheckLine className="text-green-500 text-xl" />
                      ) : (
                        <RiCloseLine className="text-red-500 text-xl" />
                      )}
                    </td>
                  </tr>
                )}

                {/* billingAddress */}
                {riskItem.billingAddress && (
                  <tr>
                    <td className="py-1 px-3 border-b text-left">
                      {" "}
                      <span>
                        <Tooltip
                          position="top"
                          content="Comparing billing addresses of both referrer and referee"
                        >
                          <span className="cursor-help text-xs ">
                            Billing Address Comparison
                          </span>
                        </Tooltip>
                      </span>{" "}
                    </td>
                    <td className="py-3 px-3 border-b text-left">
                      {riskItem.billingAddress.isCredible ? (
                        <RiCheckLine className="text-green-500 text-xl" />
                      ) : (
                        <RiCloseLine className="text-red-500 text-xl" />
                      )}
                    </td>
                  </tr>
                )}

                {/* shippingAddress */}
                {riskItem.shippingAddress && (
                  <tr>
                    <td className="py-1 px-3 border-b text-left">
                      {" "}
                      <span>
                        <Tooltip
                          position="top"
                          content="Comparing shipping addresses of both referrer and referee"
                        >
                          <span className="cursor-help text-xs ">
                            Shipping Address Comparison
                          </span>
                        </Tooltip>
                      </span>{" "}
                    </td>
                    <td className="py-3 px-3 border-b text-left">
                      {riskItem.shippingAddress.isCredible ? (
                        <RiCheckLine className="text-green-500 text-xl" />
                      ) : (
                        <RiCloseLine className="text-red-500 text-xl" />
                      )}
                    </td>
                  </tr>
                )}

                {/* Email ID */}
                {riskItem.email && (
                  <tr>
                    <td className="py-1 px-3 border-b text-left">
                      {" "}
                      <span>
                        <Tooltip
                          position="top"
                          content="Comparing email IDs of both referrer and referee"
                        >
                          <span className="cursor-help text-xs ">
                            Email ID Comparison
                          </span>
                        </Tooltip>
                      </span>{" "}
                    </td>
                    <td className="py-1 px-3 border-b text-left">
                      {riskItem.email.isCredible ? (
                        <RiCheckLine className="text-green-500 text-xl" />
                      ) : (
                        <RiCloseLine className="text-red-500 text-xl" />
                      )}
                    </td>
                  </tr>
                )}
                {/* phoneNumber */}
                {riskItem.phoneNumber && (
                  <tr>
                    <td className="py-1 px-3 border-b text-left">
                      {" "}
                      <span>
                        <Tooltip
                          position="top"
                          content="Comparing phone numbers of referrer and referee"
                        >
                          <span className="cursor-help text-xs ">
                            Comparing phone numbers
                          </span>
                        </Tooltip>
                      </span>{" "}
                    </td>
                    <td className="py-3 px-3 border-b text-left">
                      {riskItem.phoneNumber.isCredible ? (
                        <RiCheckLine className="text-green-500 text-xl" />
                      ) : (
                        <RiCloseLine className="text-red-500 text-xl" />
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}
      trigger={(triggerProps) => (
        <button
          className="focus:outline-none"
          {...triggerProps}
          onClick={() => {
            if (clickable) setIsRiskDetailsOpen(!isRiskDetailsOpen);
          }}
        >
          <p className="desktop:text-base laptop:text-tiny mobile:text-xs text-gray-600 font-bold">
            <Trust />
          </p>
        </button>
      )}
    />
  );
}
