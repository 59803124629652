import React, { useState } from "react";
import Button from "../Button";
import Popup from "@atlaskit/popup";
import {
  RiAwardFill,
  RiBookMarkFill,
  RiArrowLeftLine,
  RiDatabaseLine,
} from "react-icons/ri";
import { useWorkspace } from "../../WorkspaceContext";

const CategoryHeader = () => {
  const workspace = useWorkspace();

  const [isRoadmapOpen, setIsRoadmapOpen] = useState(false);
  return (
    <div className=" col-start-2 col-end-2 p-1 desktop:p-3 flex justify-end z-10 items-center">
      <div className="mx-4 flex">
        {/* <Popup
          isOpen={isRoadmapOpen}
          placement="bottom-end"
          onClose={() => setIsRoadmapOpen(false)}
          content={() => (
            <div className="p-2 desktop:p-4">
              <Button
                type="link"
                icon={<RiBookMarkFill />}
                onClick={() => {
                  window
                    .open(
                      "https://trello.com/b/pg3WraGU/support-corner-product-roadmap",
                      "_blank"
                    )
                    .focus();
                }}
              >
                Support Corner
              </Button>
              <Button
                type="link"
                icon={<RiAwardFill />}
                onClick={() => {
                  window
                    .open(
                      "https://trello.com/b/VSb9rTIW/offers-corner-product-roadmap",
                      "_blank"
                    )
                    .focus();
                }}
              >
                Offers Corner
              </Button>
            </div>
          )}
          trigger={(triggerProps) => (
            <button
              {...triggerProps}
              className="flex items-center font-bold text-blue-900 focus:outline-none "
              type="link"
              onClick={() => setIsRoadmapOpen(!isRoadmapOpen)}
            >
              <RiDatabaseLine className="mr-3 text-lg desktop:text-xl" />
              <span className="text-xs desktop:text-sm">Product Roadmap</span>
            </button>
          )}
        /> */}
        <button
          className="flex items-center font-bold text-gray-500 focus:outline-none ml-4 border  p-1 rounded-lg border-gray-400 "
          type="link"
          onClick={() =>
            (window.location.href = `https://${workspace.data.storeMyShopifyUrl}/admin/apps`)
          }
        >
          <RiArrowLeftLine className="mr-3 text-lg desktop:text-xl" />
          <span className="text-xs desktop:text-sm">
            Go back to Shopify admin
          </span>
        </button>
      </div>
    </div>
  );
};
export default CategoryHeader;
