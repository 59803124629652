import React, { useState, useEffect } from "react";
import toaster from "toasted-notes";
import { useQuery, useMutation, useQueryCache } from "react-query";
import CustomiserUnit from "../../../../components/CustomiserUnit";
import Select from "@atlaskit/select";
import Textfield from "@atlaskit/textfield";
import Button from "../../../../components/Button";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import { Formik } from "formik";
import { useGraphQl } from "../../../../GraphqlClient";
import { useWorkspace } from "../../../../WorkspaceContext";
import {
  queryWidgetSettings,
  updateWidgetSettings,
} from "../../../../api/widgets";
import { langOptions } from "../../../../utilities/variables";

export default function OfferPage() {
  const graphQL = useGraphQl();
  const workspace = useWorkspace();
  const queryCache = useQueryCache();
  
  const [currentLanguages, setCurrentLanguages] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState();
  const [initialLanguage, setInitialLanguage] = useState();

  const { data, refetch } = useQuery("queryWidgetSettings", () =>
    graphQL(queryWidgetSettings)
  );

  const updateWidget = async (values) => {
    await graphQL(updateWidgetSettings, {
      widgetSettingsId: values.id,
      widgetSettingsInput: values.config,
    });
    refetch();
  };

  const [updateWidgetMutation] = useMutation(updateWidget, {
    onSuccess: () => {
      queryCache.invalidateQueries("queryWidgetSettings");
      toaster.notify("Widget settings saved successfully", {
        duration: 2000,
      });
    },
  });

   // This useEffect triggers when selected language is changed and when data is updated after saving
  // And this combines the data from db and the default values saved in i18n so that even if user doesnt have certain language values in his db it will be filled from the default values

    useEffect(async () => {
        if (selectedLanguage && data) {
          let currentLangJSON =
            data &&
            data.widgets_cornerwidget[0].offer_page_lang[`${selectedLanguage}`];
          let defaultValues =
            await require(`../../../../i18n/default-values/offer-page/${selectedLanguage}.json`);
          setInitialLanguage({ ...defaultValues, ...currentLangJSON });
        }
      }, [data,selectedLanguage]);

  useEffect(() => {
    if (data) {
      let langConfig = data.widgets_cornerwidget[0].offer_page_lang;
      let langArray = Object.keys(langConfig);
      setCurrentLanguages(langArray);
      setSelectedLanguage(data.workspaces[0].source.default_language);
    }
  }, [data]);

  if (initialLanguage)
    return (
      <Formik
        enableReinitialize
        initialValues={initialLanguage}
        onSubmit={(values) => {
          const offer_page_lang = {
            offer_page_lang: {
              ...data.widgets_cornerwidget[0].offer_page_lang,
              [`${selectedLanguage}`]: values,
            },
          };

          updateWidgetMutation({
            id: { id: data && data.widgets_cornerwidget[0].id },
            config: offer_page_lang,
          });
        }}
      >
        {(props) => (
          <div className=" h-full overflow-y-auto">
            <div className=" sticky top-0 flex desktop:p-4 p-2 border-b bg-white items-center justify-between">
              <div className="px-3 py-2">
                <h2 className="text-blue-900 font-bold mb-2 desktop:text-xl text-lg">
                  Offer Widget UI Text editor
                </h2>
                <p className="text-tiny desktop:text-base">
                  From this page, you can edit all customer facing UI copy/text
                </p>
                {!props.dirty ? (
                  <div className="flex items-center mt-5">
                    <p className="font-semibold">Select Language</p>
                    {data && (
                      <Select
                        value={langOptions.find(
                          (option) => option.value === selectedLanguage
                        )}
                        onChange={(e) => {
                          setSelectedLanguage(e.value);
                        }}
                        isDisabled={props.dirty}
                        className="w-48 ml-2"
                        classNamePrefix="react-select"
                        options={langOptions.filter((item) =>
                          currentLanguages.includes(item.value)
                        )}
                      />
                    )}
                  </div>
                ) : (
                  <div className="p-3 mt-5 border rounded-md">
                    <small>
                      <b>
                        {
                          langOptions.filter(
                            (item) => item.value === selectedLanguage
                          )[0].label
                        }
                        :
                      </b>{" "}
                      Please save or discard the current changes before
                      switching languages.
                    </small>
                  </div>
                )}
              </div>
              <div className="mr-4 flex shrink-0">
                {props.dirty && (
                  <Button
                    type="link"
                    onClick={() => {
                      props.resetForm();
                    }}
                  >
                    Discard changes
                  </Button>
                )}
                <Button
                  onClick={() => props.submitForm()}
                  type="primary"
                  disabled={!props.dirty}
                >
                  {" "}
                  Save settings
                </Button>
              </div>
            </div>
            <div className="desktop:p-8 px-4 py-2 max-w-3xl overflow-y-auto">
              {/* gift_share_page_title */}
              <CustomiserUnit
                title="Gift share page title "
              >
                <div className="boreder rounded-md bg-gray-100 text-sm p-3 mb-3">
                  <div className="mb-2">
                    <span className="font-mono font-bold bg-gray-300 px-2 py-1 rounded-md">
                      &lt;referrer_name&gt;
                    </span>{" "}
                    : Will be replaced to show the name of the person who
                    referred the visitor
                  </div>
                  <div>
                    <span className="font-mono font-bold bg-gray-300 px-2 py-1 rounded-md">
                      &lt;gift_offer&gt;
                    </span>{" "}
                    : Will be replaced to show the gift offer amount
                  </div>
                </div>
                <Textfield
                  value={props.values.gift_share_page_title}
                  onChange={(e) => {
                    props.setFieldValue(
                      "gift_share_page_title",
                      e.target.value
                    );
                  }}
                  name="basic"
                />
              </CustomiserUnit>

              {/* gift_share_page_description*/}
              <CustomiserUnit
                title="Gift share page description "
              >
                <Textfield
                  value={props.values.gift_share_page_description}
                  onChange={(e) => {
                    props.setFieldValue(
                      "gift_share_page_description",
                      e.target.value
                    );
                  }}
                  name="basic"
                />
              </CustomiserUnit>

              {/* copy_text */}
              <CustomiserUnit
                title="Copy code button text"
              >
                <Textfield
                  value={props.values.copy_text}
                  onChange={(e) => {
                    props.setFieldValue("copy_text", e.target.value);
                  }}
                  name="basic"
                />
              </CustomiserUnit>

              {/* share_modal_cta_text */}
              <CustomiserUnit
                title="Share page CTA Button Text"
              >
                <Textfield
                  value={props.values.share_modal_cta_text}
                  onChange={(e) => {
                    props.setFieldValue("share_modal_cta_text", e.target.value);
                  }}
                  name="basic"
                />
              </CustomiserUnit>

              {/* valid_upto */}
              {/* <CustomiserUnit
                title="Valid upto "
              >
                <Textfield
                  value={props.values.valid_upto}
                  onChange={(e) => {
                    props.setFieldValue("valid_upto", e.target.value);
                  }}
                  name="basic"
                />
              </CustomiserUnit> */}

              {/* reward_share_page_title */}
              <CustomiserUnit
                title="Reward page title"
              >
                <div className="boreder rounded-md bg-gray-100 text-sm p-3 mb-3">
                  <div>
                    <span className="font-mono font-bold bg-gray-300 px-2 py-1 rounded-md">
                      &lt;reward_offer&gt;
                    </span>{" "}
                    : Will be replaced to show the reward offer amount
                  </div>
                </div>
                <Textfield
                  value={props.values.reward_share_page_title}
                  onChange={(e) => {
                    props.setFieldValue(
                      "reward_share_page_title",
                      e.target.value
                    );
                  }}
                  name="basic"
                />
              </CustomiserUnit>

              {/* reward_share_page_description */}
              <CustomiserUnit
                title="Reward page description "
              >
                <Textfield
                  value={props.values.reward_share_page_description}
                  onChange={(e) => {
                    props.setFieldValue(
                      "reward_share_page_description",
                      e.target.value
                    );
                  }}
                  name="basic"
                />
              </CustomiserUnit>

              {/* reward_share_page_next_reward_title */}
              <CustomiserUnit
                title="Reward page next reward title "
              >
                <Textfield
                  value={props.values.reward_share_page_next_reward_title}
                  onChange={(e) => {
                    props.setFieldValue(
                      "reward_share_page_next_reward_title",
                      e.target.value
                    );
                  }}
                  name="basic"
                />
              </CustomiserUnit>

              {/* reward_share_page_next_reward_description */}
              <CustomiserUnit
                title="Reward page next reward description "
              >
                <Textfield
                  value={props.values.reward_share_page_next_reward_description}
                  onChange={(e) => {
                    props.setFieldValue(
                      "reward_share_page_next_reward_description",
                      e.target.value
                    );
                  }}
                  name="basic"
                />
              </CustomiserUnit>


              {/* invalid_gift_share_modal_title*/}
              <CustomiserUnit
                title="Gift URl abuse warning title "
                description="Title of the modal which is shown when the referrer itself tries to redeem a code "
              >
                <Textfield
                  value={props.values.invalid_gift_share_modal_title}
                  onChange={(e) => {
                    props.setFieldValue(
                      "invalid_gift_share_modal_title",
                      e.target.value
                    );
                  }}
                  name="basic"
                />
              </CustomiserUnit>

              {/*invalid_share_modal_description*/}
              <CustomiserUnit
                title="Gift URl abuse warning description "
                description="Description on the modal which is shown when the referrer itself tries to redeem a code "
              >
                <Textfield
                  value={props.values.invalid_share_modal_description}
                  onChange={(e) => {
                    props.setFieldValue(
                      "invalid_share_modal_description",
                      e.target.value
                    );
                  }}
                  name="basic"
                />
              </CustomiserUnit>

              {/*invalid_modal_cta*/}
              <CustomiserUnit
                title="Gift URl abuse warning button "
                description="Button on the modal which is shown when the referrer itself tries to redeem a code "
              >
                <Textfield
                  value={props.values.invalid_modal_cta}
                  onChange={(e) => {
                    props.setFieldValue(
                      "invalid_modal_cta",
                      e.target.value
                    );
                  }}
                  name="basic"
                />
              </CustomiserUnit>
            </div>
          </div>
        )}
      </Formik>
    );
  else return <LoadingSpinner />;
}
