import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "react-query";
import Textfield from "@atlaskit/textfield";
import Button from "../../../components/Button";
import TabRadioButton from "../../../components/TabRadioButton";
import {
  queryWidgetSettings,
  updateWidgetSettings,
  queryCornerConvertWidgets,
  updatCornerStickybarWidget,
} from "../../../api/widgets";
import { updateSourceDetails } from "../../../api/workspace";
import { RiWindow2Fill, RiShoppingCart2Fill } from "react-icons/ri";
import { useGraphQl } from "../../../GraphqlClient";
import { useWorkspace } from "../../../WorkspaceContext";
import { useHistory } from "react-router-dom";

export default function FinalStep() {
  let history = useHistory();
  const workspace = useWorkspace();
  const graphQL = useGraphQl();

  const { data: cowiData, refetch: cowiRefetch } = useQuery(
    "queryWidgetSettings",
    () => graphQL(queryWidgetSettings)
  );
  const { data: stickybarData, refetch: satcRefetch } = useQuery(
    "queryCornerConvertWidgets",
    () => graphQL(queryCornerConvertWidgets)
  );

  const updateStickyBarWidget = async (values) => {
    await graphQL(updatCornerStickybarWidget, {
      stickyBarId: values.id,
      stickyBarInput: values.config,
    });
  };

  const [updatCornerStickybarMutation] = useMutation(updateStickyBarWidget, {
    onSuccess: () => {
      satcRefetch();
    },
    onError: () => {},
  });

  const updateWidget = async (values) => {
    await graphQL(updateWidgetSettings, {
      widgetSettingsId: values.id,
      widgetSettingsInput: values.config,
    });
  };

  const [updateWidgetMutation] = useMutation(updateWidget, {
    onSuccess: () => {
      cowiRefetch();
    },
  });

  const updateOnboarding = async () => {
    await graphQL(updateSourceDetails, {
      sourceId: { id: workspace.data.sourceid },
      sourceInput: {
        cornercart_onboarding: 100,
      },
    });
  };

  const [updateOnboardingProgressMutation] = useMutation(updateOnboarding, {
    onSuccess: () => {
      window.location.href = "/cart";
    },
  });

  const visibilityOptions = [
    { label: "Show", value: true },
    { label: "Hide", value: false },
  ];
  const handleSubmit = () => {
    updateOnboardingProgressMutation();
  };
  return (
    <div className="laptop:flex mobile:block items-center h-full w-full mt-8 bg-white rounded-lg shadow-lg">
      <div className="p-8 shrink grow max-w-xl text-center">
        <div className="mb-8">
          <h1 className="font-bold text-center text-blue-900 mb-3">
            Almost there 🙌
          </h1>
          <p>Choose widgets to enable on your store</p>
        </div>

        {stickybarData && cowiData && (
          <>
            <div className="flex my-3 p-4 border rounded-lg items-center">
              <div className="p-3 rounded-lg bg-primary-500 text-white mr-3">
                <RiShoppingCart2Fill size="20px" />
              </div>
              <div className="font-bold flex-grow text-left">
                <p>Cart Widget</p>
              </div>
              <div>
                <TabRadioButton
                  defaultValue={
                    cowiData.widgets_cornerwidget[0].cart_launcher_config
                      .isActive
                  }
                  options={visibilityOptions}
                  onChange={(selectedType) => {
                    updateWidgetMutation({
                      id: {
                        id: cowiData && cowiData.widgets_cornerwidget[0].id,
                      },
                      config: {
                        cart_launcher_config: {
                          ...cowiData.widgets_cornerwidget[0]
                            .cart_launcher_config,
                          isActive: selectedType,
                        },
                      },
                    });
                  }}
                />
              </div>
            </div>
            <div className="flex my-3 p-4 border rounded-lg items-center">
              <div className="p-3 rounded-lg bg-primary-500 text-white mr-3">
                <RiWindow2Fill size="20px" />
              </div>
              <div className="font-bold flex-grow text-left">
                <p> Add to Cart Bar</p>
              </div>
              <div>
                <TabRadioButton
                  defaultValue={stickybarData.widget_stickybar[0].is_active}
                  options={visibilityOptions}
                  onChange={(selectedType) => {
                    updatCornerStickybarMutation({
                      id: {
                        id:
                          stickybarData && stickybarData.widget_stickybar[0].id,
                      },
                      config: {
                        is_active: selectedType,
                      },
                    });
                  }}
                />
              </div>
            </div>
          </>
        )}

        <div className="flex justify-center px-8">
          <Button type="primary" onClick={() => handleSubmit()}>
            Continue to Dashboard
          </Button>
        </div>
      </div>
    </div>
  );
}
