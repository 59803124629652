import React, { useState } from "react";
import { useWorkspace } from "../WorkspaceContext";
import Textfield from "@atlaskit/textfield";
import Button from "./Button";
import { RiDeleteBin2Line, RiLoader2Line } from "react-icons/ri";
import toaster from "toasted-notes";

/**
 * A UI Component which takes a collection handle and returns a array of products inside. in the format {
      collectionHandle: collectionHandle,
      productArray: productArray,
    }
 * @param {string} value - value of the property inside product array
 * @param {function} onChange - what happens on value change
 */
const CollectionProductExtractor = ({ value, onChange }) => {
  const workspace = useWorkspace();
  const [collectionHandle, setCollectionHandle] = useState(
    value ? value.collectionHandle : ""
  );
  const [isLoading, setisLoading] = useState(false);
  const syncCollectionProducts = async (handle) => {
    try {
      setisLoading(true);
      let reachedEndOfList = false;
      let page = 1;
      let productArray = [];
      while (!reachedEndOfList) {
        const productArraySelection = await (
          await window.fetch(
            `https://${workspace.data.storeMyShopifyUrl}/collections/${handle}/products.json?limit=250&page=${page}`
          )
        ).json();
        if (
          productArraySelection &&
          productArraySelection.products.length > 0
        ) {
          productArray = [...productArray, ...productArraySelection.products];
          page = page + 1;
        } else {
          reachedEndOfList = true;
          setisLoading(false);
        }
      }
      if (productArray.length > 0) {
        let productIdArray = productArray.map(
          product => product.id
        );
        onChange({
          collectionHandle: collectionHandle,
          productArray: productIdArray,
        });
      } else {
        toaster.notify("No products found in the collection", {
          duration: 6000,
        });
      }
    } catch (err) {
      toaster.notify(
        "Failed to fetch the products. Make sure the store front is not password protected. If the error persists contact support",
        {
          duration: 12000,
        }
      );
      setisLoading(false);
    }
  };
  return (
    <div>
      {value && value.collectionHandle && value.productArray ? (
        <div className="flex items-center p-3 border rounded-lg">
          <div className="grow">
            <label
              htmlFor="blocked-product-title"
              className="text-xs text-gray-400 font-bold"
            >
              BLOCKED COLLECTION
            </label>
            <p className="text-lg font-bold">{value.collectionHandle}</p>
          </div>
          <div className="grow">
            <label
              htmlFor="blocked-product-count"
              className="text-xs text-gray-400 font-bold"
            >
              PRODUCT COUNT
            </label>
            <p className="text-lg font-bold">{value.productArray.length}</p>
          </div>
          <Button
            onClick={() => {
              syncCollectionProducts(value.collectionHandle);
            }}
            icon={isLoading && <RiLoader2Line className="animate-spin" />}
            type="primary"
            className="mx-3"
            disabled={isLoading}
          >
            {isLoading ? "Fetching Products" : "Re-Sync Products"}
          </Button>
          <Button
            onClick={() => {
              onChange({});
            }}
            type="danger-ghost"
            icon={<RiDeleteBin2Line />}
          >
            Remove
          </Button>
        </div>
      ) : (
        <div className="flex items-center p-3 border rounded-lg">
          <div className="grow flex items-center">
            <label
              htmlFor="blocked-product-title"
              className="text-xs text-gray-400 font-bold grow"
            >
              Type Collection Handle here
            </label>
            <Textfield
              value={collectionHandle}
              onChange={(onChangeEvent) => {
                setCollectionHandle(onChangeEvent.target.value);
              }}
              name="basic"
            />
          </div>
          <Button
            onClick={() => {
              syncCollectionProducts(collectionHandle);
            }}
            icon={isLoading && <RiLoader2Line className="animate-spin" />}
            type="primary"
            className="mx-3"
            disabled={isLoading}
          >
            {isLoading ? "Fetching Products" : "Submit"}
          </Button>
        </div>
      )}
    </div>
  );
};

export default CollectionProductExtractor;
