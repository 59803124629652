import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "react-query";
import { Formik } from "formik";
import Button from "../../../components/Button";
import ColorPicker from "../../../components/ColorPicker";
import {
  queryWidgetSettings,
  updateWidgetSettings,
  queryCornerConvertWidgets,
  updatCornerStickybarWidget,
  queryDefaultWidgetConfigs,
} from "../../../api/widgets";
import { useGraphQl } from "../../../GraphqlClient";
import { useHistory } from "react-router-dom";
import WidgetPreview from "../../cart/Widget/WidgetPreview";

export default function ColorSelect() {
  let history = useHistory();
  const graphQL = useGraphQl();
  const convertRGB = (rgb) => `rgb(${rgb.r},${rgb.g},${rgb.b})`;
  const { data: cowiData } = useQuery("queryWidgetSettings", () =>
    graphQL(queryWidgetSettings)
  );
  const { data: defaultValues } = useQuery("queryDefaultWidgetConfigs", () =>
    graphQL(queryDefaultWidgetConfigs)
  );
  const [isCowiUpdated, setIsCowiUpdated] = useState(false);
  const [isSatcUpdated, setIsSatcUpdated] = useState(false);

  const { data: stickybarData, refetch } = useQuery(
    "queryCornerConvertWidgets",
    () => graphQL(queryCornerConvertWidgets)
  );

  const updateStickyBarWidget = async (values) => {
    await graphQL(updatCornerStickybarWidget, {
      stickyBarId: values.id,
      stickyBarInput: values.config,
    });
  };

  const [updatCornerStickybarMutation] = useMutation(updateStickyBarWidget, {
    onSuccess: () => {
      setIsSatcUpdated(true);
    },
    onError: () => {},
  });

  const updateWidget = async (values) => {
    await graphQL(updateWidgetSettings, {
      widgetSettingsId: values.id,
      widgetSettingsInput: values.config,
    });
  };

  const [updateWidgetMutation] = useMutation(updateWidget, {
    onSuccess: () => {
      setIsCowiUpdated(true);
    },
  });

  let defaultCartConfig =
    defaultValues &&
    defaultValues.admin_config_templates.find(
      (items) => items.name === "cartConfig"
    );
  let defaultCartLauncherConfig =
    defaultValues &&
    defaultValues.admin_config_templates.find(
      (items) => items.name === "cartLauncherConfig"
    );

  // when both mutation successfully get completed user is redirected to the next step
  useEffect(() => {
    if (isCowiUpdated && isSatcUpdated) history.push("/get-started/4/");
  }, [isCowiUpdated, isSatcUpdated]);

  return (
    <div className="laptop:flex mobile:block items-center h-full w-full mt-8 ">
      {cowiData &&
        stickybarData &&
        defaultCartLauncherConfig &&
        defaultCartConfig && (
          <Formik
            enableReinitialize
            initialValues={{
              cart_config: defaultCartConfig.value,
              cart_launcher_config: defaultCartLauncherConfig.value,
              stickybar_config:
                stickybarData.widget_stickybar[0].stickybar_config,
              sticky_atc_lang:
                stickybarData.widget_stickybar[0].sticky_atc_lang,
              corner_cart_lang:
                cowiData.widgets_cornerwidget[0].corner_cart_lang,
            }}
            onSubmit={(values) => {
              updateWidgetMutation({
                id: { id: cowiData.widgets_cornerwidget[0].id },
                config: {
                  cart_config: values.cart_config,
                  cart_launcher_config: values.cart_launcher_config,
                },
              });
              updatCornerStickybarMutation({
                id: {
                  id: stickybarData && stickybarData.widget_stickybar[0].id,
                },
                config: {
                  stickybar_config: values.stickybar_config,
                },
              });
            }}
          >
            {({ values, submitForm, setFieldValue }) => {
              return (
                <>
                  <div className="p-8 shrink grow max-w-md bg-white rounded-lg shadow-lg mr-4">
                    <div className="mb-8   ">
                      <h1 className="font-bold text-blue-900 mb-3">
                        Select your brand colors.
                      </h1>
                      <p>
                        Let's start with just the basics. You'll have lots more
                        options to customise your branding later.
                      </p>
                    </div>

                    <div className="flex flex-wrap border  rounded-lg py-3">
                      <div className="max-w-sm flex border-r  px-4 items-center w-1/2 ">
                        <ColorPicker
                          color={values.cart_config.colors.accentBg}
                          onChange={(color) => {
                            setFieldValue(
                              "cart_config.banner.gradientEnd",
                              convertRGB(color.rgb)
                            );
                            setFieldValue(
                              "cart_config.banner.gradientStart",
                              convertRGB(color.rgb)
                            );
                            setFieldValue(
                              "cart_config.colors.accentBg",
                              convertRGB(color.rgb)
                            );
                            setFieldValue(
                              "stickybar_config.design.desktop.colors.ctaBgColor",
                              convertRGB(color.rgb)
                            );
                            setFieldValue(
                              "stickybar_config.design.mobile.colors.ctaBgColor",
                              convertRGB(color.rgb)
                            );
                          }}
                        />
                        <p className="ml-3 text-sm">Background Color</p>
                      </div>
                      <div className="max-w-sm flex px-4 items-center w-1/2">
                        <ColorPicker
                          color={values.cart_config.colors.accentFg}
                          onChange={(color) => {
                            setFieldValue(
                              "cart_config.colors.accentFg",
                              convertRGB(color.rgb)
                            );
                            setFieldValue(
                              "stickybar_config.design.desktop.colors.ctaFgColor",
                              convertRGB(color.rgb)
                            );
                            setFieldValue(
                              "stickybar_config.design.mobile.colors.ctaFgColor",
                              convertRGB(color.rgb)
                            );
                          }}
                        />
                        <p className="ml-3 text-sm">Foreground Color</p>
                      </div>
                    </div>

                    <div className="flex justify-center px-8 mt-4">
                      <Button
                        type="primary"
                        onClick={() => {
                          submitForm();
                        }}
                      >
                        Continue
                      </Button>
                    </div>
                  </div>
                  <div className="grow mobile:hidden laptop:flex items-center justify-center overflow-y-auto">
                    <div className=" origin-top scale-75 ">
                      <WidgetPreview
                        cartConfig={values.cart_config}
                        stickybarConfig={values.stickybar_config}
                        stickyAtcWidgetLang={values.sticky_atc_lang}
                        cornerCartWidgetLang={values.corner_cart_lang}
                      />
                    </div>
                  </div>
                </>
              );
            }}
          </Formik>
        )}
    </div>
  );
}
