import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Button from "../../../../components/Button";
import CustomiserUnit from "../../../../components/CustomiserUnit";
import GradientPicker from "../../../../components/GradientPicker";
import ColorPicker from "../../../../components/ColorPicker";
import TabRadioButton from "../../../../components/TabRadioButton";
import ThankYouPreview from "../../../offers/Referral/SimpleReferralEdit/ThankyouPage/ThankYouPreview";

export default function ThankyouPage({
  simplePostPurchaseConfig,
  setSimplePostPurchaseConfig,
  simpleGiftConfig,
  rewardType,
  simpleRewardConfig,
  goFront,
  goBack,
}) {
  let history = useHistory();

  const convertRGB = (rgb) => `rgb(${rgb.r},${rgb.g},${rgb.b})`;

  return (
    <div className="laptop:flex flex-nowrap mobile:block  items-center h-full mt-8">
      <div className="p-8 grow max-w-xl">
        <div className="mb-8">
          <div className="flex ">
            <img
              className="grow-0"
              width="120px"
              src="https://assets.cornercart.io/coca-v2/images/onboard/ofco/onboard-1.png"
            />
          </div>
          <h1 className="text-4xl font-light text-blue-900">
            Thank You Page Widget
          </h1>
          <p>
            For starters lets customize the colors of our thank you page widget.
            More customisations are available in our settings but for now let us
            start simple
          </p>
        </div>
        <div className="max-h-80 desktop:max-h-96  overflow-auto mb-5 px-8 bg-white shadow-lg rounded-lg">
          <CustomiserUnit
            title="Widget Background Color"
            description="Choose the background color of the widget"
          >
            <GradientPicker
              startColor={simplePostPurchaseConfig.bgStartColor}
              endColor={simplePostPurchaseConfig.bgEndColor}
              onChange={(startColor, endColor) => {
                setSimplePostPurchaseConfig({
                  ...simplePostPurchaseConfig,
                  bgStartColor: startColor,
                  bgEndColor: endColor,
                });
              }}
            />
          </CustomiserUnit>

          <CustomiserUnit
            title="Call To Action Button Color"
            description="Choose the background color of the Call to action button "
          >
            <div className="max-w-sm border rounded-lg p-3 flex items-center">
              <ColorPicker
                color={simplePostPurchaseConfig.ctaColor}
                onChange={(color) => {
                  setSimplePostPurchaseConfig({
                    ...simplePostPurchaseConfig,
                    ctaColor: convertRGB(color.rgb),
                  });
                }}
              />
              <p className="ml-3">Choose a color</p>
            </div>
          </CustomiserUnit>

          <CustomiserUnit
            title="Widget Foreground Color"
            description="Choose the color for the text and icon on the widget"
          >
            <TabRadioButton
              defaultValue={simplePostPurchaseConfig.fgColor}
              options={[
                { value: "light", label: "Light" },
                { value: "dark", label: "Dark" },
              ]}
              onChange={(e) => {
                setSimplePostPurchaseConfig({
                  ...simplePostPurchaseConfig,
                  fgColor: e,
                });
              }}
            />
          </CustomiserUnit>

          <CustomiserUnit
            title="Popup theme color"
            description="Choose the theme color for the popup that appears once you click the CTA button"
          >
            <div className="max-w-sm border rounded-lg p-3 flex items-center">
              <ColorPicker
                color={simplePostPurchaseConfig.popupThemeColor}
                onChange={(color) => {
                  setSimplePostPurchaseConfig({
                    ...simplePostPurchaseConfig,
                    popupThemeColor: convertRGB(color.rgb),
                  });
                }}
              />
              <p className="ml-3">Choose a color</p>
            </div>
          </CustomiserUnit>
        </div>
        <div className="flex justify-end ">
          <Button
            className="mr-8"
            type="link"
            onClick={() => {
              goBack();
            }}
          >
            Go back
          </Button>
          <button
            onClick={() => {
              goFront();
            }}
            className="text-center py-6 px-24 bg-blue-600 text-white font-bold rounded-lg hover:bg-blue-900 transition-all"
          >
            Continue
          </button>
        </div>
      </div>
      <div>
        <ThankYouPreview
          config={simplePostPurchaseConfig}
          giftConfig={simpleGiftConfig}
          rewardType={rewardType}
          rewardConfig={simpleRewardConfig}
          maxDiscountValue={0}
          minOrderValue={0}
          terms="Reward coupons can be redeemed only once. Reward coupons are sent by email. You agree to permit us to use the email-id you provided to send these emails. The Minimum purchase amount may apply for gift and/or reward coupons. Other conditions may apply. Please email us if you need clarifications."
        />
      </div>
    </div>
  );
}
