import React, { Children } from "react";
import { RiLoader2Line } from "react-icons/ri";

/**
 * A element that takes over the whole screen and renders on top
 * @component
 * @param {Boolean} isOpen - Is the TakeOverDrawer open or not
 * @param {Function} onClose - What should happen on close
 * @param {Node} children - contents of the TakeOverDrawer
 * @param {Node} actions - contents of the TakeOverDrawer
 * @param {String} title - Title of the drawer
 * @param {String} description - description of the drawer
 */
const TakeOverDrawer = ({ isOpen, onClose, children, actions, title,description }) => {
  return (
    <div
      className={`${
        isOpen ? "pointer-events-auto" : "pointer-events-none opacity-0"
      } flex items-end fixed bottom-0 left-0  z-40 w-screen h-screen  px-5 transition-all`}
    >
      <button
        onClick={onClose}
        className={`opacity-10 absolute bottom-0 left-0 bg-black  w-screen z-10 h-screen  transition-all`}
      ></button>
      <div
        className={`flex flex-col ${
          isOpen ? "translate-y-0" : "translate-y-full"
        } w-full h-[90%] bg-white rounded-t-lg z-20 transition-all shadow-lg`}
      >
        <div className="p-8 border-b flex items-center justify-between">
          <div>
          <h2 className="text-2xl font-bold text-blue-900">{title}</h2>
          </div>
          {actions && <div>{actions}</div>}
        </div>
        {children}
      </div>
    </div>
  );
};

export default TakeOverDrawer;
