import React, { useState, useEffect } from "react";
import { useQuery, useMutation, useQueryCache } from "react-query";
import InlineEdit from "@atlaskit/inline-edit";
import Textfield from "@atlaskit/textfield";
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import List from "../../../../components/List";
import ArticleList from "../../../../components/ArticleList";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import SucoPricingModal from "../../../../components/SucoPricingModal";
import { useWorkspace } from "../../../../WorkspaceContext";
import PageViewContainer from "../../../../components/structure/PageViewContainer";
import Button from "../../../../components/Button";
import toaster from "toasted-notes";
import { useGraphQl } from "../../../../GraphqlClient";
import {
  insertAssistantArticle,
  queryAllAssistantArticles,
  queryArticleCategories,
  updateAssistantCategory,
  deleteAssistantCategory,
  bulkUpdateAssistantArticle,
} from "../../../../api/support";
import {
  RiFileAddLine,
  RiDeleteBin2Line,
  RiEditFill,
  RiFileWarningFill,
  RiEyeFill,
  RiEyeOffFill,
} from "react-icons/ri";
import IconSelector from "../../../../components/IconSelector";

const CategoryEdit = (historyProps) => {
  const [selectedArticles, setSelectedArticles] = useState([]);
  const [isCategoryIconModalOpen, setIsCategoryIconModalOpen] = useState(false);
  const [errorFlag, setErrorFlag] = useState(false);
  const [isAddArticleModalOpen, setIsAddArticleModalOpen] = useState(false);
  const [isBillingModalOpen, setIsBillingModalOpen] = useState(false);
  const [isCategoryDeleteWarningOpen, setIsCategoryDeleteWarningOpen] =
    useState(false);

  const [iconUrl, setIconUrl] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [showInPage, setShowInPage] = useState(true);
  const [showInWidget, setShowInWidget] = useState(true);

  const graphQL = useGraphQl();
  const queryCache = useQueryCache();
  const workspace = useWorkspace();

  const defaultArticleValue = {
    title: "untitled",
    body: "<p>Write your article here</p>",
    is_published: false,
    is_featured: true,
    lang: "en",
    statistics: { likes: 0, views: 0 },
    accountid: workspace.data && workspace.data.accountid,
    sourceid: workspace.data && workspace.data.sourceid,
    categoryid: historyProps.match.params.id,
    position_rank: 9999,
    visibility_rules: { viewInAll: false, product: [], tags: [] },
  };
  const { data } = useQuery("queryArticleCategories", () =>
    graphQL(queryArticleCategories, {
      categoryid: historyProps.match.params.id,
    })
  );
  const { data: articleData } = useQuery("queryAllAssistantArticles", () =>
    graphQL(queryAllAssistantArticles)
  );
  const insertArticle = async () => {
    return await graphQL(insertAssistantArticle, {
      articleInput: defaultArticleValue,
    });
  };
  const updateCategory = async (input) => {
    await graphQL(updateAssistantCategory, {
      categoryId: { id: input.id },
      categoryInput: input.values,
    });
  };
  const deleteCategory = async (input) => {
    await graphQL(deleteAssistantCategory, {
      categoryId: input.id,
    });
  };

  const bulkArticleAddtion = async (input) => {
    await graphQL(bulkUpdateAssistantArticle, {
      idArray: input.id,
      newCategory: {
        categoryid: data.assistant_categories[0].id,
        position_rank: 999,
      },
    });
  };

  const [bulkArticleAddtionMutation] = useMutation(bulkArticleAddtion, {
    onSuccess: () => {
      toaster.notify("Updated successfully", {
        duration: 2000,
      });
      queryCache.invalidateQueries("queryArticleCategories");
      queryCache.invalidateQueries("queryAllAssistantArticles");
    },
  });

  const [insertArticleMutation] = useMutation(insertArticle, {
    onSuccess: (data) => {
      toaster.notify("Created a new article successfully", {
        duration: 2000,
      });
      historyProps.history.push(
        `/support/faq/articles/${data.insert_assistant_articles_one.id}/edit`
      );
    },
  });
  const [updateCategoryMutation] = useMutation(updateCategory, {
    onSuccess: () => queryCache.invalidateQueries("queryArticleCategories"),
  });
  const [deleteCategoryMutation] = useMutation(deleteCategory, {
    onSuccess: () => {
      toaster.notify("Category deleted", {
        duration: 2000,
      });
      historyProps.history.push(`/support/faq/categories/`);
    },
  });

  const handleBulkArticleAddtion = (idArray) => {
    bulkArticleAddtionMutation({
      id: idArray,
    });
  };

  const handleIconNameSubmision = () => {
    updateCategoryMutation({
      id:
        data && data.assistant_categories[0] && data.assistant_categories[0].id,
      values: {
        name: categoryName,
        icon_url: iconUrl,
        show_in_page: showInPage,
        show_in_widget: showInWidget,
      },
    });
  };
  const handleVisibilityChange = (value) => {
    if (value === "page") {
      updateCategoryMutation({
        id:
          data &&
          data.assistant_categories[0] &&
          data.assistant_categories[0].id,
        values: {
          show_in_page: !showInPage,
        },
      });
      setShowInPage(!showInPage);
    } else {
      updateCategoryMutation({
        id:
          data &&
          data.assistant_categories[0] &&
          data.assistant_categories[0].id,
        values: {
          show_in_widget: !showInWidget,
        },
      });
      setShowInWidget(!showInWidget);
    }
  };

  useEffect(() => {
    data &&
      data.assistant_categories[0] &&
      setIconUrl(data.assistant_categories[0].icon_url);
    data &&
      data.assistant_categories[0] &&
      setCategoryName(data.assistant_categories[0].name);
    data &&
      data.assistant_categories[0] &&
      setShowInPage(data.assistant_categories[0].show_in_page);
    data &&
      data.assistant_categories[0] &&
      setShowInWidget(data.assistant_categories[0].show_in_widget);
  }, [data]);

  return (
    <PageViewContainer
      title="Category"
      subTitle="FAQ"
      action={
        <div className="flex">
          <Button
            onClick={() => {
              setIsCategoryDeleteWarningOpen(true);
            }}
            type="danger-ghost"
            className="mr-2"
            icon={<RiDeleteBin2Line />}
          >
            <span className="mobile:hidden laptop:block">Delete Category</span>
          </Button>
          <Button
            onClick={() => {
              setIsAddArticleModalOpen(true);
            }}
            type="primary"
            icon={<RiFileAddLine />}
          >
            Add Articles
          </Button>
          <ModalTransition>
            {isCategoryDeleteWarningOpen && (
              <Modal
                actions={[
                  {
                    text: "Delete it",
                    isDisabled: data.assistant_articles.length > 0,
                    onClick: () => {
                      deleteCategoryMutation({
                        id: historyProps.match.params.id,
                      });
                      setIsCategoryDeleteWarningOpen(false);
                    },
                  },
                  {
                    text: "Cancel",
                    onClick: () => {
                      setIsCategoryDeleteWarningOpen(false);
                    },
                  },
                ]}
                onClose={() => {
                  setIsCategoryDeleteWarningOpen(false);
                }}
                heading={
                  data.assistant_articles.length === 0
                    ? "You’re about to delete this category"
                    : "You can only delete empty categories"
                }
                appearance="danger"
              >
                <p>
                  To delete a category you've to delete all the articles inside
                  it first.
                </p>
              </Modal>
            )}
            {isAddArticleModalOpen && (
              <Modal
                actions={[
                  {
                    text: "Confirm",
                    onClick: () => {
                      handleBulkArticleAddtion(selectedArticles);
                      setIsAddArticleModalOpen(false);
                    },
                  },
                  {
                    text: "Create new article",
                    onClick: () => {
                      insertArticleMutation();
                      setIsAddArticleModalOpen(false);
                    },
                  },
                  {
                    text: "Cancel",
                    onClick: () => {
                      setIsAddArticleModalOpen(false);
                    },
                  },
                ]}
                onClose={() => {
                  setIsAddArticleModalOpen(false);
                }}
                heading="Select Articles"
              >
                {articleData && (
                  <List
                    items={articleData.assistant_articles.filter(
                      (article) =>
                        article.categoryid !== data.assistant_categories[0].id
                    )}
                    selectedItemsId={selectedArticles}
                    onSelectionChange={setSelectedArticles}
                    header={
                      <div className="grid grid-cols-12 items-center justify-center ">
                        <div className="col-span-12 px-7">
                          <p className="font-bold text-gray-600">Title</p>
                        </div>
                      </div>
                    }
                    emptyState={
                      <div>
                        <div className="flex justify-center my-8">
                          <img
                            src="https://assets.cornercart.io/coca-v2/images/empty-states/slides.png"
                            alt=""
                          />
                        </div>
                        <div className="text-center">
                          <h3 className="font-bold text-blue-900 mb-3">
                            Create an FAQ article{" "}
                          </h3>
                          <p className="text-gray-800">
                            You currently have no FAQ articles. Click the the
                            button below to add one
                          </p>
                        </div>
                      </div>
                    }
                    render={(item) => (
                      <div className="grid grid-cols-12 items-center justify-center py-5">
                        <div className="col-span-12 px-7">
                          <p className="font-bold text-blue-900 hover:text-blue-600">
                            {item.title}
                          </p>
                          <p className="text-gray-700"></p>
                        </div>
                      </div>
                    )}
                  />
                )}
              </Modal>
            )}
          </ModalTransition>
        </div>
      }
    >
      {data && data.assistant_categories[0] ? (
        <>
          <div className="h-full overflow-y-auto">
            <div className="p-6 desktop:p-8 flex items-center border-b">
              <div className="flex items-center justify-between w-full">
                <div className="flex">
                  <button
                    onClick={() => {
                      setIsCategoryIconModalOpen(true);
                    }}
                    className="p-2 mx-3 bg-white border-2 rounded-md relative "
                  >
                    <img
                      className="w-9 h-9 desktop:w-12 desktop:h-12"
                      src={iconUrl}
                      alt="category"
                    />
                    <div className="absolute bottom-0 right-0 rounded-br-sm rounded-tl-lg  p-1 bg-gray-200 desktop:text-sm text-xs text-gray-700">
                      <RiEditFill />
                    </div>
                  </button>
                  <InlineEdit
                    defaultValue={categoryName}
                    editView={() => (
                      <Textfield
                        defaultValue={categoryName}
                        onChange={(e) => setCategoryName(e.target.value)}
                        name="basic"
                      />
                    )}
                    readView={() => (
                      <h2 className="p-2 desktop:p-5 text-blue-900 border-b-2 border-dotted border-gray-400 cursor-pointer text-base desktop:text-xl">
                        <b>{categoryName}</b>
                      </h2>
                    )}
                    onConfirm={(e) => handleIconNameSubmision()}
                  />
                </div>
                <div className="shrink-0">
                  <small className="text-xs font-bold text-gray-700">
                    SHOW THIS CATEGORY IN
                  </small>
                  <div className="flex">
                    <button
                      onClick={() => {
                        handleVisibilityChange("widget");
                      }}
                      className={`flex items-center border rounded-l-md text-red-600 bg-red-100   border-red-300 desktop:px-3 mobile:px-1 py-1 focus:outline-none hover:shadow-lg desktop:text-base mobile:text-sm ${
                        showInWidget &&
                        "bg-blue-100 text-blue-600 border-blue-200 "
                      }`}
                    >
                      {showInWidget ? (
                        <span className="mr-2 laptop:block mobile:hidden ">
                          <RiEyeFill />
                        </span>
                      ) : (
                        <span className="mr-2 text-red-700 laptop:block mobile:hidden">
                          <RiEyeOffFill />
                        </span>
                      )}
                      <span>Widget</span>
                    </button>
                    <button
                      onClick={() => {
                        handleVisibilityChange("page");
                      }}
                      className={`flex items-center border rounded-r-md text-red-600 bg-red-100   border-red-300 desktop:px-3 mobile:px-1 py-1 focus:outline-none hover:shadow-lg desktop:text-base mobile:text-sm ${
                        showInPage &&
                        "bg-blue-100 text-blue-600 border-blue-200"
                      }`}
                    >
                      {showInPage ? (
                        <span className="mr-2 laptop:block mobile:hidden">
                          <RiEyeFill />
                        </span>
                      ) : (
                        <span className="mr-2 text-red-700 laptop:block mobile:hidden">
                          <RiEyeOffFill />
                        </span>
                      )}
                      <span>Page</span>
                    </button>
                  </div>
                </div>
              </div>
              <ModalTransition>
                {isCategoryIconModalOpen && (
                  <Modal
                    actions={[
                      {
                        text: "Select",
                        onClick: () => {
                          handleIconNameSubmision();
                          setIsCategoryIconModalOpen(false);
                        },
                      },
                      {
                        text: "Cancel",
                        onClick: () => {
                          setIconUrl(data.assistant_categories[0].icon_url);
                          setIsCategoryIconModalOpen(false);
                        },
                      },
                    ]}
                    onClose={() => setIsCategoryIconModalOpen(false)}
                  >
                    <IconSelector
                      onIconSlect={(url) => {
                        setIconUrl(url);
                      }}
                    />
                  </Modal>
                )}
              </ModalTransition>
            </div>
            <div className="p-4 desktop:p-6">
              <ArticleList
                draggable
                data={data}
                onUpdate={() => {
                  queryCache.invalidateQueries("queryArticleCategories");
                  queryCache.invalidateQueries("queryAllAssistantArticles");
                }}
                emptyState={
                  <div>
                    <div className="flex justify-center my-8">
                      <img
                        src="https://assets.cornercart.io/coca-v2/images/empty-states/slides.png"
                        alt=""
                      />
                    </div>
                    <div className="text-center">
                      <h3 className="font-bold text-blue-900 mb-3">Add FAQ </h3>
                      <p className="text-gray-800">
                        You currently have no FAQ articles in this category.
                        Click the the button below to add one
                      </p>
                      <div className="flex justify-center mt-8">
                        <Button
                          onClick={() => {
                            setSelectedArticles(
                              data.assistant_articles.map(
                                (article) => article.id
                              )
                            );
                            setIsAddArticleModalOpen(true);
                          }}
                          type="ghost"
                          icon={<RiFileAddLine />}
                        >
                          Add New Article
                        </Button>
                      </div>
                    </div>
                  </div>
                }
              />
            </div>
          </div>
        </>
      ) : (
        <div className="h-full flex flex-col justify-center items-center">
          <div className="grow-0">
            <LoadingSpinner />
          </div>
          {/* renders an error message after 3 seconds */}
          {setTimeout(() => {
            setErrorFlag(true);
          }, 3000) &&
            errorFlag && (
              <p className="text-gray-500 text-center max-w-2xl">
                Something went wrong and we couldn't fetch this category. Please
                go back and select the category again, and if error persists
                contact us
              </p>
            )}
        </div>
      )}
    </PageViewContainer>
  );
};

export default CategoryEdit;
