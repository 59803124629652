import React from "react";
import CustomiserUnit from "../../../../../components/CustomiserUnit";
import Textfield from "@atlaskit/textfield";
import TextArea from "@atlaskit/textarea";
import { useWorkspace } from "../../../../../WorkspaceContext";
import Select from "@atlaskit/select";
import GradientPicker from "../../../../../components/GradientPicker";
import TabRadioButton from "../../../../../components/TabRadioButton";
import ColorPicker from "../../../../../components/ColorPicker";
import ThankYouPreview from "./ThankYouPreview";

const ThankyouPage = (props) => {
  const workspace = useWorkspace();
  const widgetPlacementPosition = [
    { label: "Below Total Amount", value: 1 },
    { label: "Below Store Logo", value: 2 },
    { label: "Below Thankyou Title", value: 3 },
    { label: "Below Order confirmed message", value: 4 },
    { label: "Below Customer information", value: 5 },
  ];
  const convertRGB = (rgb) => `rgb(${rgb.r},${rgb.g},${rgb.b})`;
  return (
    <div className="flex items-stretch h-full">
      <div className=" max-w-sm desktop:max-w-md overflow-y-auto py-3 desktop:px-8 px-4">
        <CustomiserUnit
          title="Minimum Order Value"
          description="Choose minimum order value to show the campaign in the thank you page"
        >
          <div className="flex items-center">
            <div className="w-20">
              <Textfield
                defaultValue={props.values.min_order_value}
                onChange={(e) => {
                  props.setFieldValue(
                    "min_order_value",
                    parseInt(e.target.value, 10)
                  );
                }}
                min={1}
                type="number"
                name="basic"
              />
            </div>
            <div className="border-2 border-gray-200 bg-gray-200 rounded-r-md p-2 font-bold">
              {workspace.data.storeCurrency}
            </div>
          </div>
        </CustomiserUnit>

        <CustomiserUnit
          title="Widget Position"
          description="Select where to show the widget in the thank you page"
        >
          <Select
            isSearchable={false}
            value={widgetPlacementPosition.find(
              (option) =>
                option.value === props.values.postpurchase_config.widgetPosition
            )}
            onChange={(e) =>
              props.setFieldValue("postpurchase_config.widgetPosition", e.value)
            }
            className="max-w-sm mr-2"
            options={widgetPlacementPosition}
          />
        </CustomiserUnit>

        <CustomiserUnit
          title="Widget Background Color"
          description="Choose the background color of the widget"
        >
          <div className="max-w-sm">
            <GradientPicker
              startColor={props.values.postpurchase_config.bgStartColor}
              endColor={props.values.postpurchase_config.bgEndColor}
              onChange={(startColor, endColor) => {
                props.setFieldValue(
                  "postpurchase_config.bgStartColor",
                  startColor
                );
                props.setFieldValue("postpurchase_config.bgEndColor", endColor);
              }}
            />
          </div>
        </CustomiserUnit>

        <CustomiserUnit
          title="Call To Action Button Color"
          description="Choose the background color of the Call to action button "
        >
          <div className="max-w-sm border rounded-lg p-3 flex items-center">
            <ColorPicker
              color={props.values.postpurchase_config.ctaColor}
              onChange={(color) => {
                props.setFieldValue(
                  "postpurchase_config.ctaColor",
                  convertRGB(color.rgb)
                );
              }}
            />
            <p className="ml-3">Choose a color</p>
          </div>
        </CustomiserUnit>

        <CustomiserUnit
          title="Widget Foreground Color"
          description="Choose the color for the text and icon on the widget"
        >
          <TabRadioButton
            defaultValue={props.values.postpurchase_config.fgColor}
            options={[
              { value: "light", label: "Light" },
              { value: "dark", label: "Dark" },
            ]}
            onChange={(e) => {
              props.setFieldValue("postpurchase_config.fgColor", e);
            }}
          />
        </CustomiserUnit>

        {props.values.postpurchase_config.popupThemeColor && (
          <CustomiserUnit
            title="Popup theme color"
            description="Choose the theme color for the popup that appears once you click the CTA button"
          >
            <div className="max-w-sm border rounded-lg p-3 flex items-center">
              <ColorPicker
                color={props.values.postpurchase_config.popupThemeColor}
                onChange={(color) => {
                  props.setFieldValue(
                    "postpurchase_config.popupThemeColor",
                    convertRGB(color.rgb)
                  );
                }}
              />
              <p className="ml-3">Choose a color</p>
            </div>
          </CustomiserUnit>
        )}

        <CustomiserUnit
          title="Custom Css"
          description="Add custom CSS to make changes to widget. Please use !important in your CSS to make sure it gets precedence over the default CSS"
        >
          <TextArea
            defaultValue={
              props.values.postpurchase_config.widgetCustomCss
                ? props.values.postpurchase_config.widgetCustomCss
                : ""
            }
            onChange={(e) => {
              props.setFieldValue("postpurchase_config.widgetCustomCss", e.target.value);
            }}
            name="basic"
            minimumRows={7}
          />
        </CustomiserUnit>
      </div>
      <div className="laptop:block mobile:hidden grow bg-gray-100 desktop:p-5 px-2 pb-5 overflow-y-auto overflow-x-auto">
        <p className="text-sm desktop:text-tiny text-gray-600 text-center my-3">
          {" "}
          Preview
        </p>
        <div className="flex justify-center">
          <div className=" origin-top scale-80 desktop:scale-100">
            <ThankYouPreview
              config={props.values.postpurchase_config}
              giftConfig={props.values.gift_config}
              rewardType={props.values.reward_type}
              rewardConfig={props.values.reward_config}
              maxDiscountValue={props.values.max_discount_value}
              minOrderValue={0}
              terms={props.values.terms_config}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThankyouPage;
