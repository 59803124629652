import React, { useState } from "react";
import Button from "./Button";
import PaywallBadge from "./PaywallBadge";
import SucoPricingModal from "./SucoPricingModal";
import Dropdown from "./Dropdown";
import { RiArrowDownSLine } from "react-icons/ri";
import Textfield from "@atlaskit/textfield";
import { useWorkspace } from "../WorkspaceContext";

{/* type Props = { */}
{/*   onIconSlect: any; */}
{/* }; */}

const IconSelector = ({ onIconSlect }) => {
  const workspace = useWorkspace();
  const [curentIconPack, setCurrentIconPack] = useState("color");
  const [isBillingModalOpen, setIsBillingModalOpen] = useState(false);

  const iconPackOptions = [
    "color",
    "outline",
    "thin-lines",
    "autumn",
    "doodle",
    "tiny-pop",
  ];

  const getIconPackProperties = (iconPackName) => {
    switch (iconPackName) {
      case "color": {
        return {
          displayName: "Basic Color",
          name: "color",
          iconCount: 51,
          authorName: "Freepik",
          showPaywall: !workspace.data?.feature_flag.apps?.supportcorner
            ?.category_icon_packs.color,
        };
        break;
      }
      case "outline": {
        return {
          displayName: "Basic Outlines",
          name: "outline",
          iconCount: 100,
          authorName: "freepik",
          showPaywall: !workspace.data?.feature_flag.apps?.supportcorner
            ?.category_icon_packs.outline,
        };
        break;
      }
      case "thin-lines": {
        return {
          displayName: "Sleek Outline",
          name: "thin-lines",
          iconCount: 68,
          authorName: "freepik",
          showPaywall: !workspace.data?.feature_flag.apps?.supportcorner
            ?.category_icon_packs.thin_lines,
        };
        break;
      }
      case "autumn": {
        return {
          displayName: "Autumn Vibes",
          name: "autumn",
          iconCount: 70,
          authorName: "freepik",
          showPaywall: !workspace.data?.feature_flag.apps?.supportcorner
            ?.category_icon_packs.autumn,
        };
        break;
      }
      case "doodle": {
        return {
          displayName: "Doodles",
          name: "doodle",
          iconCount: 44,
          authorName: "freepik",
          showPaywall: !workspace.data?.feature_flag.apps?.supportcorner
            ?.category_icon_packs.doodle,
        };
        break;
      }
      case "tiny-pop": {
        return {
          displayName: "Tiny Pop",
          name: "tiny-pop",
          iconCount: 50,
          authorName: "freepik",
          showPaywall: !workspace.data?.feature_flag.apps?.supportcorner
            ?.category_icon_packs.tiny_pop,
        };
        break;
      }
      default: {
        return {
          displayName: "Custom Icon",
          name: "custom",
          showPaywall: !workspace.data?.feature_flag.apps?.supportcorner
            ?.custom_category_images,
        };
        break;
      }
    }
  };

  const IconPack = (props) => {
    return (
      <div
        className={`border border-gray-400 rounded-lg m-3 overflow-hidden ${
          props.clickable &&
          " hover:shadow-lg hover:border-blue-600 cursor-pointer "
        } ${props.paywall && " relative overflow-visible "}`}
      >
        {props.paywall && <PaywallBadge absolute type="lite"/>}
        <div className={`flex flex-wrap w-${props.size} p-1`}>
          {Array(4)
            .fill("")
            .map((_, i) => {
              const image = `https://assets.cornercart.io/coca-v2/images/category-icons/${
                props.iconProps.name
              }/${i + 1}.svg`;
              return (
                <div key={i} className="w-1/2 p-1">
                  <img alt="thumbnail" src={image} width="100%" />
                </div>
              );
            })}
        </div>
        {props.showTitle && (
          <p className="text-center text-xs bg-blue-100 text-blue-600 rounded-b-lg">
            {props.iconProps.displayName}
          </p>
        )}
      </div>
    );
  };

  return (
    <>
      <div className="flex justify-between items-center my-4 border-b pb-8">
        <div className="flex  items-center">
          {curentIconPack !== "custom" && (
            <IconPack
              iconProps={getIconPackProperties(curentIconPack)}
              size={20}
            />
          )}
          <div>
            <small>Icon Pack</small>
            <h2 className="font-bold">
              {getIconPackProperties(curentIconPack)?.displayName}
            </h2>
          </div>
        </div>

        <Dropdown
          alignment="right"
          type="link"
          icon={<RiArrowDownSLine />}
          triggerText="Change Icon Pack"
        >
          <div className="flex flex-wrap justify-center">
            {iconPackOptions.map((item) => {
              return (
                <button
                  onClick={() => {
                    setCurrentIconPack(item);
                  }}
                >
                  <IconPack
                    iconProps={getIconPackProperties(item)}
                    size={24}
                    clickable
                    showTitle
                    paywall={getIconPackProperties(item).showPaywall}
                  />
                </button>
              );
            })}
          </div>
          <div className="flex items-center justify-center">
            <small className="text-gray-600">or</small>
          </div>
          <div className=" flex items-center justify-center">
            <Button
              onClick={() => {
                if (getIconPackProperties("custom").showPaywall)
                  setIsBillingModalOpen(true);
                else setCurrentIconPack("custom");
              }}
              type="link"
            >
              <div className="flex items-center">
                Upload Custom Icon
                {getIconPackProperties("custom").showPaywall && (
                  <PaywallBadge />
                )}
              </div>
            </Button>
          </div>
        </Dropdown>
      </div>
      <div>
        <h2 className="font-bold text-center mb-4">
          {" "}
          {curentIconPack === "custom" ? "Custom Icon" : "Select An Icon"}
        </h2>
      </div>
      {curentIconPack === "custom" ? (
        <div className="h-96">
          <p>
            Insert a image URL of the prefered icon below. Uploading an image in
            <b> Files</b> section in shopify's setting will give you an Image
            URL. Paste it here in the text box below.
          </p>
          <div className="mt-3">
            <Textfield
              name="basic"
              placeholder="Paste Image URL here"
              onChange={(e) => {
                if (e.target.value.lenth !== 0) onIconSlect(e.target.value);
              }}
            />
          </div>
        </div>
      ) : (
        <div className="flex flex-wrap">
          {Array(getIconPackProperties(curentIconPack)?.iconCount)
            .fill("")
            .map((_, i) => {
              const image = `https://assets.cornercart.io/coca-v2/images/category-icons/${
                getIconPackProperties(curentIconPack)?.name
              }/${i + 1}.svg`;
              return (
                <button
                  key={i}
                  onClick={() => {
                    if (getIconPackProperties(curentIconPack).showPaywall)
                      setIsBillingModalOpen(true);
                    else onIconSlect(image);
                  }}
                  className=" w-24 p-3 m-3 bg-white border-2 rounded-md relative overflow-visible"
                >
                  {getIconPackProperties(curentIconPack).showPaywall && (
                    <PaywallBadge absolute type="lite" />
                  )}
                  <img alt="thumbnail" src={image} width="100%" />
                </button>
              );
            })}
        </div>
      )}
      <SucoPricingModal
        title="You need a higher plan to access this feature"
        isOpen={isBillingModalOpen}
        onClose={() => setIsBillingModalOpen(false)}
      />
      {/* <small className="text-center">
    Icons made by{" "}
    <a
      href="https://www.flaticon.com/authors/freepik"
      title="Freepik"
    >
      Freepik
    </a>{" "}
    from{" "}
    <a
      href="https://www.flaticon.com/"
      title="Flaticon"
    >
      www.flaticon.com
    </a>
  </small> */}
    </>
  );
};

export default IconSelector;
