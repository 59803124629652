import React, { useState, useEffect } from "react";
import { useQuery, useMutation, useQueryCache } from "react-query";
import Select from "@atlaskit/select";
import CustomiserUnit from "../../../../components/CustomiserUnit";
import Button from "../../../../components/Button";
import Dropdown from "../../../../components/Dropdown";
import { useGraphQl } from "../../../../GraphqlClient";
import { useWorkspace } from "../../../../WorkspaceContext";
import { RiCloseFill, RiAddLine, RiAlertFill } from "react-icons/ri";
import { updateSourceDetails } from "../../../../api/workspace";
import {
  queryAllLangObjects,
  updateWidgetSettings,
  updatCornerStickybarWidget,
} from "../../../../api/widgets";
import toaster from "toasted-notes";

import { langOptions } from "../../../../utilities/variables";

export default function General(props) {
  const graphQL = useGraphQl();
  const workspace = useWorkspace();
  const queryCache = useQueryCache();

  const [currentLanguages, setCurrentLanguages] = useState([]);
  const [defaultLanguage, setDefaultLanguage] = useState(
    workspace.data.defaultLanguage
  );

  const updateTranslatedLanguageArray = async (input) => {
    await graphQL(updateSourceDetails, {
      sourceId: { id: workspace.data.sourceid },
      sourceInput: {
        translated_languages: input,
      },
    });
  };

  const [updateTranslatedLanguageArrayMutation] = useMutation(
    updateTranslatedLanguageArray,
    {
      onSuccess: () => {},
    }
  );

  const updateDefaultLanguage = async (input) => {
    await graphQL(updateSourceDetails, {
      sourceId: { id: workspace.data.sourceid },
      sourceInput: {
        default_language: input,
      },
    });
  };

  const [updateDefaultLanguageMutation] = useMutation(updateDefaultLanguage, {
    onSuccess: () => {
      queryCache.invalidateQueries("queryWidgetSettings");
      queryCache.invalidateQueries("queryCornerConvertWidgets");
      refetch();
      toaster.notify("Default language updated", {
        duration: 2000,
      });
    },
  });

  const { data, refetch } = useQuery("queryAllLangObjects", () =>
    graphQL(queryAllLangObjects)
  );

  const updateCornerWidget = async (values) => {
    await graphQL(updateWidgetSettings, {
      widgetSettingsId: values.id,
      widgetSettingsInput: values.config,
    });
  };

  const [updateCornerWidgetMutation] = useMutation(updateCornerWidget, {
    onSuccess: () => {
      toaster.notify("Language updated", {
        duration: 5000,
      });
      queryCache.invalidateQueries("queryAllLangObjects");
      initValues();
    },
  });

  const updateStickyBarWidget = async (values) => {
    await graphQL(updatCornerStickybarWidget, {
      stickyBarId: values.id,
      stickyBarInput: values.config,
    });
  };

  const [updatCornerStickybarMutation] = useMutation(updateStickyBarWidget, {
    onSuccess: () => {
      queryCache.invalidateQueries("queryAllLangObjects");
      initValues();
    },
    onError: () => {},
  });

  const handleLanguageDeletion = (lang) => {
    if (lang !== defaultLanguage) {
      let tempFaqLang = data.widgets_cornerwidget[0].faq_lang;
      let tempCornerLang = data.widgets_cornerwidget[0].corner_lang;
      let tempOfferWidgetLang = data.widgets_cornerwidget[0].offer_widget_lang;
      let tempOfferPageLang = data.widgets_cornerwidget[0].offer_page_lang;
      let tempCartCowiLang = data.widgets_cornerwidget[0].corner_cart_lang;

      delete tempCornerLang[lang];
      delete tempFaqLang[lang];
      delete tempOfferWidgetLang[lang];
      delete tempOfferPageLang[lang];
      delete tempCartCowiLang[lang];

      // the following condition makes sure there is a current lang array even if the source.translated_languages is null
      let currentLangArray = data.workspaces[0].source.translated_languages
        ? data.workspaces[0].source.translated_languages
        : [defaultLanguage];
      if (currentLangArray.includes(lang)) {
        let deleteItemIndex = currentLangArray.indexOf(lang);
        if (deleteItemIndex > -1) {
          currentLangArray.splice(deleteItemIndex, 1);
          updateTranslatedLanguageArrayMutation(currentLangArray);
        }
      }

      updateCornerWidgetMutation({
        id: { id: data && data.widgets_cornerwidget[0].id },
        config: {
          faq_lang: tempFaqLang,
          corner_lang: tempCornerLang,
          offer_widget_lang: tempOfferWidgetLang,
          offer_page_lang: tempOfferPageLang,
          corner_cart_lang: tempCartCowiLang,
        },
      });
      if (workspace.data.installedApps.find((item) => item.appid === 3)) {
        let tempStickyLang = data.widget_stickybar[0].sticky_atc_lang;
        delete tempStickyLang[lang];
        updatCornerStickybarMutation({
          id: { id: data && data.widget_stickybar[0].id },
          config: {
            sticky_atc_lang: tempStickyLang,
          },
        });
      }
    } else
      toaster.notify(
        "Cannot delete this translation since it is your default language",
        {
          duration: 5000,
        }
      );
  };

  const handleLanguageAddition = async (langCode) => {
    let tempFaqLang = data.widgets_cornerwidget[0].faq_lang;
    let tempCornerLang = data.widgets_cornerwidget[0].corner_lang;
    let tempOfferWidgetLang = data.widgets_cornerwidget[0].offer_widget_lang;
    let tempOfferPageLang = data.widgets_cornerwidget[0].offer_page_lang;
    let tempCartCowiLang = data.widgets_cornerwidget[0].corner_cart_lang;

    let cornerLangToBeAdded =
      await require(`../../../../i18n/default-values/corner-widget/${langCode}.json`);
    let faqLangToBeAdded =
      await require(`../../../../i18n/default-values/faq-page/${langCode}.json`);
    let offerWidgetLangToBeAdded =
      await require(`../../../../i18n/default-values/offer-widget/${langCode}.json`);
    let offerPageLangToBeAdded =
      await require(`../../../../i18n/default-values/offer-page/${langCode}.json`);
    let cartCowiLangToBeAdded =
      await require(`../../../../i18n/default-values/cart-cowi/${langCode}.json`);

    // this syntax of [`${...}`] is used for defining dynamic keys with object literals
    let newFaqLang = { ...tempFaqLang, [`${langCode}`]: faqLangToBeAdded };
    let newCornerLang = {
      ...tempCornerLang,
      [`${langCode}`]: cornerLangToBeAdded,
    };
    let newOfferWidgetLang = {
      ...tempOfferWidgetLang,
      [`${langCode}`]: offerWidgetLangToBeAdded,
    };
    let newOfferPageLang = {
      ...tempOfferPageLang,
      [`${langCode}`]: offerPageLangToBeAdded,
    };
    let newCartCowiLang = {
      ...tempCartCowiLang,
      [`${langCode}`]: cartCowiLangToBeAdded,
    };

    // the following condition makes sure there is a current lang array even if the source.translated_languages is null
    let currentLangArray = data.workspaces[0].source.translated_languages
      ? data.workspaces[0].source.translated_languages
      : [defaultLanguage];
    if (!currentLangArray.includes(langCode)) {
      let updatedLangArray = [...currentLangArray, langCode];
      updateTranslatedLanguageArrayMutation(updatedLangArray);
    }

    updateCornerWidgetMutation({
      id: { id: data && data.widgets_cornerwidget[0].id },
      config: {
        corner_lang: newCornerLang,
        faq_lang: newFaqLang,
        offer_widget_lang: newOfferWidgetLang,
        offer_page_lang: newOfferPageLang,
        corner_cart_lang: newCartCowiLang,
      },
    });

    if (workspace.data.installedApps.find((item) => item.appid === 3)) {
      let tempStickyBarLang = data.widget_stickybar[0].sticky_atc_lang;
      let stickyBarLangToBeAdded =
        await require(`../../../../i18n/default-values/stickybar/${langCode}.json`);
      let newStickyBarLang = {
        ...tempStickyBarLang,
        [`${langCode}`]: stickyBarLangToBeAdded,
      };
      updatCornerStickybarMutation({
        id: { id: data && data.widget_stickybar[0].id },
        config: {
          sticky_atc_lang: newStickyBarLang,
        },
      });
    }
  };

  const handleDefaultLanguageChange = (selectedLang) => {
    // the following code runs when the selected languge is not already in the languge list
    if (!currentLanguages.find((lang) => lang === selectedLang)) {
      handleLanguageAddition(selectedLang);
    }
    updateDefaultLanguageMutation(selectedLang);
  };

  useEffect(() => {
    if (data) {
      setDefaultLanguage(data.workspaces[0].source.default_language);
      setCurrentLanguages(data.workspaces[0].source.translated_languages);
    }
  }, [data]);

  return (
    <div className="px-8 py-4  h-full overflow-y-auto">
      <CustomiserUnit
        title="Select your default language"
        description="This language will be the default language the app uses to initialise the widgets"
      >
        {data && (
          <Select
            isSearchable={false}
            value={langOptions.find(
              (option) => option.value === defaultLanguage
            )}
            onChange={(e) => {
              handleDefaultLanguageChange(e.value);
              // updateDefaultLanguageMutation(e.value);
            }}
            className="w-48 mr-2"
            classNamePrefix="react-select"
            options={langOptions}
          />
        )}
      </CustomiserUnit>
    {
      currentLanguages &&
      <CustomiserUnit
        title="Translated Languages"
        description="Everything rendered by this app in your store will be translated to the following languages. "
      >
        <div className="flex">
          {currentLanguages.map((item) => {
            if (item === "version") return null;
            else {
              let displayItem = langOptions.find((lang) => lang.value === item);
              return (
                <div
                  className={`flex items-center border rounded-lg mr-2 pl-2 font-semibold bg-gray-100`}
                >
                  {" "}
                  {displayItem.label}{" "}
                  {displayItem.value === defaultLanguage ? (
                    <div className="text-[10px] p-1 font-light rounded">
                      (Default Language)
                    </div>
                  ) : (
                    <Button
                      type="danger-link"
                      onClick={() => handleLanguageDeletion(displayItem.value)}
                    >
                      <RiCloseFill />
                    </Button>
                  )}
                </div>
              );
            }
          })}

          <Dropdown
            alignment="right"
            type="outline"
            icon={<RiAddLine />}
            triggerText="Add Language"
          >
            <div className="flex flex-wrap max-w-xs">
              {langOptions.map((item) => {
                if (!currentLanguages.includes(item.value))
                  return (
                    <Button
                      onClick={() => handleLanguageAddition(item.value)}
                      type="ghost"
                      className="m-1"
                    >
                      {item.label}
                    </Button>
                  );
              })}
            </div>
          </Dropdown>
        </div>
        {workspace.data.installedApps.find((item) => item.appid === 3) &&
          !workspace.data?.feature_flag.apps?.cornercart
            ?.multi_language_support &&
          currentLanguages.length > 1 && (
            <div className="bg-amber-100 p-3 mt-3 text-amber-700 rounded-lg flex items-center">
              <RiAlertFill className="mr-3" />
              <span>
                You need a higher plan in CornerCart to enable multi language
                support
              </span>
            </div>
          )}
      </CustomiserUnit>
    }
    </div>
  );
}
