import React, { useState } from "react";
import TakeOverDrawer from "../../../../../components/TakeOverDrawer";
import CustomiserUnit from "../../../../../components/CustomiserUnit";
import TabRadioButton from "../../../../../components/TabRadioButton";
import CocaPricingModal from "../../../../../components/CocaPricingModal";
import PaywallBadge from "../../../../../components/PaywallBadge";
import Button from "../../../../../components/Button";
import WidgetPreview from "../../WidgetPreview";
import { cornerWidgetThemes } from "../../../../../utilities/variables";
import { useWorkspace } from "../../../../../WorkspaceContext";
import {
  RiPaintBrushFill,
  RiCheckboxCircleFill,
  RiMoonClearFill,
  RiSunFill,
  RiVipCrown2Fill,
} from "react-icons/ri";

/**
 * Customise the color palette of the widget
 * @component
 * @param {Object} values - values required to render the initial preview
 */
export default function ThemeSelector({ values, setFieldValue }) {
  const workspace = useWorkspace();
  const [isThemeSelectorVisible, setIsThemeSelectorVisible] = useState(false);
  const [isBillingModalOpen, setIsBillingModalOpen] = useState(false);
  const [selectedThemeStyle, setSelectedThemeStyle] = useState("all");
  const [selectedThemeIndex, setSelectedThemeIndex] = useState(0);
  const currentCartConfig =
    selectedThemeIndex === -1
      ? values.cart_config
      : {
          ...values.cart_config,
          colors: {
            ...cornerWidgetThemes[selectedThemeIndex].themeObject.colors,
          },
          banner: {
            ...cornerWidgetThemes[selectedThemeIndex].themeObject.banner,
          },
          general: {
            ...values.cart_config.general,
            customCss:
              cornerWidgetThemes[selectedThemeIndex].themeObject.customCss,
          },
        };

  const themeStyle = [
    { label: "All", value: "all" },
    {
      label: (
        <div className="flex items-center">
          <RiSunFill size="1.2em" />
          <span className="ml-2">Light Themes</span>
        </div>
      ),
      value: "light",
    },
    {
      label: (
        <div className="flex items-center">
          <RiMoonClearFill size="1.2em" />
          <span className="ml-2">Dark Themes</span>
        </div>
      ),
      value: "dark",
    },
  ];

  const defaultTheme = {
    name: "Default Free Theme",
    type: "light",
    themeObject: {
      colors: values.cart_config.colors,
      banner: values.cart_config.banner,
      customCss: "",
    },
  };

  const ThemeCard = ({
    isSelected,
    name,
    type,
    themeIndex,
    themeObject,
    pro,
  }) => {
    if (selectedThemeStyle === "all" || selectedThemeStyle === type)
      return (
        <button
          onClick={() => {
            setSelectedThemeIndex(themeIndex);
          }}
          className={`flex items-center justify-between w-full ${
            isSelected ? "text-primary-500 border-primary-500" : "text-black"
          }  border rounded-lg mt-3 p-3 hover:shadow-lg hover:text-blue-900 cursor-pointer`}
        >
          <div className="flex items-center text-base">
            <div
              className={`p-3 mr-4 rounded-full ${
                isSelected ? "bg-primary-100" : "bg-gray-200"
              }`}
            >
              {type === "dark" ? (
                <RiMoonClearFill size="1.6em" />
              ) : (
                <RiSunFill size="1.6em" />
              )}
            </div>
            <div>
              <p className="font-bold text-left ">{name}</p>
              <p className="text-gray-600 text-sm flex items-center">
                {type === "dark" ? "Dark Theme" : "Light Theme"}
              </p>
            </div>
          </div>

          <div className="flex rounded-md shadow-lg overflow-hidden mx-4">
            <div
              style={{ background: themeObject.colors.accentBg }}
              className="flex justify-center items-center w-12 h-12"
            >
              <div
                style={{ color: themeObject.colors.accentFg }}
                className="font-bold "
              >
                A
              </div>
            </div>
            <div>
              <div className="flex">
                <div
                  style={{ background: themeObject.colors.primaryType }}
                  className="w-6 h-6"
                />
                <div
                  style={{ background: themeObject.colors.secondaryType }}
                  className="w-6 h-6"
                />
              </div>
              <div
                style={{ background: themeObject.colors.widgetBg }}
                className="w-full h-full flex-grow"
              />
            </div>
          </div>
        </button>
      );
    else return null;
  };

  return (
    <>
      {/* Theme Picker */}
      <CustomiserUnit
        title={<span className="flex">Theme Picker </span>}
        description="Select one theme from our library to get started. "
      >
        <Button
          onClick={() => {
            setIsThemeSelectorVisible(!isThemeSelectorVisible);
          }}
          type="ghost"
          className="mt-3"
          icon={<RiPaintBrushFill />}
        >
          Select a theme
        </Button>
      </CustomiserUnit>
      <TakeOverDrawer
        title={
          <div className="flex items-center">
            <RiPaintBrushFill />
            <span className="ml-3 flex">
              Select a Theme
              {!workspace.data?.feature_flag.apps?.cornercart?.cornerwidget
                ?.cornerwidget_advanced_color_customisation && (
                <PaywallBadge type="pro" />
              )}
            </span>
          </div>
        }
        actions={
          <div className="flex items-center">
            <Button
              onClick={() => {
                setIsThemeSelectorVisible(!isThemeSelectorVisible);
              }}
              className="mr-2"
              type="ghost"
            >
              Cancel
            </Button>

            <Button
              onClick={() => {
                if (
                  workspace.data?.feature_flag.apps?.cornercart?.cornerwidget
                    ?.cornerwidget_advanced_color_customisation ||
                  selectedThemeIndex === -1
                ) {
                  setFieldValue("cart_config", currentCartConfig);
                  setIsThemeSelectorVisible(!isThemeSelectorVisible);
                } else {
                  setIsBillingModalOpen(true);
                }
              }}
              icon={<RiCheckboxCircleFill />}
            >
              {workspace.data?.feature_flag.apps?.cornercart?.cornerwidget
                ?.cornerwidget_advanced_color_customisation ||
              selectedThemeIndex === -1
                ? " Apply & Continue Editing"
                : "Upgrade to unlock Pro themes"}
            </Button>
          </div>
        }
        isOpen={isThemeSelectorVisible}
        onClose={() => {
          setIsThemeSelectorVisible(!isThemeSelectorVisible);
        }}
      >
        <div className="h-full flex flex-row items-stretch min-h-0">
          <div className="desktop:max-w-xl laptop:max-w-md mobile:w-full px-8 overflow-y-auto pb-12">
            <div className="my-4">
              {/* <PaywallElement type="horizontal" paywallPlan={13} title="Upgrade Now" description="Subscribe to a higher plan to edit various texts inside the widget" /> */}
              Select a theme from the following list. You could customise it
              even further once you have selected a theme
            </div>
            <div className="flex justify-center">
              <TabRadioButton
                defaultValue={selectedThemeStyle}
                options={themeStyle}
                onChange={(selectedType) => {
                  setSelectedThemeStyle(selectedType);
                }}
              />
            </div>
            <div className="mt-8">
              {!workspace.data?.feature_flag.apps?.cornercart?.cornerwidget
                ?.cornerwidget_advanced_color_customisation && (
                <>
                  <ThemeCard
                    name={defaultTheme.name}
                    type={defaultTheme.type}
                    themeObject={defaultTheme.themeObject}
                    isSelected={-1 === selectedThemeIndex}
                    themeIndex={-1}
                  />
                  <div className="text-center my-5">Pro Themes</div>
                </>
              )}

              {cornerWidgetThemes.map((themeItem, index) => (
                <ThemeCard
                  name={themeItem.name}
                  type={themeItem.type}
                  themeObject={themeItem.themeObject}
                  isSelected={index === selectedThemeIndex}
                  themeIndex={index}
                  pro
                />
              ))}
            </div>
          </div>
          <div className="grow flex-wrap h-full bg-gray-100 border-l mobile:hidden laptop:flex items-center justify-center overflow-y-auto">
            <div className=" origin-top scale-80 desktop:scale-100">
              {isThemeSelectorVisible && (
                <WidgetPreview
                  cartConfig={
                    selectedThemeIndex === -1
                      ? values.cart_config
                      : currentCartConfig
                  }
                  cartLauncherConfig={values.cart_launcher_config}
                  cornerCartWidgetLang={values.corner_cart_lang}
                  noLimitPreview
                />
              )}
            </div>
          </div>
        </div>
      </TakeOverDrawer>
      <CocaPricingModal
        hightlightPlan={13}
        title={`Upgrade to a higher plan to unlock Pro themes`}
        isOpen={isBillingModalOpen}
        onClose={() => setIsBillingModalOpen(false)}
      />
    </>
  );
}
