import React from "react";
import { RiCloseLine } from "react-icons/ri";
import Drawer from "@atlaskit/drawer";
import CategoryNavButtons from "./CategoryNavButtons";
import SectionNav from "./SectionNav";


const MobileSideBar = ({ isVisible, onClose }) => {
  const contentOverrideCssFn = () => ({
    marginTop: 0,
    width: "100%",
  });

  const handleClose = () => {
    onClose()
  }

const ContextNavbar = () => {
  return (
    <div className="flex flex-col items-center border-r py-4 px-4 z-30">
      <button
        className="text-blue-600 laptop:hidden mobile:mobile text-3xl focus:outline-none focus:shadow-none py-4"
        onClick={() => handleClose()}
      >
        <RiCloseLine />
      </button>
      <CategoryNavButtons />
    </div>
  );
};
  
  return (
    <div className="laptop:hidden mobile:block">
      {/* For future refernce: Legacy context API warnign in the console is thrown because of this atlaskit drawer component  */}
      <Drawer
        onClose={() => handleClose()}
        isOpen={isVisible}
        width="narrow"
        overrides={{
          Sidebar: {
            component: ContextNavbar,
          },
          Content: {
            cssFn: contentOverrideCssFn,
          },
        }}
      >
        <div className="flex flex-col bg-gradient-to-t from-blue-100 to-white border-r h-screen">
          <SectionNav />
        </div>
      </Drawer>
    </div>
  );
};

export default MobileSideBar;
