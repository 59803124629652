import React, { useState } from "react";
import Button from "./Button";
import SucoPricingModal from "./SucoPricingModal";
import OfcoPricingModal from "./OfcoPricingModal";
import CocaPricingModal from "./CocaPricingModal";
import { useWorkspace } from "../WorkspaceContext";
import { RiVipCrown2Fill } from "react-icons/ri";

/**
 * An UI element designed to show settings elements.
 * @component
 * @param {String} title - The title
 * @param {String} description - description
 * @param {Node} children - contents of the customiser unitc
 * @param {Boolean} disabled - Whether this particluar customiser unit is accesible or not
 * @param {Boolean} paywall - whether to show paywall or not
 * @param {Number} paywallPlan - Which plan to show on the paywall
 */
const CustomiserUnit = ({
  title,
  description,
  children,
  disabled,
  paywall,
  paywallPlan,
}) => {
  const workspace = useWorkspace();
  const [isBillingModalOpen, setIsBillingModalOpen] = useState(false);
  const availablePlans = workspace.data.allAvailablePlans;
  const requiredPlan =
    paywallPlan &&
    availablePlans &&
    availablePlans.find((plan) => plan.id === paywallPlan);

  const formatPrice = (price) => {
    var priceAsString = price.toString();
    var splitPrice = priceAsString.split(".");
    return (
      <div className="flex  leading-none">
        <span className="text-lg self-center">$</span>
        <span className="text-3xl m-0 font-extrabold self-center">
          {splitPrice[0]}
        </span>
        <span className="text-lg self-end text-sm">
          {splitPrice[1] && `.${splitPrice[1]}`}
        </span>
      </div>
    );
  };

  const renderPricingModal = () => {
    switch (requiredPlan.appid) {
      case 1:
        return (
          <SucoPricingModal
            hightlightPlan={paywallPlan}
            title={`Upgrade to a higher plan to unlock this feature`}
            isOpen={isBillingModalOpen}
            onClose={() => setIsBillingModalOpen(false)}
          />
        );
      case 2:
        return <OfcoPricingModal />;
      case 3:
        return (
          <CocaPricingModal
            hightlightPlan={paywallPlan}
            title={`Upgrade to a higher plan to unlock this feature`}
            isOpen={isBillingModalOpen}
            onClose={() => setIsBillingModalOpen(false)}
          />
        );
    }
  };
  return (
    <div
      className={`py-3 desktop:py-5 ${
        disabled && "opacity-25 pointer-events-none cursor-not-allowed"
      }  max-w-4xl`}
    >
      <div>
        <div className="flex items-center">
          <p className="font-bold text-tiny desktop:text-base">{title}</p>
          {paywall && (
            <p className="font-bold text-xs bg-primary-500 text-white rounded-full px-3 ml-2 flex items-center ">
              <span className="mr-2">
                <RiVipCrown2Fill />
              </span>
              <span>Pro</span>
            </p>
          )}
        </div>
        {description && (
          <small className=" text-gray-700 mt-1 text-sm desktop:text-tiny">
            {description}
          </small>
        )}
      </div>
      {paywall ? (
        <div className=" shadow-sm border my-1 desktop:my-3 rounded-md  flex items-center overflow-hidden">
          <div className=" mr-3 text-center self-stretch text-primary-500 p-4 bg-primary-100  ">
            {requiredPlan && formatPrice(requiredPlan.billing_amount)}
            <div className="text-xs m-0">/month</div>
          </div>
          <div className="grow px-4 py-2">
            <p className=" desktop:text-base text-sm  mb-1 text-gray-700">
              This feature is only available in a higher plan
            </p>
            <a
              onClick={() => setIsBillingModalOpen(true)}
              className="font-bold text-blue-600 desktop:text-base text-sm  cursor-pointer transition-all px-0 hover:bg-blue-100 hover:px-2 py-1 rounded-md "
            >
              Upgrade Now
            </a>
          </div>
          {/* <div >
            <Button className="text-sm" type="link" onClick={() => setIsBillingModalOpen(true)}>
              Upgrade
            </Button>
          </div> */}
        </div>
      ) : (
        <div className="mt-3">
          {disabled ? (
            <div className="bg-gray-200 w-64 h-12 rounded-md " />
          ) : (
            <div>{children}</div>
          )}
        </div>
      )}
      {paywallPlan && renderPricingModal()}
    </div>
  );
};

export default CustomiserUnit;
