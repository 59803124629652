import React, { useState, useEffect } from "react";
import {
  RiSmartphoneLine,
  RiSmartphoneFill,
  RiComputerFill,
  RiComputerLine,
  RiRefreshFill,
  RiInformationLine,
} from "react-icons/ri";

import { useWorkspace } from "../../../WorkspaceContext";
import LoadingSpinner from "../../../components/LoadingSpinner";

/**
 * A preview window for showcasing widgets. The config is passed to the iframe messages. Inside the each widget there is a event lisner listening to these messages. And the config is updated when ever a mesage  is triggered from here
 * @component
 * @param {Object} cartConfig - The cart Config object . If left blank, the values will be fetched from the db
 * @param {Object} cartLauncherConfig - The cart launcher config. If left blank, the values will be fetched from the db
 * @param {Object} stickybarConfig - The sticky bar config. If left blank, the values will be fetched from the db
 * @param {Object} cornerCartWidgetLang - cart widget lang config. If left blank, the values will be fetched from the db
 * @param {Object} stickyAtcWidgetLang - sticky atc widget lang. If left blank, the values will be fetched from the db
 * @param {String} infoMessage - What mesage should be shown as closable info message
 * @param {String} previewLang - the languge which is to be showed inside the previe. If left blank, the values will be default language
 * @param {boolean} noLimitPreview - if this value is true every feature will be visible in preview
 */
const WidgetPreview = ({
  cartConfig,
  cartLauncherConfig,
  stickybarConfig,
  cornerCartWidgetLang,
  stickyAtcWidgetLang,
  previewLang,
  infoMessage,
  noLimitPreview,
}) => {
  const workspace = useWorkspace();
  const [previewMode, setPreviewMode] = useState("desk");
  const [basicConfig, setBasicConfig] = useState();
  const [isMessageVisible, setIsMessageVisible] = useState(true);

  const defaultFeatureflag = {
    remove_corner_branding: true,
    multi_language_support: true,
    dev_options: true,
    fb_pixel: true,
    ga_pixel: true,
    stickybar: {
      stickybar_custom_css: true,
      stickybar_themes: true,
      stickybar_on_any_page: true,
      stickybar_mobile_specific_customisations: true,
    },
    cornerwidget: {
      cornerwidget_custom_css: true,
      cornerwidget_advanced_color_customisation: true,
      cornerwidget_banner_image: true,
      cornerwidget_related_products: true,
      cornerwidget_optional_note: true,
      cornerwidget_custom_attributes: true,
    },
  };
  useEffect(() => {
    const url = `${process.env.REACT_APP_STARLINK_URL}/corner/config/${workspace.data.storeMyShopifyUrl}/conf.json`;

    const fetchData = async () => {
      try {
        const response = await fetch(url);
        const json = await response.json();
        setBasicConfig(json.json_build_object);
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchData();
  }, []);

  let previewConfig = basicConfig && {
    ...basicConfig,
    general_config: {
      ...basicConfig.general_config,
      currencyFormat: "<span class=money>${{amount}} USD</span>",
      featureFlag: {
        apps: {
          cornercart: noLimitPreview
            ? defaultFeatureflag
            : {
                ...defaultFeatureflag,
                ...basicConfig.general_config.featureFlag.apps.cornercart,
              },
        },
      },
    },

    cornercart: {
      cartLauncherConfig: cartLauncherConfig
        ? { ...cartLauncherConfig }
        : { ...basicConfig.cornercart.cartLauncherConfig },
      cartConfig: cartConfig
        ? { ...cartConfig }
        : { ...basicConfig.cornercart.cartConfig },
      stickybarConfig: stickybarConfig
        ? { ...stickybarConfig }
        : basicConfig.cornercart.stickybarConfig,
      langConfig: {
        cornerCartWidget: cornerCartWidgetLang
          ? { version: 1, ...cornerCartWidgetLang }
          : basicConfig.cornercart.langConfig.cornerCartWidget,
        stickyAtcWidget: stickyAtcWidgetLang
          ? { version: 1, ...stickyAtcWidgetLang }
          : basicConfig.cornercart.langConfig.stickyAtcWidget,
      },
    },
  };

  const triggerPreviewConfigChange = () => {
    if (document.getElementById(`preview-iframe`)) {
      document
        .getElementById(`preview-iframe`)
        .contentWindow.postMessage(JSON.stringify(previewConfig), "*");
    }
  };

  useEffect(() => {
    triggerPreviewConfigChange();
  }, [
    cartConfig,
    cartLauncherConfig,
    stickybarConfig,
    cornerCartWidgetLang,
    stickyAtcWidgetLang,
  ]);

  if (basicConfig) {
    return (
      <div
        style={{
          height: "652px",
          width: previewMode === "desk" ? "713px" : "350px",
        }}
        className=" bg-white shadow-lg rounded-lg overflow-hidden flex flex-col transition-all "
      >
        <div className="w-full h-8 bg-blue-100 flex pt-1">
          <div className="flex h-full items-center mb-2">
            <div className="w-2 h-2 mr-2 ml-2 rounded-full bg-red-500" />
            <div className="w-2 h-2 mr-2 rounded-full bg-amber-400" />
            <div className="w-2 h-2 mr-2 rounded-full bg-green-400" />
          </div>
          <div className="flex items-end ml-3">
            <div className="bg-white w-40 h-4/5 rounded-t-md" />
          </div>
        </div>

        <div className="flex border-b p-3 shadow-sm ">
          <button
            onClick={() => setPreviewMode("desk")}
            className={`flex items-center border rounded-l-full py-1 pl-2 pr-2 focus:outline-none ${
              previewMode === "desk" && "border-blue-600 text-blue-600"
            }`}
          >
            {previewMode === "desk" ? <RiComputerFill /> : <RiComputerLine />}
            <span className="ml-1 text-xs">Desktop</span>
          </button>
          <button
            onClick={() => setPreviewMode("mob")}
            className={`flex items-center border rounded-r-full py-1 pr-2 pl-2 focus:outline-none ${
              previewMode === "mob" && "border-blue-600 text-blue-600 "
            }`}
          >
            {previewMode === "mob" ? (
              <RiSmartphoneFill />
            ) : (
              <RiSmartphoneLine />
            )}
            <span className="ml-1 text-xs">Mobile</span>
          </button>
          <div className="grow py-1 px-4 mx-3 font-bold rounded-full border text-xs">
            Preview
          </div>
          <button
            onClick={triggerPreviewConfigChange}
            className="border rounded-full px-3 text-gray-500 hover:border-blue-400 hover:text-blue-400 flex items-center focus:outline-none"
          >
            <span className="text-md">
              <RiRefreshFill />{" "}
            </span>
            {previewMode === "desk" && (
              <span className="ml-2 font-bold text-xs ">Reload Preview</span>
            )}
          </button>
        </div>
        <div id="iframe-wrapper" className="flex flex-col w-full relative">
          {infoMessage && (
            <div className="absolute top-0 right-0 z-10 ">
              {isMessageVisible ? (
                <div className="bg-white mx-3 my-2  px-3 py-2 rounded-lg shadow-lg text-xs m-4">
                  <div className="flex ">
                    <span>{infoMessage}</span>
                  </div>
                  <div className="flex justify-end mt-2">
                    <button
                      className="border border-blue-600 px-1 text-primary-500 font-bold rounded-md text-xs"
                      onClick={() => {
                        setIsMessageVisible(!isMessageVisible);
                      }}
                    >
                      OK
                    </button>
                  </div>
                </div>
              ) : (
                <button
                  onClick={() => {
                    setIsMessageVisible(!isMessageVisible);
                  }}
                  className="bg-primary-600 text-white mx-3 my-2  px-3 py-1 rounded-lg shadow-lg text-xs m-4 flex items-center opacity-50 hover:opacity-100 transition-all"
                >
                  <RiInformationLine className="mr-2" /> <span>Info</span>
                </button>
              )}
            </div>
          )}
          <iframe
            id={`preview-iframe`}
            name={`preview-iframe`}
            className="transition-all "
            onLoad={() => {
              setTimeout(triggerPreviewConfigChange, 1000);
            }}
            style={{ transform: "scale(.66)", transformOrigin: "0% 0%" }}
            width={previewMode === "desk" ? "1080px" : "530px"}
            height="890px"
            src={`${process.env.REACT_APP_WIDGET_PREVIEW_LINK}&lang=${
              previewLang ? previewLang : workspace.data.default_language
            }`}
            title="Thank you page"
          />
        </div>
      </div>
    );
  } else return <LoadingSpinner />;
};

export default WidgetPreview;
