import React, { useState, useEffect } from "react";
import { useGraphQl } from "../../GraphqlClient";
import { useMutation } from "react-query";
import { activateCharge } from "../../api/billing";
import toaster from "toasted-notes";

function PaymentsLoader() {
  const [invalidChargeID, setInvalidChargeID] = useState(false);
  const graphQL = useGraphQl();
  const urlParams = new URLSearchParams(window.location.search);
  const chargeId = urlParams.get("charge_id");

  const chargeActivation = async () => {
    const status = await graphQL(activateCharge, {
      chargeId: chargeId,
    });
    return status;
  };

  const [chargeActivationMutation] = useMutation(chargeActivation, {
    onError: (err) => {
      console.error("Error in chargeActivationMutation ", err);
      window.Rollbar.error("Error in chargeActivationMutation ", err);
      toaster.notify("Redirection Failed, please try logging in again", {
        duration: 4000,
      });
    },
    onSuccess: (data) => {
      if (data.activateCharge.billingStatus === "active")
        window.location.href = "/";
      else
        toaster.notify("Redirection Failed", {
          duration: 2000,
        });
    },
  });

  useEffect(() => {
    if (chargeId) chargeActivationMutation();
    else setInvalidChargeID(true);
  }, []);

  return (
    <>
      <div className="w-screen h-screen flex items-center justify-center bg-blue-100">
        <div className="text-center bg-white shadow-xl p-8 rounded-lg">
          <h1 className="text-6xl animate-bounce -mb-7">💵</h1>
          <h1 className="text-7xl leading-3 mb-14">🤲</h1>
          <h1 className="text-blue-600 font-bold mb-4">
            Updating Payment Status...
          </h1>
          <p className="max-w-lg">
            You will be redirected back to the app shortly. (Is it taking
            longer? If so please re-login from Shopify dashboard by clicking on
            our app icon) are stuck here for more that 5 seconds please do
            relogin to app by clicking our app icon in your shopify dashboard
          </p>
          {invalidChargeID && (
            <p className="mt-3 text-sm text-red-600">
              <b>Error:</b> No charge ID found in the URL
            </p>
          )}
        </div>
      </div>
    </>
  );
}

export default PaymentsLoader;
