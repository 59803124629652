import React from "react";
import { Link } from "react-router-dom";
import Button from "../../../../components/Button";
import { useHistory } from "react-router-dom";
import InlineTooltip from "../../../../components/InlineTooltip";

export default function Intro({ goFront, goBack }) {
  let history = useHistory();
  const Step = ({ title, image, description, number }) => (
    <div className="max-w-sm px-6">
      <div className="flex justify-center">
        <img className="grow-0" width="160px" src={image} />
      </div>

      <h3 className="my-2 font-bold text-blue-900 relative">
        <div className="text-5xl text-bold absolute -top-12 left-4 opacity-10 ">
          {number}
        </div>
        <span>{title}</span>
      </h3>
      <p>{description}</p>
    </div>
  );
  return (
    <div className="text-center flex flex-col justify-center items-center ">
      <div className="max-w-3xl mb-8">
        <h1 className="text-4xl font-light text-blue-900 mb-5">
          How simple referral work?
        </h1>

        <p>
          Simple referral is a referral campaign. The referrer gets a cashback
          on his previous order everytime someone buys using a coupon that they
          shared.
        </p>
      </div>
      <div className="flex justify-end laptop:px-16 mobile:px-0 mt-8">
        <Step
          number={1}
          title="The referral offer is shown when a visitor completes a purchase"
          image="https://assets.cornercart.io/coca-v2/images/onboard/ofco/onboard-1.png"
        />
        <Step
          number={2}
          title="They opt in & unlock a shareable gift coupon"
          image="https://assets.cornercart.io/coca-v2/images/onboard/ofco/onboard-2.png"
        />
        <Step
          number={3}
          title="This gift coupon is shared with their friends"
          image="https://assets.cornercart.io/coca-v2/images/onboard/ofco/onboard-3.png"
        />
        <Step
          number={4}
          title={
            <span>
              Everytime time a friend buy using this coupon, the referrer gets a{" "}
              <InlineTooltip message="Processed as partial refunds">
                cashback
              </InlineTooltip>{" "}
              on his order
            </span>
          }
          image="https://assets.cornercart.io/coca-v2/images/campaign-icons/viral-referrals.png"
        />
      </div>
      <div className="mt-12 flex items-center justify-center">
        <Button
          className="mr-8"
          type="link"
          onClick={() => {
            goBack();
          }}
        >
          Go back
        </Button>
        <button
          onClick={() => {
            goFront();
          }}
          className="text-center py-6 px-24 bg-blue-600 text-white font-bold rounded-lg hover:bg-blue-900 transition-all"
        >
          Create a Simple Referral Campaign
        </button>
      </div>
    </div>
  );
}
