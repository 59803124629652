import React from "react";

{/* interface Props { */}
{/*   pageView: React.ReactNode; */}
{/*   navigationBar: React.ReactNode; */}
{/* } */}
const Section = ({ pageView, navigationBar }) => {
  return (
    <div className=" flex row-start-2 row-end-3 laptop:col-start-2  laptop:shadow-2xl laptop:rounded-tl-section overflow-hidden laptop:border-t laptop:border-l mobile:col-start-1 mobile:col-end-3 z-20">
      <div className="w-52 desktop:w-64 laptop:flex  mobile:hidden flex-col flex-none bg-white bg-opacity-50 border-r">
        {navigationBar}
      </div>
      {pageView}
    </div>
  );
};

export default Section;
