import React, { useState } from "react";
import { useQuery, useMutation, useQueryCache } from "react-query";
import moment from "moment";
import PageViewContainer from "../../../../components/structure/PageViewContainer";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";

import Button from "../../../../components/Button";
import {
  RiAddCircleFill,
  RiArrowLeftLine,
  RiPlayCircleLine,
} from "react-icons/ri";
import {
  queryAllCampaigns,
  insertViralReferrals,
  insertSimpleReferrals,
  insertCampaign,
} from "../../../../api/offers";
import { useGraphQl } from "../../../../GraphqlClient";
import { useWorkspace } from "../../../../WorkspaceContext";
import toaster from "toasted-notes";
import { useHistory } from "react-router-dom";

export default function ViralReferrals(historyProps) {
  const graphQL = useGraphQl();
  const [isVidModalOpen, setIsVidModalOpen] = useState(false);
  const [currentVideo, setcurrentVideo] = useState();
  const workspace = useWorkspace();
  let history = useHistory();
  const queryCache = useQueryCache();
  const { data } = useQuery("queryAllCampaigns", () =>
    graphQL(queryAllCampaigns)
  );
  const defaultsViralReferralCampaign = {
    accountid: workspace.data && workspace.data.accountid,
    sourceid: workspace.data && workspace.data.sourceid,
    name: `Viral Referral ${data ? data.campaigns.length + 1 : ":untitled"}`,
    starts_at: moment().toISOString(),
    expires_at: moment().add(1, "years").toISOString(),
    status: "paused",
    reward_stage: [
      {
        id: Math.floor(Math.random() * 10000),
        rewardTarget: 2,
        discountConfig: {
          discountType: "percentage",
          discountValue: -12,
          targetType: "all",
          minimumRequirementType: "none",
          minimumRequirementValue: 1,
          targetItems: {
            productIds: [],
            collectionIds: [],
          },
        },
      },
      {
        id: Math.floor(Math.random() * 10000),
        rewardTarget: 5,
        discountConfig: {
          discountType: "percentage",
          discountValue: -20,
          targetType: "all",
          minimumRequirementType: "none",
          minimumRequirementValue: 1,
          targetItems: {
            productIds: [],
            collectionIds: [],
          },
        },
      },
      {
        id: Math.floor(Math.random() * 10000),
        rewardTarget: 10,
        discountConfig: {
          discountType: "percentage",
          discountValue: -25,
          targetType: "all",
          minimumRequirementType: "none",
          minimumRequirementValue: 1,
          targetItems: {
            productIds: [],
            collectionIds: [],
          },
        },
      },
      {
        id: Math.floor(Math.random() * 10000),
        rewardTarget: 25,
        discountConfig: {
          discountType: "percentage",
          discountValue: -40,
          targetType: "all",
          minimumRequirementType: "none",
          minimumRequirementValue: 1,
          targetItems: {
            productIds: [],
            collectionIds: [],
          },
        },
      },
    ],
    gift_config: {
      discountType: "percentage",
      discountValue: -10,
      targetType: "all",
      discountSharePageType:"popup",
      minimumRequirementType: "none",
      targetItems: {
        productIds: [],
        collectionIds: [],
      },
      minimumRequirementValue: 1,
    },
    settings_config: {
      giftPageColor: "rgb(62,119,255)",
      placement: {
        thankyouPage: true,
        cornerWidget: false,
      },
      thankyouPageConfig: {
        bgStartColor: "rgb(255,255,255)",
        bgEndColor: "rgb(217, 238, 255)",
        fgColor: "dark",
        ctaColor: "rgb(62,119,255)",
        popupThemeColor: "rgb(62,119,255)",
        widgetPosition: 4,
        minOrderValue: 0,
        widgetCustomCss:""
      },
      offerTerms:
        "Reward coupons can be redeemed only once. Reward coupons are sent by email. You agree to permit us to use the email-id you provided to send these emails. The Minimum purchase amount may apply for gift and/or reward coupons. Other conditions may apply. Please email us if you need clarifications.",
      emailConfig: {
        themeColor: "rgb(33,118,255)",
        shopName: workspace.data.storeName,
        fgColor: "white",
        subject: `${workspace.data.storeName}: Reward milestone unlocked - Thank you for referring more people!`,
        primaryTitle: "Reward Unlocked",
        greeting: "Hey congrats!",
        content:
          "You have unlocked a higher reward stage by sharing your coupon with more people. Here's the updated reward code for you. Enjoy",
        couponTitle: "Your reward coupon for",
        cta: "Redeem Now",
        subCta:
          "This reward code is just for you. It can be redeemed only once.Visit our website to get your unique referral code to share with more people and unlock bigger rewards.",
        logoUrl:
          "https://assets.cornercart.io/coca-v2/images/email-icons/sample-logo.png",
      },
      giftEmailConfig: {
        themeColor: "rgb(62,119,255)",
        shopName: workspace.data.storeName,
        primaryTitle: "Your Referrer code",
        subject: `${workspace.data.storeName}: Thank you for signing up & here's your referrer code`,
        subCta:
          "The referral code is meant for your friends. You cannot redeem it for yourself.",
        logoUrl:
          "https://assets.cornercart.io/coca-v2/images/email-icons/sample-logo.png",
        greeting: "Thank you for signing up for our referral program!",
        content:
          "Here's your personal referral code that you can share with your friends. Earn bigger rewards, when more friends use your referral code to make a purchase!. Happy sharing!.",
        fgColor: "white",
        couponTitle: "Share this coupon to Gift your friends a discount of",
        cta: "More share options",
      },
    },
  };

  const defaultSimpleReferralCampaign = {
    accountid: workspace.data && workspace.data.accountid,
    sourceid: workspace.data && workspace.data.sourceid,
    name: `Simple Referral ${data ? data.campaigns.length + 1 : ":untitled"}`,
    starts_at: moment().toISOString(),
    expires_at: moment().add(1, "years").toISOString(),
    status: "paused",
    is_auto_optin: false,
    max_discount_value: 999999999,
    min_order_value: 0,
    postpurchase_config: {
      bgStartColor: "rgb(255,255,255)",
      bgEndColor: "rgb(217, 238, 255)",
      fgColor: "dark",
      ctaColor: "rgb(62,119,255)",
      popupThemeColor: "rgb(62,119,255)",
      widgetPosition: 4,
      giftPageColor: "rgb(62,119,255)",
      widgetCustomCss:""
    },
    reward_config: {
      rewardValue: -10,
      rewardDimension: "percentage",
      targetType: "all",
      minimumRequirementType: "none",
      targetItems: {
        productIds: [],
        collectionIds: [],
      },
      minimumRequirementValue: 1,
    },
    reward_type: "cashback",
    terms_config:
      "Reward coupons can be redeemed only once. Reward coupons are sent by email. You agree to permit us to use the email-id you provided to send these emails. The Minimum purchase amount may apply for gift and/or reward coupons. Other conditions may apply. Please email us if you need clarifications.",
    email_config: {
      rewardEmailConfig: {
        themeColor: "rgb(33,118,255)",
        shopName: workspace.data.storeName,
        fgColor: "white",
        subject: `${workspace.data.storeName}: Reward milestone unlocked - Thank you for referring more people!`,
        primaryTitle: "Reward Unlocked",
        greeting: "Hey congrats!",
        content:
          "You have unlocked a higher reward stage by sharing your coupon with more people. Here's the updated reward code for you. Enjoy",
        couponTitle: "Your reward coupon for",
        cta: "Redeem Now",
        subCta:
          "This reward code is just for you. It can be redeemed only once.Visit our website to get your unique referral code to share with more people and unlock bigger rewards.",
        logoUrl:
          "https://assets.cornercart.io/coca-v2/images/email-icons/sample-logo.png",
      },
      giftEmailConfig: {
        themeColor: "rgb(62,119,255)",
        shopName: workspace.data.storeName,
        primaryTitle: "Your Referrer code",
        subject: `${workspace.data.storeName}: Thank you for signing up & here's your referrer code`,
        subCta:
          "The referral code is meant for your friends. You cannot redeem it for yourself.",
        logoUrl:
          "https://assets.cornercart.io/coca-v2/images/email-icons/sample-logo.png",
        greeting: "Thank you for signing up for our referral program!",
        content:
          "Here's your personal referral code that you can share with your friends. Earn bigger rewards, when more friends use your referral code to make a purchase!. Happy sharing!.",
        fgColor: "white",
        couponTitle: "Share this coupon to Gift your friends a discount of",
        cta: "More share options",
      },
    },
    gift_config: {
      discountType: "percentage",
      discountValue: -10,
      targetType: "all",
      discountSharePageType:"popup",
      minimumRequirementType: "none",
      targetItems: {
        productIds: [],
        collectionIds: [],
      },
      minimumRequirementValue: 1,
    },
  };

  const insertCampaigns = async (input) => {
    return await graphQL(insertCampaign, {
      campaignInput: {
        accountid: workspace.data && workspace.data.accountid,
        sourceid: workspace.data && workspace.data.sourceid,
        // this logic takes the campaign with highest priority and increments in with 1
        priority:
          data && data.campaigns.length > 0
            ? data.campaigns.sort((a, b) => {
                return b.priority - a.priority;
              })[0].priority + 1
            : 10,
        type: input,
      },
    });
  };

  const [insertCampaignsMutation] = useMutation(insertCampaigns, {
    onError: (err) => {
      console.error("Error in insertCampaignsMutation", err);
      window.Rollbar.error("Error in insertCampaignsMutation", err);
    },
    onSuccess: (data) => {
      toaster.notify("campaign created", {
        duration: 2000,
      });
      if (data.insert_campaigns_one.type === "VIRAL_REFERRAL")
        insertViralReferralMutation({
          campaignId: data.insert_campaigns_one.id,
        });
      else
        insertSimpleReferralMutation({
          campaignId: data.insert_campaigns_one.id,
        });
    },
  });

  const insertViralReferral = async (input) => {
    return await graphQL(insertViralReferrals, {
      viralReferralsInput: {
        campaignid: input.campaignId,
        ...defaultsViralReferralCampaign,
      },
    });
  };

  const [insertViralReferralMutation] = useMutation(insertViralReferral, {
    onError: (err) => {
      console.log("error", err);
      window.Rollbar.error("Error in insertViralReferralMutation ", err);
    },
    onSuccess: (data) => {
      historyProps.history.push(
        `/offers/referral/campaigns/viral-referral/edit/${data.insert_viral_referrals_one.campaignid}`
      );
    },
  });

  const insertSimpleReferral = async (input) => {
    return await graphQL(insertSimpleReferrals, {
      simpleReferralsInput: {
        campaignid: input.campaignId,
        ...defaultSimpleReferralCampaign,
      },
    });
  };

  const [insertSimpleReferralMutation] = useMutation(insertSimpleReferral, {
    onError: (err) => {
      console.error("error in insertSimpleReferralMutation", err);
      window.Rollbar.error("Error in insertSimpleReferralMutation ", err);
    },
    onSuccess: (data) => {
      historyProps.history.push(
        `/offers/referral/campaigns/simple-referral/edit/${data.insert_simple_referrals_one.campaignid}`
      );
    },
  });

  const CampaignCard = ({
    title,
    children,
    videoLink,
    ctaText,
    onSelect,
    thumbnail,
  }) => {
    return (
      <div className=" flex w-full rounded-lg px-8 py-5 border bg-white items-center ">
        <button
          onClick={() => {
            setIsVidModalOpen(true);
            setcurrentVideo({
              link: videoLink,
              title: title,
              onSelect: onSelect,
            });
          }}
          className=" relative desktop:w-64 laptop:w-56 mobile:w-52 mb-3 shrink-0 desktop:m-4 hover:opacity-90   hover:shadow-lg transition-all"
        >
          <div className="absolute left-0 top-0 w-full h-full text-6xl text-white bg-blue-900  bg-opacity-50 opacity-10 rounded-lg transition-all hover:opacity-100 flex justify-center items-center">
            <RiPlayCircleLine />
          </div>
          <img className="rounded-lg" src={thumbnail} alt="" />
          {/* Watch Video */}
        </button>
        <div className="ml-4">
          <h3 className="text-lg font-bold text-blue-900 mb-2">{title}</h3>
          <div>{children}</div>
          <div className=" mt-4 ">
            <Button
              onClick={onSelect}
              type="primary"
              icon={<RiAddCircleFill />}
            >
              {ctaText}
            </Button>
          </div>
        </div>
      </div>
    );
  };

  if (data)
    return (
      <PageViewContainer title="Create Campaign" subTitle="Referral">
        <div className="desktop:px-16 mobile:px-3 h-full bg-gradient-to-b from-white to-blue-100 overflow-y-auto  pt-4 ">
          <div>
            <h3 className="m-3 flex items-center justify-center text-2xl font-bold text-blue-900">
              Select a referral campaign type
            </h3>
          </div>
          <div className="flex flex-wrap mt-4">
            <div className="flex  p-3 laptop:w-1/2 mobile:w-full ">
              <CampaignCard
                title="Simple Referral"
                ctaText="Create a Simple Referral"
                videoLink="https://www.youtube.com/embed/FZNOs-wrVJI?&autoplay=1"
                thumbnail="https://assets.cornercart.io/coca-v2/images/video-thumbnails/hiw-simple-referrals.png"
                onSelect={async () =>
                  insertCampaignsMutation("SIMPLE_REFERRAL")
                }
              >
                <p className="text-gray-700">
                  Offer a reward every time they refer a friend to your store{" "}
                </p>
              </CampaignCard>
            </div>
            <div className="flex  p-3 laptop:w-1/2 mobile:w-full">
              <CampaignCard
                title="Viral Referral"
                ctaText="Create a Viral Referral"
                videoLink="https://www.youtube.com/embed/rbZA2eEzShE"
                thumbnail="https://assets.cornercart.io/coca-v2/images/video-thumbnails/hiw-viral-referrals.png"
                onSelect={async () => insertCampaignsMutation("VIRAL_REFERRAL")}
              >
                <p className="text-gray-700">
                  Make it a game. Offer multiple rewards to unlock based on the
                  number of friends they refer{" "}
                </p>
              </CampaignCard>
            </div>
          </div>
          <Button
            onClick={() => history.goBack()}
            icon={<RiArrowLeftLine />}
            className="mt-5"
            type="link"
          >
            Go Back
          </Button>
        </div>
        <ModalTransition>
          {isVidModalOpen && (
            <Modal onClose={() => setIsVidModalOpen(false)}>
              <div className="py-5">
                <div className="w-full h-96 rounded-lg overflow-hidden">
                  <iframe
                    width="100%"
                    height="100%"
                    src={currentVideo.link}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
                <div>
                  <div className="flex justify-center mt-8">
                    <Button
                      onClick={() => setIsVidModalOpen(false)}
                      className="mr-4"
                      type="link"
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={currentVideo.onSelect}
                      appearance="primary"
                      autoFocus
                    >
                      {`Create ${currentVideo.title} Campaign`}
                    </Button>
                  </div>
                </div>
              </div>
            </Modal>
          )}
        </ModalTransition>
      </PageViewContainer>
    );
  else return <LoadingSpinner />;
}
