import React, { useState, useEffect } from "react";
import CornerWidgetPreview from "../../support/widget/Previews/CornerWidgetPreview";
import { useQuery, useMutation } from "react-query";
import Button from "../../../components/Button";
import GradientPicker from "../../../components/GradientPicker";
import { queryWidgetSettings, updateWidgetSettings } from "../../../api/widgets";
import { useGraphQl } from "../../../GraphqlClient";

export default function WidgetCreation(props) {
  const graphQL = useGraphQl();
  const { data, refetch } = useQuery("queryWidgetSettings", () =>
    graphQL(queryWidgetSettings)
  );
  const [bgStartColor, setBgStartColor] = useState("rgb(255,158,255)");
  const [bgEndColor, setBgEndColor] = useState("rgb(101,191,244)");

  const updateWidget = async (values) => {
    await graphQL(updateWidgetSettings, {
      widgetSettingsId: values.id,
      widgetSettingsInput: values.config,
    });
    refetch();
  };

  const [updateWidgetMutation] = useMutation(updateWidget, {
    onSuccess: () => {
      props.history.push(`/get-started/2/`);
    },
  });

  const handleSubmit = () => {
    updateWidgetMutation({
      id: { id: data && data.widgets_cornerwidget[0].id },
      config: {
        common_config: {
          ...data.widgets_cornerwidget[0].common_config,
          bgStartColor: bgStartColor,
          bgEndColor: bgEndColor,
        },
      },
    });
  };

  useEffect(() => {
    if (data) {
      setBgStartColor(
        data.widgets_cornerwidget[0]?.common_config?.bgStartColor
      );
      setBgEndColor(data.widgets_cornerwidget[0]?.common_config?.bgEndColor);
    }
  }, [data]);

  return (
    <div className="laptop:flex mobile:block  items-center h-full mt-8">
      <div className="p-8 grow max-w-xl">
        <div className="mb-8">
          <h1 className="font-bold text-blue-900 mb-3">
            Lets customise the widget
          </h1>
          <p>
            For starters lets customize the colors of our widget. More
            customisations are available in our settings but for now let us
            start simple
          </p>
        </div>
        <div className="bg-white mb-3 shadow-lg rounded-lg">
          <GradientPicker
            startColor={bgStartColor}
            endColor={bgEndColor}
            onChange={(startColor,endColor)=>{
              setBgStartColor(startColor);
              setBgEndColor(endColor);
            }}
          />
        </div>
        <div className="flex justify-end ">
          <Button type="primary" onClick={() => handleSubmit()}>
            Continue
          </Button>
        </div>
      </div>
      <div className=" scale-75">
        {data && (
          <CornerWidgetPreview
            state="open"
            bgStartColor={bgStartColor}
            bgEndColor={bgEndColor}
            shape="circle"
            size="large"
            viewState="assistant"
            foregroundColor="light"
            hideSidebar={true}
            search={false}
            langObject={data?.widgets_cornerwidget[0].corner_lang}
            defaultLang={data?.workspaces[0].source.default_language}
            introEmoji={
              data?.widgets_cornerwidget[0].common_config.contextConfigs
                .assistant.introEmoji
            }
            contactOptions={{
              email: "sa",
              messenger: "sa",
              whatsapp: "sa",
              sms:""
            }}
          />
        )}
      </div>
    </div>
  );
}
