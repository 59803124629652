import React, { useState, useEffect } from "react";
import { useQuery, useQueryCache } from "react-query";
import { RiHandCoinLine, RiShoppingBag3Line } from "react-icons/ri";
import { queryAnalyticsInfo } from "../../../../api/analytics";
import { useGraphQl } from "../../../../GraphqlClient";
import moment from "moment";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import { useWorkspace } from "../../../../WorkspaceContext";

export default function Overview({ fromDate, toDate }) {
  const workspace = useWorkspace();
  const graphQL = useGraphQl();
  const queryCache = useQueryCache();
  const { data } = useQuery("queryAnalyticsInfo", () =>
    graphQL(queryAnalyticsInfo, {
      fromDate: fromDate,
      toDate: toDate,
    })
  );
  
  const AnalyticsCard = (cardProps) => (
    <div className="flex grow items-center rounded-lg bg-blue-100 bg-opacity-50 laptop:mr-4 px-3 desktop:py-12 laptop:py-8 mobile:py-4 mobile:mr-2 ">
      <div className="text-blue-600 opacity-20 text-6xl mobile:hidden  laptop:block ">
        {cardProps.icon}
      </div>
      <div className="laptop:ml-6 mobile:ml-0">
        <h1 className="text-blue-600 leading-none laptop:text-5xl mobile:text-3xl font-light m-0">
          {cardProps.data}
        </h1>
        <p className="text-blue-900 laptop:text-sm mobile:text-xs font-bold">
          {cardProps.label}
        </p>
      </div>
    </div>
  );

  const CampaignCard = (campaignCardProps) => (
    <div
      className={`laptop:w-1/2 mobile:w-full shrink-0 p-3 ${
        campaignCardProps.disabled && "opacity-50 pointer-events-none"
      }`}
    >
      <div className="border rounded-lg  px-4 py-2 desktop:px-8 desktop:py-4 ">
        <div className="flex items-center">
          <div className=" mr-4 shrink-0">
            <img src={campaignCardProps.imageUrl} width="100px" alt="" />
          </div>
          <div className="">
            <h3 className="mb-2 font-bold text-blue-600 text-base desktop:text-lg">
              {campaignCardProps.title}
            </h3>
            <p className=" mb-3 text-gray-600 text-tiny desktop:text-base">
              {campaignCardProps.description}
            </p>
            <div className="shrink-0">{campaignCardProps.actions}</div>
          </div>
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    queryCache.invalidateQueries("queryAnalyticsInfo");
  }, [fromDate, toDate]);

  if (data)
    return (
      <div>
        <div className="flex mt-3">
          <AnalyticsCard
            icon={<RiHandCoinLine />}
            data={
              <span>
                {data.sources[0].orders_aggregate.aggregate.sum.order_value
                  ? data.sources[0].orders_aggregate.aggregate.sum.order_value.toFixed(
                      2
                    )
                  : 0}
                <span className="laptop:text-3xl mobile:text-base">
                  {" "} USD
                </span>
              </span>
            }
            label="EXTRA REVENUE GENERATED"
          />
          <AnalyticsCard
            icon={<RiShoppingBag3Line />}
            data={
              data.sources[0].orders_aggregate.aggregate.count
                ? data.sources[0].orders_aggregate.aggregate.count
                : 0
            }
            label="EXTRA ORDERS CREATED"
          />
        </div>
        <div className="flex items-center justify-end">
          <small className="text-xs mt-2 mr-4">
            ( Based on data from last 30 days )
          </small>
        </div>
      </div>
    );
  else return <LoadingSpinner />;
}
