import React, { useState, useEffect } from "react";

import CocaSession from "./session";
/* AuthContext's primary responsibility is to supply
 * JWT tokens needed by the app to authenticate calls
 * to our graphql endpoint.
 */

const defaultData = {};
const AuthenticationContext = React.createContext(defaultData);

const AuthenticationProvider = (props) => {
  const urlParams = new URLSearchParams(window.location.search);
  const [authState, setAuthState] = useState({
    status: "loading",
    token: "",
    reason: "",
  });

  useEffect(() => {
    let customToken = urlParams.get("token") || CocaSession.getJWTToken();
    const refreshToken =
      urlParams.get("rf_token") || CocaSession.getRefreshToken();
    if (!customToken || !refreshToken) {
      setAuthState({ status: "out", token: "", reason: "NO_TOKEN" });
      window.Rollbar.error(
        "Failed in authentication context: rf token or custom token doesnt exist",
        {
          customToken: customToken,
          refreshToken: refreshToken,
        }
      );
    } else {
      const isHasuraClaimExist = CocaSession.isHasuraClaimsExists(customToken);
      if (isHasuraClaimExist) {
        CocaSession.setJWTToken(customToken);
        CocaSession.setRefreshToken(refreshToken);
        setAuthState({ status: "in", token: customToken, reason: "" });
      } else {
        window.Rollbar.error(
          "Failed in authentication context Hasura claim does not exist",
          {
            customToken: customToken,
            refreshToken: refreshToken,
          }
        );
        setAuthState({ status: "out", token: "", reason: "NO_TOKEN" });
      }
    }
  }, []);

  return <AuthenticationContext.Provider value={authState} {...props} />;
};
const useAuth = () => React.useContext(AuthenticationContext);

export { AuthenticationProvider, useAuth };
