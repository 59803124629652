import React from "react";
import { RiArrowLeftLine } from "react-icons/ri";
import { useHistory } from "react-router-dom";
{/* interface Props { */}
{/*   title: string; */}
{/*   subTitle: string; */}
{/*   action?: React.ReactNode; */}
{/*   children: React.ReactNode; */}
{/* } */}
const PageViewContainer = ({ title, subTitle, action, children }) => {
  let history = useHistory();
  return (
    <div className="grid grid-rows-shrinked-page-row desktop:grid-rows-page-row grid-cols-page-col flex-1 bg-white">
      <div className="col-start-1 row-start-1 row-end-2 flex items-center justify-between px-4 desktop:px-8 border-b">
        <div className="flex flex-none items-center">
          <button onClick={()=>history.goBack()} className="grow-0  p-2 desktop:p-3  mr-3 border border-gray-300 text-blue-600 hover:border-blue-400 transition-all rounded-lg">
            <RiArrowLeftLine />
          </button>
          <div className="">
            <small className="text-blue-600 font-semibold text-xs desktop:text-tiny ">{subTitle}</small>
            <h1 className="font-extrabold text-blue-900 leading-5 desktop:leading-6 text-xl desktop:text-2xl">
              {title}
            </h1>
          </div>
        </div>
        {action && <div>{action}</div>}
      </div>
      <div className="col-start-1 row-start-2 row-end-3">{children}</div>
    </div>
  );
};
export default PageViewContainer;
