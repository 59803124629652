import React from "react";
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import PageViewContainer from "../../../components/structure/PageViewContainer";
import General from "./General";
import CornerWidget from "./CornerWidget";
import FaqPage from "./FaqPage";
import OfferWidget from "./OfferWidget";
import OfferPage from "./OfferPage";

export default function LanguageSettings(props) {
  let { path } = useRouteMatch();
  const location = useLocation();
  const pageRoute = location.pathname.split("/")[3];

  const getTitle = () => {
    switch (pageRoute) {
      case "general":
        return "General";
      case "corner-widget":
        return "Corner Widget";
      case "faq-page":
        return "FAQ Page";
      default:
        return "General";
    }
  };

  return (
    <PageViewContainer title={getTitle()} subTitle="Language">
      <Switch>
        <Route
          path={`${path}/general/`}
          render={() => <General {...props} />}
        />
        <Route
          path={`${path}/corner-widget/`}
          render={() => <CornerWidget {...props} />}
        />
        <Route
          path={`${path}/faq-page/`}
          render={() => <FaqPage {...props} />}
        />
        <Route
          path={`${path}/offer-page/`}
          render={() => <OfferPage {...props} />}
        />
        <Route
          path={`${path}/offer-widget/`}
          render={() => <OfferWidget {...props} />}
        />
        <Route path={`${path}`}>
          <Redirect to="/settings/language/general/" />
        </Route>
      </Switch>
    </PageViewContainer>
  );
}
