import React, { useState } from "react";
import PageViewContainer from "../../../../components/structure/PageViewContainer";
import List from "../../../../components/List";
import Button from "../../../../components/Button";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import { useQuery, useMutation } from "react-query";
import { Link } from "react-router-dom";
import SucoPricingModal from "../../../../components/SucoPricingModal";
import {
  queryAllStaticAccordions,
  insertAccordion,
} from "../../../../api/support";
import { useGraphQl } from "../../../../GraphqlClient";
import {
  RiFileAddLine,
  RiEdit2Line,
  RiCheckboxBlankCircleFill,
} from "react-icons/ri";
import toaster from "toasted-notes";
import { useWorkspace } from "../../../../WorkspaceContext";

export default function CustomAccordionList(historyProps) {
  const graphQL = useGraphQl();
  const workspace = useWorkspace();
  const [isBillingModalOpen, setIsBillingModalOpen] = useState(false);
  const defaultAccordion = {
    accountid: workspace.data && workspace.data.accountid,
    is_active: true,
    config: {
      questionTextColor: "rgb(39, 39, 39)",
      questionBgColor: "rgb(255, 255, 255)",
      answerBgColor: "rgb(255, 255, 255)",
      blockAutoRender: true,
    },
    custom_css: "",
    name: "Untitled Accordion",
    sourceid: workspace.data && workspace.data.sourceid,
    type: "static",
  };
  const { data } = useQuery("queryAllStaticAccordions", () =>
    graphQL(queryAllStaticAccordions)
  );

  const addAccordion = async () => {
    return await graphQL(insertAccordion, {
      accordionInput: defaultAccordion,
    });
  };

  const [insertAccordionMutation] = useMutation(addAccordion, {
    onSuccess: (data) => {
      toaster.notify("New accordion created", {
        duration: 2000,
      });
      historyProps.history.push(
        `/support/faq/custom-accordions/${data.insert_accordions_one.id}`
      );
    },
  });

  if (data && data.accordions) {
    return (
      <PageViewContainer
        title="Custom Accordions"
        subTitle="FAQ"
        action={
          workspace.data?.feature_flag?.apps?.supportcorner
            ?.custom_accordions && (
            <div className="flex">
              <Button
                onClick={async () => insertAccordionMutation()}
                type="primary"
                icon={<RiFileAddLine />}
              >
                Add Accordion
              </Button>
            </div>
          )
        }
      >
        {workspace.data?.feature_flag?.apps?.supportcorner?.custom_accordions ? (
          <div className="desktop:px-8 mobile:px-3 pt-4 h-full overflow-y-auto">
            <List
              items={data.accordions}
              header={
                <div className="grid grid-cols-12 items-center justify-center ">
                  <div className="px-3 laptop:col-span-7 mobile:col-span-9">
                    <p className="font-bold text-gray-600 text-sm desktop:text-tiny">Title</p>
                  </div>
                  <div className="px-3 col-span-2 laptop:flex mobile:hidden text-sm desktop:text-tiny">
                    <p className="font-bold text-gray-600">Status</p>
                  </div>
                </div>
              }
              emptyState={
                <div>
                  <div className="flex justify-center my-4 desktop:my-8">
                    <img
                      src="https://assets.cornercart.io/coca-v2/images/empty-states/file.png"
                      alt=""
                    />
                  </div>
                  <div className="text-center">
                    <h3 className="font-bold text-blue-900 mb-3">
                      Create an Accordion{" "}
                    </h3>
                    <p className="text-gray-800">
                      You currently have no accordions. Click the the button
                      below to add one
                    </p>
                    <div className="flex justify-center mt-8">
                      <Button
                        onClick={async () => insertAccordionMutation()}
                        type="ghost"
                        icon={<RiFileAddLine />}
                      >
                        Create a new accordion
                      </Button>
                    </div>
                  </div>
                </div>
              }
              render={(item, index) => {
                return (
                  <Link to={`/support/faq/custom-accordions/${item.id}`}>
                    <div className="grid grid-cols-12 items-center justify-center desktop:py-5 py-2">
                      <div className="px-3 laptop:col-span-7 mobile:col-span-9">
                        <p className="font-bold text-blue-900 flex items-center ">
                          <span>{item.name}</span>{" "}
                        </p>
                        <div className="mobile:flex laptop:hidden items-center mt-3">
                          <small className="text-xs">
                            {item.is_active ? (
                              <span className="flex items-center rounded-sm ">
                                <RiCheckboxBlankCircleFill className="flex-none text-green-400 mr-1 text-xs" />{" "}
                                Published{" "}
                              </span>
                            ) : (
                              <span className="flex items-center  rounded-s">
                                <RiCheckboxBlankCircleFill className="flex-none text-amber-400 mr-1 text-xs" />{" "}
                                Draft{" "}
                              </span>
                            )}
                          </small>
                        </div>
                      </div>

                      <div className="px-3 col-span-2 laptop:flex mobile:hidden ">
                        <small className="text-sm">
                          {item.is_active ? (
                            <span className="flex items-center rounded-sm ">
                              <RiCheckboxBlankCircleFill className="flex-none text-green-400 mr-1 text-sm" />{" "}
                              Published{" "}
                            </span>
                          ) : (
                            <span className="flex items-center  rounded-s">
                              <RiCheckboxBlankCircleFill className="flex-none text-amber-400 mr-1 text-sm" />{" "}
                              Draft{" "}
                            </span>
                          )}
                        </small>
                      </div>

                      <div className="p-1 desktop:px-3 col-span-3 flex">
                        <p className="pointer-cursor text-blue-900 hover:text-blue-600 px-2 py-1 desktop:p-3 border border-gray-500  hover:border-blue-600 flex rounded-md items-center text-tiny desktop:text-base">
                          <RiEdit2Line />
                          <span className="laptop:block mobile:hidden ml-2">
                            {" "}
                            Edit Accordion
                          </span>
                        </p>
                      </div>
                    </div>
                  </Link>
                );
              }}
            />
          </div>
        ) : (
          <div className="h-full flex items-center justify-center flex-col">
            <div className="flex justify-center my-8">
              <img
                src="https://assets.cornercart.io/coca-v2/images/empty-states/dynamic-accordion.png"
                alt=""
              />
            </div>
            <div className="text-center max-w-3xl">
              <h3 className="font-bold text-blue-900 mb-3">
                You need a higher plan to access Custom Accordions{" "}
              </h3>
              <p className="text-gray-800">
                With custom accordions you can create an accordion using any set
                of FAQs. And these accordions can be embedded anywhere on your
                website
              </p>
              <div className="flex justify-center mt-8">
                <Button
                  type="primary"
                  className="shrink-0 border-amber-900 mx-3"
                  onClick={() => setIsBillingModalOpen(true)}
                >
                  {" "}
                  Get this feature
                </Button>
                <SucoPricingModal
                  title="You need a higher plan to access this feature"
                  isOpen={isBillingModalOpen}
                  onClose={() => setIsBillingModalOpen(false)}
                />
              </div>
              <small className="text-xs text-gray-500">Every plan upgradation will have 14 day* trial period</small>
            </div>
          </div>
        )}
      </PageViewContainer>
    );
  } else return <LoadingSpinner />;
}
