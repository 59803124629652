import React, { useState, useEffect } from "react";
import { useWorkspace } from "../../WorkspaceContext";
import { RiStore2Fill } from "react-icons/ri";
import {
  RiSettings4Fill,
  RiArrowDropRightFill,
  RiBookMarkFill,
  RiAwardFill,
  RiArrowRightLine,
  RiShoppingCartFill
} from "react-icons/ri";
import Tree from "@atlaskit/tree";
import { NavLink, useLocation, useRouteMatch } from "react-router-dom";

const SectionNav = () => {
  const workspace = useWorkspace();

  const faqSectionNavTree = {
    rootId: 1,
    items: {
      1: {
        id: 1,
        children: [2, 8],
        hasChildren: true,
      },
      2: {
        id: 2,
        children: [3, 4, 5, 6, 7],
        isExpanded: true,
        hasChildren: true,
        data: {
          name: "FAQ",
          type: "title",
          link: "/support/faq/articles/",
        },
      },
      3: {
        id: 3,
        children: [],
        data: {
          name: "Articles",
          type: "page",
          link: "/support/faq/articles/",
        },
      },
      4: {
        id: 4,
        children: [],
        data: {
          name: "Categories",
          type: "page",
          link: "/support/faq/categories/",
        },
      },
      5: {
        id: 5,
        children: [],
        data: {
          name: "FAQ Page",
          type: "page",
          link: "/support/faq/page/",
        },
      },
      6: {
        id: 6,
        children: [],
        data: {
          name: "Custom Accordions",
          type: "page",
          link: "/support/faq/custom-accordions/",
        },
      },
      7: {
        id: 7,
        children: [],
        data: {
          name: "Product Accordion",
          type: "page",
          link: "/support/faq/product-accordion/",
        },
      },
      8: {
        id: 8,
        children: [9, 10],
        isExpanded: true,
        hasChildren: true,
        data: {
          name: "Widget",
          type: "title",
          link: "/support/widget/close-state/",
        },
      },
      9: {
        id: 9,
        children: [],
        data: {
          name: "Closed State",
          type: "page",
          link: "/support/widget/close-state/",
        },
      },
      10: {
        id: 10,
        children: [],
        data: {
          name: "Opened state",
          type: "page",
          link: "/support/widget/open-state/",
        },
      },
    },
  };
  const campaignSectionNavTree = {
    rootId: 1,
    items: {
      1: {
        id: 1,
        children: [2],
        hasChildren: true,
      },
      2: {
        id: 2,
        children: [3, 4],
        isExpanded: true,
        hasChildren: true,
        data: {
          name: "Referral",
          type: "title",
          link: "/offers/referral/overview/",
        },
      },
      3: {
        id: 3,
        children: [],
        data: {
          name: "Campaigns",
          type: "page",
          link: "/offers/referral/campaigns/",
        },
      },
      4: {
        id: 4,
        children: [],
        data: {
          name: "Requests",
          type: "page",
          link: "/offers/referral/requests/",
        },
      },
    },
  };

  const cartSectionNavTree = {
    rootId: 1,
    items: {
      1: {
        id: 1,
        children: [2],
        hasChildren: true,
      },
      2: {
        id: 2,
        children: [3,4],
        isExpanded: true,
        hasChildren: true,
        data: {
          name: "Widgets",
          type: "title",
          link: "/cart/widgets/",
        },
      },
      3: {
        id: 3,
        children: [],
        data: {
          name: "Cart Widget",
          type: "page",
          link: "/cart/widgets/corner-cart",
        },
      },
      4: {
        id: 4,
        children: [5],
        data: {
          name: "Add To Cart Bar ",
          type: "page",
          link: "/cart/widgets/atc-bar",
        },
      }
    },
  };

  const getLanguageSetingsSubSections = () => {
    let baseItems = [6];
    if (workspace.data.installedApps.find((item) => item.appid === 1)) {
      baseItems.push(7, 8);
    }
    if (workspace.data.installedApps.find((item) => item.appid === 2)) {
      baseItems.push(9, 10);
    }
    return baseItems;
  };

  const settingsSectionNavTree = {
    rootId: 1,
    items: {
      1: {
        id: 1,
        children: [2, 5, 11],
        hasChildren: true,
      },
      2: {
        id: 2,
        children: [3,4],
        isExpanded: true,
        hasChildren: true,
        data: {
          name: "Account",
          type: "title",
          link: "/settings/account/billing/",
        },
      },
      3: {
        id: 3,
        children: [],
        data: {
          name: "Billing",
          type: "page",
          link: "/settings/account/billing/",
        },
      },
      4: {
        id: 4,
        children: [],
        data: {
          name: "Store Settings",
          type: "page",
          link: "/settings/account/store/",
        },
      },
      5: {
        id: 5,
        children: getLanguageSetingsSubSections(),
        isExpanded: true,
        hasChildren: true,
        data: {
          name: "Language Settings",
          type: "title",
          link: "/settings/language/general/",
        },
      },
      6: {
        id: 6,
        children: [],
        data: {
          name: "General",
          type: "page",
          link: "/settings/language/general/",
        },
      },
      7: {
        id: 7,
        children: [],
        data: {
          name: "FAQ Widget",
          type: "page",
          link: "/settings/language/corner-widget/",
        },
      },
      8: {
        id: 8,
        children: [],
        data: {
          name: "FAQ Page",
          type: "page",
          link: "/settings/language/faq-page/",
        },
      },
      9: {
        id: 9,
        children: [],
        data: {
          name: "Offer Widget",
          type: "page",
          link: "/settings/language/offer-widget/",
        },
      },
      10: {
        id: 10,
        children: [],
        data: {
          name: "Offer Pages",
          type: "page",
          link: "/settings/language/offer-page/",
        },
      },
      11: {
        id: 11,
        children: [12],
        isExpanded: true,
        hasChildren: true,
        data: {
          name: "Integration",
          type: "title",
          link: "/settings/integration/apps/",
        },
      },
      12: {
        id: 12,
        children: [],
        data: {
          name: "Apps",
          type: "page",
          link: "/settings/integration/apps/",
        },
      },
    },
  };

  const adSectionNavTree = {
    rootId: 1,
    items: {},
  };

  const [sectionNavTreeData, setSectionNavTreeData] = useState(
    settingsSectionNavTree
  );
  const [headerIcon, setHeaderIcon] = useState(<RiArrowDropRightFill />);
  const [headerText, setHeaderText] = useState("Corner Cart");

  const SectionLink = ({ item }) => {
    let match = useRouteMatch(item.data.link);
    const isSelected = match?.path === item.data.link;
    return (
      <NavLink to={item.data.link} strict>
        <div
          className={`py-1 px-2 desktop:py-2 desktop:px-4 mt-2 rounded-md  ${
            isSelected &&
            item.data.type === "page" &&
            "bg-blue-400 bg-opacity-10"
          } ${
            item.data.type === "page" &&
            " hover:bg-blue-400 hover:bg-opacity-10"
          }`}
        >
          {item.data.type === "title" ? (
            <p className="text-tiny desktop:text-base font-bold text-blue-900">
              {item.data.name}
            </p>
          ) : (
            <p
              className={`flex text-tiny desktop:text-base text-blue-600 items-center ${
                isSelected && "font-bold"
              }`}
            >
              {/* {isSelected && (
                <span className="mr-1">
                  <RiArrowRightSLine />
                </span>
              )} */}
              <span className="">{item.data.name}</span>
            </p>
          )}
        </div>
      </NavLink>
    );
  };

  let location = useLocation();
  const pageRoute = location.pathname.split("/")[1];


  useEffect(() => {
    const initializeSidebar = () => {
      switch (pageRoute) {
        case "support": {
          setHeaderIcon(<RiBookMarkFill />);
          setHeaderText("Support");
          setSectionNavTreeData(faqSectionNavTree);
          break;
        }
        case "settings": {
          setHeaderIcon(<RiSettings4Fill />);
          setHeaderText("Settings");
          setSectionNavTreeData(settingsSectionNavTree);
          break;
        }
        case "offers": {
          setHeaderIcon(<RiAwardFill />);
          setHeaderText("Offers");
          setSectionNavTreeData(campaignSectionNavTree);
          break;
        }
        case "ofco-ad": {
          setHeaderIcon(<RiAwardFill />);
          setHeaderText("Offers");
          setSectionNavTreeData(adSectionNavTree);
          break;
        }
        case "suco-ad": {
          setHeaderIcon(<RiBookMarkFill />);
          setHeaderText("Support");
          setSectionNavTreeData(adSectionNavTree);
          break;
        }
        case "cart": {
          setHeaderIcon(<RiShoppingCartFill />);
          setHeaderText("Cart");
          setSectionNavTreeData(cartSectionNavTree);
          break;
        }
        default: {
          setHeaderIcon(<RiShoppingCartFill />);
          setHeaderText("Cart");
          setSectionNavTreeData(cartSectionNavTree);
          break;
        }
      }
    };
    initializeSidebar();
  }, [pageRoute]);

  return (
    <>
      <div className="flex items-center h-20 desktop:h-24 flex-none justify-center desktop:justify-start desktop:px-8 border-b ">
        <div className="text-xl desktop:text-3xl text-blue-600">
          {headerIcon}
        </div>
        <h1 className="text-xl ml-1 desktop:text-2xl font-extrabold text-blue-600">
          {headerText}
        </h1>
      </div>
      <div className="px-2 desktop:px-4 mt-5 grow">
        <Tree
          tree={sectionNavTreeData}
          offsetPerLevel={10}
          renderItem={({ item, provided }) => {
            return (
              <div
                className="cursor-pointer"
                ref={provided.innerRef}
                {...provided.draggableProps}
              >
                <SectionLink item={item} />
              </div>
            );
          }}
        />
      </div>
      <div>
        {!workspace.data.installedApps.find((item) => item.appid === 2) && (
          <div className="px-1 py-2 desktop:p-3 border rounded-lg m-1 desktop:m-2 bg-white bg-opacity-50 shadow-lg text-center">
            <span className="text-2xl text-pink-600 desktop:flex justify-center animate-bounce hidden">
              <RiAwardFill />
            </span>
            <h3 className="text-sm desktop:text-base font-bold text-pink-600 mb-1 desktop:mb-2">
              All new Offers Corner
            </h3>
            <p className="text-xs">
              Get more traffic to your store with refer-a-friend campaign with a
              viral twist.
            </p>
            <div className="flex justify-center mt-1 desktop:mt-3">
              <button
                onClick={() => {
                  window
                    .open(
                      "https://apps.shopify.com/offerscorner?utm_source=suco-app&utm_medium=suco-section-nav-banner&utm_campaign=ofco-launch-banner",
                      "_blank"
                    )
                    .focus();
                }}
                className="p-2 desktop:px-3 desktop:py-2 focus:outline-none cursor-pointer rounded-lg bg-pink-600 text-white font-bold text-xs desktop:text-sm flex items-center"
              >
                <span className="text-lg mr-1">
                  <RiArrowRightLine />
                </span>
                <span className="z-20">Get it for free</span>
              </button>
            </div>
            <div className="flex justify-center mt-1">
              <button
                onClick={() => {
                  window
                    .open("https://calendly.com/usecorner/30min", "_blank")
                    .focus();
                }}
                className="p-2 desktop:px-3 desktop:py-2 text-xs desktop:text-sm  focus:outline-none cursor-pointer text-pink-600  font-bold  flex items-center"
              >
                <span className="z-20">Schedule a demo</span>
              </button>
            </div>
          </div>
        )}
        <div>
          <span className="text-xs justify-center flex items-center  text-black opacity-30 mb-3">
            <RiStore2Fill className="mr-2" /> {workspace.data.storeUrl}
          </span>
        </div>
      </div>
    </>
  );
};

export default SectionNav;
