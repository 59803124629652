import React from "react";
import { helper } from "showdown";

const EmailSettings = (props) => {
  return (
    <div className="sticky top-0 -mt-52  scale-90 top-0 mx-3 max-w-3xl grow ">
      <div className="bg-white my-3 p-3 border rounded-lg">
        <small className="text-xs font-bold text-blue-600">SUBJECT</small>
        <p>{props.subject}</p>
      </div>
      <div className="bg-white">
        <div className="flex justify-center py-8 px-2">
          <img
            style={{ maxWidth: "168.2px", width: "29%" }}
            src={props.logoUrl}
            alt="s"
          />
        </div>
        {props.shopName && (
          <div className="text-center mb-4">
            <p className="text-xl font-bold">{props.shopName}</p>
          </div>
        )}
        <div style={{ backgroundColor: props.themeColor }} className="py-8 ">
          <div className="flex justify-center text-center">
            <img
              className="w-20"
              src={`https://assets.cornercart.io/coca-v2/images/email-icons/confetti-${props.fgColor}.png`}
              alt=""
            />
          </div>
          <h1
            style={{ color: props.fgColor }}
            className="text-center text-3xl font-bold text-white mt-3"
          >
            {props.primaryTitle}
          </h1>
        </div>
        <div className="p-5">
          <p className="text-xl mb-3">{props.greeting}</p>
          <p className="text-xl">{props.content}</p>
          <div className="text-xl text-center mt-10">
            <p>{props.couponTitle} 10%</p>

            <h3 className="font-bold text-2xl"> SIDM2319SK</h3>
            {props.cta && (
              <div className="flex justify-center mt-10">
                <p
                  style={{
                    backgroundColor: props.themeColor,
                    color: props.fgColor,
                  }}
                  className="mt-4 px-14 py-4 text-xl font-bold"
                >
                  {props.cta}
                </p>
              </div>
            )}
            <p className="text-gray-500 text-base mt-5">{props.subCta}</p>
          </div>
        </div>
        <div
          style={{ backgroundColor: props.themeColor }}
          className=" py-4 mt-8 w-full"
        />
      </div>
    </div>
  );
};

export default EmailSettings;
