import React, { useState } from "react";
import { useQuery, useMutation, useQueryCache } from "react-query";
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import {
  updateViralReferral,
  updateCampaign,
  deleteCampaign,
  queryViralReferral,
} from "../../../../api/offers";
import InlineEdit from "@atlaskit/inline-edit";
import PageViewContainer from "../../../../components/structure/PageViewContainer";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import Select from "@atlaskit/select";
import { Formik } from "formik";
import {
  RiCheckboxBlankCircleFill,
  RiArrowDropDownLine,
  RiDeleteBin2Fill,
  RiGift2Fill,
  RiHandCoinFill,
  RiListSettingsFill,
  RiMailSendFill,
  RiWindowFill,
  RiSpeedMiniFill,
} from "react-icons/ri";
import GiftOffer from "./GiftOffer";
import ThankyouPage from "./ThankyouPage/index";
import Rewards from "./Rewards";
import Settings from "./Settings";
import EmailSettings from "./EmailSettings";
import { useGraphQl } from "../../../../GraphqlClient";
import toaster from "toasted-notes";
import Button from "../../../../components/Button";

const CustomAccordionEdit = (historyProps) => {
  const [isDeleteWarningOpen, setIsDeleteWarningOpen] = useState(false);
  const [currentTab, setCurrentTab] = useState("thankyou-page");

  const graphQL = useGraphQl();
  const queryCache = useQueryCache();

  const updateViralReferrals = async (input) => {
    await graphQL(updateViralReferral, {
      campaignId: { campaignid: input.campaignid },
      viralReferralInput: input.values,
    });
  };
  const updateCampaigns = async (input) => {
    await graphQL(updateCampaign, {
      campaignId: { id: input.id },
      campaignInput: input.values,
    });
  };

  const { data } = useQuery(
    `queryViralReferral${historyProps.match.params.id}`,
    () =>
      graphQL(queryViralReferral, {
        viralReferralId: historyProps.match.params.id,
      })
  );
  const [updateViralReferralMutation] = useMutation(updateViralReferrals, {
    onSuccess: () => {
      queryCache.invalidateQueries(
        `queryViralReferral${historyProps.match.params.id}`
      );
      toaster.notify("Campaign Updated", {
        duration: 2000,
      });
    },
  });
  const [updatCampaignMutation] = useMutation(updateCampaigns);

  const handleSubmision = (value) => {
    updatCampaignMutation({
      id: data.viral_referrals[0].campaignid,
      values: value.campaignPriority,
    });
    updateViralReferralMutation({
      campaignid:
        data && data.viral_referrals[0] && data.viral_referrals[0].campaignid,
      values: value.viralReferralValues,
    });
  };
  const deleteCampaigns = async () => {
    await graphQL(deleteCampaign, {
      campaignId: historyProps.match.params.id,
    });
  };
  const [deleteCampaignMutation] = useMutation(deleteCampaigns, {
    onSuccess: () => {
      toaster.notify("Viral referral campaign deleted", {
        duration: 2000,
      });
      historyProps.history.push(`/offers/referral/campaigns/`);
    },
  });

  const campaignStatus = [
    {
      label: (
        <div className="flex items-center">
          <RiCheckboxBlankCircleFill className="flex-none text-green-400 mr-2 text-lg" />{" "}
          Active{" "}
        </div>
      ),
      value: "active",
    },
    {
      label: (
        <div className="flex items-center">
          <RiCheckboxBlankCircleFill className="flex-none text-amber-400 mr-2 text-lg" />{" "}
          Paused{" "}
        </div>
      ),
      value: "paused",
    },
  ];

  const renderTabContent = (formikProps) => {
    switch (currentTab) {
      case "thankyou-page":
        return (
          <ThankyouPage
            {...formikProps}
            data={data.viral_referrals[0]}
            nextStepTrigger={() => setCurrentTab("rewards")}
            updateData={() => {
              queryCache.invalidateQueries(
                `queryViralReferral${historyProps.match.params.id}`
              );
            }}
          />
        );

      case "rewards":
        return (
          <Rewards
            {...formikProps}
            data={data.viral_referrals[0]}
            updateData={() => {
              queryCache.invalidateQueries(
                `queryViralReferral${historyProps.match.params.id}`
              );
            }}
          />
        );

      case "gift-offer":
        return (
          <GiftOffer
            {...formikProps}
            data={data.viral_referrals[0]}
            updateData={() => {
              queryCache.invalidateQueries(
                `queryViralReferral${historyProps.match.params.id}`
              );
            }}
          />
        );
      case "campaign-settings":
        return (
          <Settings
            {...formikProps}
            data={data.viral_referrals[0]}
            updateData={() => {
              queryCache.invalidateQueries(
                `queryViralReferral${historyProps.match.params.id}`
              );
            }}
          />
        );

      case "email-settings":
        return (
          <EmailSettings
            {...formikProps}
            data={data.viral_referrals[0]}
            updateData={() => {
              queryCache.invalidateQueries(
                `queryViralReferral${historyProps.match.params.id}`
              );
            }}
          />
        );
    }
  };

  if (data && data.viral_referrals[0]) {
    return (
      <PageViewContainer
        title="Viral Referrals"
        subTitle="Campaigns"
        action={
          <div className="flex items-center">
            <div className="mt-3 mr-5 border px-2 py-1 text-xs text-gray-500 rounded-lg">
              <span className="mobile:hidden laptop:inline"> Campaign ID:</span>
              <span className="font-bold">
                {data.viral_referrals[0].campaignid}
              </span>
            </div>
            {/* <Button
              className="mt-3 mr-5"
              icon={<RiDeleteBin2Fill />}
              type="danger-ghost"
              onClick={() => setIsDeleteWarningOpen(true)}
            >
              <span className="laptop:block mobile:hidden">
                Delete Campaign
              </span>
            </Button> */}
            <div className="max-w-md ">
              <InlineEdit
                defaultValue={data.viral_referrals[0].status}
                hideActionButtons
                editView={() => (
                  <div className="w-40">
                    <Select
                      isSearchable={false}
                      options={campaignStatus}
                      onChange={(onChangeEvent) => {
                        updateViralReferralMutation({
                          campaignid:
                            data &&
                            data.viral_referrals[0] &&
                            data.viral_referrals[0].campaignid,
                          values: { status: onChangeEvent.value },
                        });
                      }}
                      autoFocus
                      openMenuOnFocus
                    />
                  </div>
                )}
                readView={() => {
                  if (data.viral_referrals[0].status === "active") {
                    return (
                      <div className="flex items-center py-3 px-4 rounded-md bg-gray-200 cursor-pointer ">
                        <RiCheckboxBlankCircleFill className="flex-none text-green-400 mr-2 text-lg" />{" "}
                        Active{" "}
                        <RiArrowDropDownLine className="flex-none ml-2 text-lg" />{" "}
                      </div>
                    );
                  } else if (data.viral_referrals[0].status === "paused") {
                    return (
                      <div className="flex items-center py-3 px-4 rounded-md bg-gray-200 cursor-pointer ">
                        <RiCheckboxBlankCircleFill className="flex-none text-amber-400 mr-2 text-lg" />{" "}
                        Paused{" "}
                        <RiArrowDropDownLine className="flex-none ml-2 text-lg" />{" "}
                      </div>
                    );
                  } else {
                    <div className="flex items-center py-3 px-4 rounded-md bg-gray-200 cursor-pointer ">
                      <RiCheckboxBlankCircleFill className="flex-none text-red-500 mr-2 text-lg" />{" "}
                      Expired{" "}
                      <RiArrowDropDownLine className="flex-none ml-2 text-lg" />{" "}
                    </div>;
                  }
                }}
                onConfirm={(e) => console.log("is_published", e)}
              />
            </div>
          </div>
        }
      >
        <div className="h-full flex flex-col">
          <div className="border-b grow-0 ">
            <div className="flex desktop:px-8 justify-center items-center">
              <button
                onClick={() => setCurrentTab("thankyou-page")}
                className={`mx-3 desktop:p-5 laptop:p-4 p-1 pb-2 flex text-sm desktop:text-base items-center focus:outline-none ${
                  currentTab === "thankyou-page" &&
                  "border-b border-blue-600 text-blue-600 font-bold bg-gradient-to-b from-white to-blue-100 "
                }`}
              >
                <RiWindowFill className="mr-2 laptop:block hidden" />{" "}
                <span>Thank you Page</span>
              </button>
              <div className="laptop:block hidden text-gray-300">
                <RiSpeedMiniFill />
              </div>
              <button
                onClick={() => setCurrentTab("rewards")}
                className={`mx-3 desktop:p-5 laptop:p-4 p-1 pb-2 flex text-sm desktop:text-base items-center focus:outline-none ${
                  currentTab === "rewards" &&
                  "border-b border-blue-600 text-blue-600 font-bold bg-gradient-to-b from-white to-blue-100"
                }`}
              >
                <RiHandCoinFill className="mr-2 laptop:block hidden" />{" "}
                <span>Reward for Referrer</span>
              </button>
              <div className="laptop:block hidden text-gray-300">
                <RiSpeedMiniFill />
              </div>

              <button
                onClick={() => setCurrentTab("gift-offer")}
                className={`mx-3 desktop:p-5 laptop:p-4 p-1 pb-2 flex text-sm desktop:text-base items-center focus:outline-none ${
                  currentTab === "gift-offer" &&
                  "border-b border-blue-600 text-blue-600 font-bold bg-gradient-to-b from-white to-blue-100"
                }`}
              >
                <RiGift2Fill className="mr-2 laptop:block hidden" />{" "}
                <span>Gift Coupon for Friends</span>
              </button>
              <div className="laptop:block hidden text-gray-300">
                <RiSpeedMiniFill />
              </div>
              <button
                onClick={() => setCurrentTab("campaign-settings")}
                className={`mx-3 desktop:p-5 laptop:p-4 p-1 pb-2 flex text-sm desktop:text-base items-center focus:outline-none ${
                  currentTab === "campaign-settings" &&
                  "border-b border-blue-600 text-blue-600 font-bold bg-gradient-to-b from-white to-blue-100"
                }`}
              >
                <RiListSettingsFill className="mr-2 laptop:block hidden" />{" "}
                <span>Campaign Settings</span>
              </button>

              <div className="laptop:block hidden text-gray-300">
                <RiSpeedMiniFill />
              </div>
              <button
                onClick={() => setCurrentTab("email-settings")}
                className={`mx-3 desktop:p-5 laptop:p-4 p-1 pb-2 flex text-sm desktop:text-base items-center focus:outline-none ${
                  currentTab === "email-settings" &&
                  "border-b border-blue-600 text-blue-600 font-bold bg-gradient-to-b from-white to-blue-100"
                }`}
              >
                <RiMailSendFill className="mr-2 laptop:block hidden" />{" "}
                <span>Email Settings</span>
              </button>
            </div>
          </div>
          <div className="grow-1  h-full overflow-y-auto ">
            <Formik
              enableReinitialize
              initialValues={data.viral_referrals[0]}
              onSubmit={(values) => {
                const updatedPriorities = {
                  priority: values.campaign.priority,
                };
                const updatedViralReferralValues = {
                  name: values.name,
                  blocked_collections: values.blocked_collections,
                  starts_at: values.starts_at,
                  expires_at: values.expires_at,
                  gift_config: values.gift_config,
                  reward_stage: values.reward_stage.sort(
                    (a, b) => a.rewardTarget - b.rewardTarget
                  ),
                  settings_config: values.settings_config,
                };
                handleSubmision({
                  viralReferralValues: updatedViralReferralValues,
                  campaignPriority: updatedPriorities,
                });
              }}
            >
              {(formikProps) => {
                return (
                  <div className="w-full h-full">
                    {formikProps.dirty && (
                      <div className="z-50 sticky top-0 flex justify-between border-b bg-amber-100 items-center py-3 desktop:px-8 px-4 ">
                        <p>You've unsaved changes</p>
                        <div className="flex items-center">
                          <Button
                            onClick={() => {
                              formikProps.resetForm();
                            }}
                            type="link"
                          >
                            Discard Changes
                          </Button>
                          <Button
                            onClick={() => {
                              formikProps.submitForm();
                            }}
                            type="primary"
                          >
                            Save Changes
                          </Button>
                        </div>
                      </div>
                    )}
                    {renderTabContent(formikProps)}
                  </div>
                );
              }}
            </Formik>
          </div>
        </div>
        <ModalTransition>
          {isDeleteWarningOpen && (
            <Modal
              actions={[
                {
                  text: "Delete it",
                  onClick: () => {
                    deleteCampaignMutation();
                    setIsDeleteWarningOpen(false);
                  },
                },
                {
                  text: "Cancel",
                  onClick: () => {
                    setIsDeleteWarningOpen(false);
                  },
                },
              ]}
              onClose={() => {
                setIsDeleteWarningOpen(false);
              }}
              heading="Are you sure?"
              appearance="danger"
            >
              <p>
                Are you sure you want to delete this viral referral campaign.
              </p>
            </Modal>
          )}
        </ModalTransition>
      </PageViewContainer>
    );
  } else return <LoadingSpinner />;
};

export default CustomAccordionEdit;
