import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Formik } from "formik";
import Button from "../../../../components/Button";
import TabRadioButton from "../../../../components/TabRadioButton";
import Textfield from "@atlaskit/textfield";
import CustomiserUnit from "../../../../components/CustomiserUnit";
import { useWorkspace } from "../../../../WorkspaceContext";
import ProfitCalculator from "../../../offers/Referral/SimpleReferralEdit/ProfitCalculator";

export default function Gift({
  giftStage,
  setGiftStage,
  rewardType,
  maxDiscountValue,
  simpleRewardConfig,
  goFront,
  goBack,
}) {
  let history = useHistory();
  const workspace = useWorkspace();
  const [isProfitCalcOpened, setProfitCalcOpened] = useState(false);
  return (
    <div className="">
      <div className="max-w-5xl mb-8">
        <div className="flex ">
          <img
            className="grow-0"
            width="160px"
            src="https://assets.cornercart.io/coca-v2/images/onboard/ofco/onboard-2.png"
          />
        </div>
        <h1 className="text-5xl font-light text-blue-900">Gift Coupon</h1>
        <p>
          A gift coupon is the discount code shared by the referrer with their
          friends.
        </p>
      </div>
      <Formik
        enableReinitialize
        initialValues={giftStage}
        onSubmit={(values) => {
          setGiftStage(values);
          goFront();
        }}
      >
        {(props) => {
          return (
            <>
              <div className="bg-white rounded-lg shadow-lg  py-3 px-8 laptop:flex items-center">
                <div>
                  <CustomiserUnit
                    title="Discount type"
                    description="Select the type of discount for the gift coupon"
                  >
                    <TabRadioButton
                      defaultValue={props.values.discountType}
                      options={[
                        { value: "percentage", label: "Percentage" },
                        { value: "fixedAmount", label: "Fixed Amount" },
                      ]}
                      onChange={(e) => {
                        props.setFieldValue("discountType", e);
                      }}
                    />
                  </CustomiserUnit>

                  <CustomiserUnit
                    title="Discount Value"
                    description="Choose the value for the gift coupon"
                  >
                    <div className="flex items-center">
                      <div className="w-20">
                        <Textfield
                          defaultValue={props.values.discountValue * -1}
                          onChange={(e) => {
                            props.setFieldValue(
                              "discountValue",
                              parseInt(e.target.value, 10) * -1
                            );
                          }}
                          min={1}
                          type="number"
                          name="basic"
                        />
                      </div>
                      <div className="border-2 border-gray-200 bg-gray-200 rounded-r-md p-2 font-bold">
                        {props.values.discountType === "percentage"
                          ? "%"
                          : workspace.data.storeCurrency}
                      </div>
                    </div>
                  </CustomiserUnit>
                </div>
                <div className="ml-8">
                  <div>
                    {isProfitCalcOpened ? (
                      <div>
                        <div className="flex justify-end">
                          <button
                            onClick={() => {
                              setProfitCalcOpened(false);
                            }}
                            className="px-1 py-1 rounded-lg border border-gray-600 hover:border-primary-600 text-xs "
                          >
                            Back to FAQ
                          </button>
                        </div>
                        <ProfitCalculator
                          reward_config={simpleRewardConfig}
                          min_order_value={0}
                          reward_type={rewardType}
                          gift_config={props.values}
                          max_discount_value={maxDiscountValue}
                        />
                      </div>
                    ) : (
                      <div>
                        <div className="border rounded-lg py-1 px-3 max-w-3xl text-sm desktop:text-base ">
                          <div className="py-3 border-b">
                            <p className="font-bold">What is a Gift URL?</p>
                            <p className="text-gray-700 mt-2 ">
                              It is a URL to a discount code given to a customer
                              on thank you page. This customer is supposed to
                              share this URL with their friends.
                            </p>
                          </div>
                          <div className="py-3 border-b">
                            <p className="font-bold">
                              Can the customer itself get the discount code from
                              this URL?
                            </p>
                            <p className="text-gray-700 mt-2 ">
                              By default our app doesnt lets the customer to
                              open the URL from the same device they used to get
                              this URL (blocked using cookies)
                            </p>
                          </div>
                          <div className="py-3 border-bottom">
                            <p className="font-bold">
                              How much ROI can I expect from this campaign?
                            </p>
                            <button
                              onClick={() => {
                                setProfitCalcOpened(true);
                              }}
                              className="px-3 py-1 mt-2 rounded-lg border border-gray-600 hover:border-primary-600 "
                            >
                              Open Profit Calculator
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="flex justify-end items-center mt-12">
                <Button
                  className="mr-8"
                  type="link"
                  onClick={() => {
                    goBack();
                  }}
                >
                  Go back
                </Button>
                <button
                  onClick={() => props.submitForm()}
                  className="text-center py-6 px-24 bg-blue-600 text-white font-bold rounded-lg hover:bg-blue-900 transition-all"
                >
                  Continue
                </button>
              </div>
            </>
          );
        }}
      </Formik>
    </div>
  );
}
