import React from 'react'
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch
} from 'react-router-dom'
import Account from "./Account"
import Language from "./Language"
import Integration from "./Integration"


export default function SettingsRouter(){ 
  let { path } = useRouteMatch();


  return (
    <Switch>
      <Route exact path={`${path}`}>
        <Redirect to="/settings/account/" />
      </Route>
      <Route path={`${path}/account`} component={Account} />
      <Route path={`${path}/language`} component={Language} />
      <Route path={`${path}/integration`} component={Integration} />
    </Switch>
  );
}
