import React from "react";
import { AuthenticationProvider } from "./AuthenticationContext";
import { WorkspaceProvider } from "./WorkspaceContext";
import { GraphqlClientProvider } from "./GraphqlClient";
import { BrowserRouter as Router } from "react-router-dom";

function AppContextProviders({ children }) {
  return (
    <AuthenticationProvider>
      <GraphqlClientProvider>
        <WorkspaceProvider>{children}</WorkspaceProvider>
      </GraphqlClientProvider>
    </AuthenticationProvider>
  );
}

export default AppContextProviders;
