import React, { useEffect } from "react";
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom";
import { useWorkspace } from "../../WorkspaceContext";
import Widgets from "./Widget/WidgetRouter"

export default function CartRouter() {
  let { path } = useRouteMatch();
  const workspace = useWorkspace();

  useEffect(() => {
    if (workspace && workspace.data.currentAppID !== 3)
      workspace.data.changeCurrentAppID(3);
  }, [workspace]);

  return (
    <>
      <Switch>
        <Route path={`${path}/widgets`} component={Widgets} />
        <Route exact path={path}>
          <Redirect to={`/cart/widgets/corner-cart`} />
        </Route>
      </Switch>
    </>
  );
}
