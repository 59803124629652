import React from "react";

const FaqPreview = (props) => {
  const bannerStyleValues =
    props.bannerImage.length > 0
      ? {
          background: props.bgEndColor,
          backgroundImage: `url(${props.bannerImage})`,
          backgroundSize: "cover",
        }
      : {
          background: `linear-gradient(90deg, ${props.bgStartColor} 0%, ${props.bgEndColor}  100%)`,
        };
  return (
    <div className="w-full bg-white shadow-lg h-2/3 m-8 rounded-lg overflow-hidden flex flex-col ">
      <div className="w-full h-8 bg-blue-100 flex">
        <div className="flex h-full items-center">
          <div className="w-2 h-2 mr-2 ml-2 rounded-full bg-red-500" />
          <div className="w-2 h-2 mr-2 rounded-full bg-amber-400" />
          <div className="w-2 h-2 mr-2 rounded-full bg-green-400" />
        </div>
        <div className="flex items-end ml-3">
          <div className="bg-white w-40 h-4/5 rounded-t-md" />
        </div>
      </div>
      <div className="flex border-b p-3 shadow-sm ">
        <div className="w-5 h-5 mr-3 rounded-full border" />
        <div className="w-5 h-5 mr-3 rounded-full border" />
        <div className="grow h-5 rounded-full border" />
      </div>
      <div className="grow">
        <div className="flex py-5 items-center border-b">
          <div className="h-5 w-5 bg-gray-100 rounded-full mx-12" />
          <div className="h-2 grow bg-gray-100 rounded-full mx-4" />
          <div className="h-2 grow  bg-gray-100 rounded-full mx-4" />
          <div className="h-2 grow  bg-gray-100 rounded-full mx-4" />
          <div className="h-2 grow  bg-gray-100 rounded-full mx-4" />
        </div>
        <div style={bannerStyleValues} className="py-14 flex justify-center ">
          <div className="text-center w-1/3">
            <p className={`text-lg font-bold text-${props.bannerTextColor}`}>
              {" "}
              {props.bannerText}
            </p>
            {props.searchBox && (
              <div className="h-5 rounded shadow border mt-2  bg-white text-xs text-gray-400 text-left px-3" >Search</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqPreview;
