import React, { useState, useEffect } from "react";
import { useMutation } from "react-query";
import Select from "@atlaskit/select";
import Tooltip from "@atlaskit/tooltip";
import toaster from "toasted-notes";
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import { Link } from "react-router-dom";
import {
  RiArrowUpDownLine,
  RiShareForwardBoxLine,
  RiCheckboxBlankCircleFill,
  RiDeleteBin2Line,
  RiArrowDownSLine,
  RiFileTextLine,
  RiFileLockLine,
  RiQuestionLine,
  RiStarLine,
  RiStarFill,
  RiUpload2Fill,
  RiUploadFill,
  RiFileDownloadLine,
  RiAlertLine,
  RiFileWarningFill
} from "react-icons/ri";
import Button from "./Button";
import Dropdown from "./Dropdown";
import List from "./List";
import { useGraphQl } from "../GraphqlClient";
import {
  bulkUpdateAssistantArticle,
  bulkDeleteAssistantArticle,
  updateAssistantArticle,
} from "../api/support";
import { useAuth } from "../AuthenticationContext";
import { useWorkspace } from "../WorkspaceContext";
import SucoPricingModal from "../components/SucoPricingModal";

export default function ArticleList({
  data,
  onUpdate,
  emptyState,
  draggable,
  showImportExport,
  hideLimitWarning
}) {
  const authState = useAuth();
  const workspace= useWorkspace();
  const [selectedItems, setSelectedItems] = useState([]);
  const [articleList, setArticleList] = useState([]);
  const [activeSortOption, setActiveSortOption] = useState("all");
  const graphQL = useGraphQl();
  const [isArticleDeleteWarningOpen, setIsArticleDeleteWarningOpen] = useState(
    false
  );
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [isBillingModalOpen, setIsBillingModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);

  const updateArticle = async (input) => {
    await graphQL(updateAssistantArticle, {
      articleId: { id: input.id },
      articleInput: input.values,
    });
  };

  const bulkDeleteArticle = async (input) => {
    await graphQL(bulkDeleteAssistantArticle, {
      idArray: input.id,
    });
  };

  const bulkUpdateArticles = async (input) => {
    let valueToUpdate;
    switch (input.updateType) {
      case "addToCategory":
        valueToUpdate = { categoryid: data.assistant_categories[0].id };
        break;
      case "uncategorize":
        valueToUpdate = { categoryid: null };
        break;
      case "publish":
        valueToUpdate = { is_published: true };
        break;
      case "draft":
        valueToUpdate = { is_published: false };
        break;
      case "feature":
        valueToUpdate = { is_featured: true };
        break;
      case "unfeature":
        valueToUpdate = { is_featured: false };
        break;
    }
    await graphQL(bulkUpdateAssistantArticle, {
      idArray: input.id,
      newCategory: valueToUpdate,
    });
  };

  const [updateArticleMutation] = useMutation(updateArticle, {
    onSuccess: () => {
      onUpdate();
    },
  });

  const [bulkUpdateArticleMutation] = useMutation(bulkUpdateArticles, {
    onSuccess: () => {
      toaster.notify("Updated successfully", {
        duration: 2000,
      });
      onUpdate();
    },
  });

  const [bulkDeleteArticleMutation] = useMutation(bulkDeleteArticle, {
    onSuccess: () => {
      toaster.notify("Deletion Complete", {
        duration: 2000,
      });
      onUpdate();
    },
  });
  const handleArticledeletion = () => {
    bulkDeleteArticleMutation({
      id: selectedItems,
    });
  };
  const handleBulkAction = (action, idArray) => {
    bulkUpdateArticleMutation({
      id: idArray,
      updateType: action,
    });
  };

  const getSortedData = () => {
    switch (activeSortOption) {
      case "all":
        return articleList;
        break;
      case "published":
        return articleList.filter((article) => article.is_published === true);
        break;
      case "draft":
        return articleList.filter((article) => article.is_published === false);
        break;
      case "featured":
        return articleList.filter((article) => article.is_featured === true);
        break;
      case "nonfeatured":
        return articleList.filter((article) => article.is_featured === false);
        break;
    }
  };
  const sortOptions = [
    { label: "All", value: "all" },
    { label: "Published Articles", value: "published" },
    { label: "Drafts", value: "draft" },
    { label: "Featured", value: "featured" },
    { label: "Non Featured", value: "nonfeatured" },
  ];

  const getCategoryName = (categoryId) => {
    const category = data.assistant_categories.find(
      (category) => category.id === categoryId
    );
    if (category)
      return (
        <>
          <img  className="mr-1 desktop:mr-2 w-5 desktop:w-6" src={category.icon_url} alt="" />{" "}
          {category.name}
        </>
      );
  };

  // When user drags an article, the whole new articleList array which is aranged in the new order is passed on to this fuction. Here we update each artcle's position_rank with its array index
  const handleDrag = (updatedList) => {
    updatedList.map((item, index) => {
      updateArticleMutation({
        id: item.id,
        values: {
          position_rank: index,
        },
      });
    });
  };
  const handleAllExport = () => {
    // console.log(authState.token)
    window.open(
      `${process.env.REACT_APP_STARLINK_URL}/faq/export?filter=E_ALL&articleIds=[]&x-starlink-token=${authState.token}`,
      "_blank"
    );
  };
  const handleSelectedExport = () => {
    window.open(
      `${process.env.REACT_APP_STARLINK_URL}/faq/export?filter=E_ARTICLES&articleIds=[${selectedItems}]&x-starlink-token=${authState.token}`,
      "_blank"
    );
  };

  const handleImport = () => {
    const formData = new FormData();

    formData.append("file", selectedFile);

    if (selectedFile.size > 25000000) {
      toaster.notify(
        "File Size is too high. Contact support to upload this large file",
        {
          duration: 8000,
        }
      );
    } else {
      fetch(`${process.env.REACT_APP_STARLINK_URL}/faq/import`, {
        method: "POST",
        headers: {
          "x-starlink-token": `${authState.token}`,
          enctype: "multipart/form-data",
        },
        body: formData,
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.upload) 
         { toaster.notify(
            "Successfully Imported",
            {
              duration: 8000,
            }
          );
          setIsImportModalOpen(false);
          onUpdate();}

          if(result.message){
            toaster.notify(
              "Import failed",
              {
                duration: 8000,
              }
            );
          }
        })
        .catch((error) => {
          toaster.notify("Import Failed", {
            duration: 2000,
          });
          console.log("Error responce", error);
        });
    }
  };

  // each time anything is updated this functio makes sure the updated article list is sorted.
  const initialiseList = () => {
    const arrangedArticleList = data.assistant_articles.sort(
      (a, b) => a.position_rank - b.position_rank
    );
    setArticleList(arrangedArticleList);
  };

  useEffect(() => {
    initialiseList();
  }, [data]);

  return (
    <>
      <div className="flex  py-1 px-2 desktop:py-2 desktop:px-4 flex-wrap">
        <div className="flex items-center">
          <p className="mr-3 desktop:block hidden  ">View</p>
          <Select
            value={sortOptions.find(
              (option) => option.value === activeSortOption
            )}
            isSearchable={false}
            onChange={(e) => setActiveSortOption(e.value)}
            className="w-48 mr-2"
            options={sortOptions}
          />
        </div>
        <div className="flex items-center ">
          <Dropdown
            alignment="right"
            type="link"
            icon={<RiArrowDownSLine />}
            triggerText="Bulk Actions"
            disabled={selectedItems.length === 0}
          >
            <Button
              onClick={() => {
                handleBulkAction("uncategorize", selectedItems);
              }}
              type="link"
              className="mr-2"
              icon={<RiShareForwardBoxLine />}
            >
              Remove from this category
            </Button>
            <Button
              onClick={() => {
                handleBulkAction("publish", selectedItems);
              }}
              type="link"
              className="mr-2"
              icon={<RiFileTextLine />}
            >
              Change status to published
            </Button>
            <Button
              onClick={() => {
                handleBulkAction("draft", selectedItems);
              }}
              type="link"
              className="mr-2"
              icon={<RiFileLockLine />}
            >
              Change status to draft
            </Button>
            <Button
              onClick={() => {
                handleBulkAction("feature", selectedItems);
              }}
              type="link"
              className="mr-2"
              icon={<RiStarFill />}
            >
              Feature articles
            </Button>
            <Button
              onClick={() => {
                handleBulkAction("unfeature", selectedItems);
              }}
              type="link"
              className="mr-2"
              icon={<RiStarLine />}
            >
              Unfeature articles
            </Button>
            <Button
              onClick={() => {
                setIsArticleDeleteWarningOpen(true);
              }}
              type="danger-link"
              className="mr-2"
              icon={<RiDeleteBin2Line />}
            >
              Delete selected items
            </Button>
          </Dropdown>
          {showImportExport && (
            <Dropdown
              alignment="right"
              type="link"
              icon={<RiArrowDownSLine />}
              triggerText="Import/Export"
            >
              <Button
                onClick={() => {
                  handleAllExport();
                }}
                type="link"
                className="mr-2"
                icon={<RiUpload2Fill />}
              >
                Export all articles
              </Button>
              <Button
                onClick={() => {
                  handleSelectedExport();
                }}
                type="link"
                className="mr-2"
                disabled={selectedItems.length === 0}
                icon={<RiUploadFill />}
              >
                Export selected articles
              </Button>
              <Button
                onClick={() => {
                  setIsImportModalOpen(true);
                }}
                type="link"
                className="mr-2"
                icon={<RiFileDownloadLine />}
              >
                Import articles
              </Button>
            </Dropdown>
          )}
        </div>
        <div className="mt-3">
          {activeSortOption !== "all" && (
            <Button
              onClick={() => {
                setActiveSortOption("all");
              }}
              type="link"
              className="mr-2"
              icon={<RiArrowUpDownLine />}
            >
              Reorder Articles
            </Button>
          )}
        </div>
        <ModalTransition>
          {isArticleDeleteWarningOpen && (
            <Modal
              actions={[
                {
                  text: "Delete it",
                  onClick: () => {
                    handleArticledeletion();
                    setIsArticleDeleteWarningOpen(false);
                  },
                },
                {
                  text: "No, keep it",
                  onClick: () => {
                    setIsArticleDeleteWarningOpen(false);
                  },
                },
              ]}
              onClose={() => {
                setIsArticleDeleteWarningOpen(false);
              }}
              heading="You’re about to delete these selected articles"
              appearance="danger"
            >
              <p>
                We won't be able to undo this later, are you sure you want to
                conitnue with the deletion?
              </p>
            </Modal>
          )}
          {isImportModalOpen && (
            <Modal
              actions={[
                {
                  text: "Start Importing",
                  onClick: () => {
                    handleImport();
                  },
                  disabled: isFilePicked,
                },
                {
                  text: "Cancel",
                  onClick: () => {
                    setIsImportModalOpen(false);
                  },
                },
              ]}
              onClose={() => {
                setIsImportModalOpen(false);
              }}
              heading="Upload the export file"
            >
              <p>
                Choose the file containing your FAQ articles to start
                importing. You can upload only files generated by SupportCorner
                app (for e.g from another SupportCorner account). If you wish to
                import articles from another app or another file format, please
                contact us via chat and we can help you out.
              </p>
              <div className="text-center">
                <input
                  onChange={(event) => {
                    setSelectedFile(event.target.files[0]);
                    setIsFilePicked(true);
                  }}
                  className="laptop:py-2 laptop:px-4 mobile:py-2 mobile:px-2 items-center  rounded-md transition duration-200 ease-in-out cursor-pointer focus:outline-none focus:shadow-none border border-blue-600 text-blue-600 hover:text-blue-900 hover:border-blue-900 font-bold "
                  type="file"
                  id="myFile"
                  name="filename"
                />
                <div>
                  <small className="text-xs text-amber-600">
                    Max file size 25mb. To import larger files contact support
                  </small>
                </div>
              </div>
            </Modal>
          )}
        </ModalTransition>
      </div>

      {hideLimitWarning ? null : articleList.length >
          workspace.data?.feature_flag?.apps.supportcorner.articles_per_category && (
          <div className="flex items-center justify-center bg-amber-300 text-amber-900 py-2">
            <h2 className="mr-3">
              <RiFileWarningFill />
            </h2>
            <p>Exceeded maximum articles allowed in your plan.</p>
            <Button
              type="ghost"
              className="shrink-0 border-amber-900 mx-3"
              onClick={() => setIsBillingModalOpen(true)}
            >
              {" "}
              Upgrade Plan
            </Button>
            <SucoPricingModal
              title="You need a higher plan to access this feature"
              isOpen={isBillingModalOpen}
              onClose={() => setIsBillingModalOpen(false)}
            />
          </div>
        )}

      <div className="desktop:px-8 mobile:px-3  pt-4 ">
        <List
          draggable={draggable && activeSortOption === "all"}
          onDrag={handleDrag}
          items={getSortedData()}
          selectedItemsId={selectedItems}
          onSelectionChange={setSelectedItems}
          emptyState={emptyState}
          header={
            <div className="grid grid-cols-12 items-center justify-center ">
              <div className="laptop:col-span-6 mobile:col-span-12 px-3 desktop:px-7">
                <p className="font-bold text-gray-600 desktop:text-base text-tiny">Title</p>
              </div>
              <div className="col-span-3 laptop:block mobile:hidden">
                <p className="font-bold text-gray-600 desktop:text-base text-tiny">Status</p>
              </div>
              <div className="col-span-3 laptop:block mobile:hidden">
                <div className="font-bold text-gray-600">
                  <Tooltip content="Choose whether to show article in the widget's featured section">
                    <p className="font-bold text-gray-600 flex items-center desktop:text-base text-tiny">
                      Featured{" "}
                      <span className="cursor-pointer text-lg ml-4">
                        <RiQuestionLine />
                      </span>
                    </p>{" "}
                  </Tooltip>
                </div>
              </div>
            </div>
          }
          render={(item, index) => (
            <Link
              onClick={(e) => {
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();
              }}
              to={`/support/faq/articles/${item.id}/edit`}
            >
              <div className="grid grid-cols-12 items-center justify-center py-3 desktop:py-5">
                <div className="laptop:col-span-6 mobile:col-span-12 desktop:px-7 mobile:px-3">
                  <p className="font-bold text-blue-900 hover:text-blue-600 desktop:text-base text-tiny">
                    {item.title}
                    
                  </p>
                  <p className="text-gray-700 flex items-center text-tiny desktop:text-base">
                    {item.categoryid
                      ? getCategoryName(item.categoryid)
                      : "Not Categorised"}
                  </p>
                  {hideLimitWarning ? null : index + 1 >
                    workspace.data?.feature_flag?.apps.supportcorner
                      .articles_per_category && (
                    <div className="flex items-center mt-2 text-red-600 text-sm rounded-full px-3 py-1 border border-red-300 w-fit font-bold">
                      <RiAlertLine className="mr-1" />{" "}
                      <span>Exceeded Limit </span>
                    </div>
                  )}
                  <div className="laptop:hidden mobile:flex items-center mt-3">
                    <small className="text-xs">
                      {item.is_published ? (
                        <span className="flex items-center rounded-sm ">
                          <RiCheckboxBlankCircleFill className="flex-none text-green-400 mr-1 text-xs" />{" "}
                          Published{" "}
                        </span>
                      ) : (
                        <span className="flex items-center  rounded-s">
                          <RiCheckboxBlankCircleFill className="flex-none text-amber-400 mr-1 text-xs" />{" "}
                          Draft{" "}
                        </span>
                      )}
                    </small>
                    {item.is_featured && (
                      <small className="text-xs ml-2 px-3 border-l">
                        <span className="flex items-center rounded-sm ">
                          <RiStarFill className="text-amber-500 text-xs mr-1" />
                          Featured{" "}
                        </span>
                      </small>
                    )}
                  </div>
                </div>
                <div className="col-span-3 laptop:flex mobile:hidden ">
                  {item.is_published ? (
                    <span className="flex items-center rounded-sm py-4">
                      <RiCheckboxBlankCircleFill className="flex-none text-green-400 mr-2 text-lg" />{" "}
                      Published{" "}
                    </span>
                  ) : (
                    <span className="flex items-center  rounded-sm py-4">
                      <RiCheckboxBlankCircleFill className="flex-none text-amber-400 mr-2 text-lg" />{" "}
                      Draft{" "}
                    </span>
                  )}
                </div>
                <div className="col-span-3 laptop:flex mobile:hidden ">
                  {item.is_featured && (
                    <RiStarFill className="text-amber-500 text-xl" />
                  )}
                </div>
              </div>
            </Link>
          )}
        />
      </div>
    </>
  );
}
