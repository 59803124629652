import React, { useState } from "react";
import TextArea from "@atlaskit/textarea";
import CustomiserUnit from "../../../../../components/CustomiserUnit";
import TabRadioButton from "../../../../../components/TabRadioButton";
import ColorPicker from "../../../../../components/ColorPicker";
import Textfield from "@atlaskit/textfield";
import EmailPreview from "./EmailPreview";

const EmailSettings = (props) => {
  const convertRGB = (rgb) => `rgb(${rgb.r},${rgb.g},${rgb.b})`;
  const [emailType, setEmailType] = useState("rewardEmail");

  return (
    <div>
      <div className="w-full flex">
        <div className="">
          <div className="flex justify-center border-b ">
            <button
              onClick={() => setEmailType("rewardEmail")}
              className={`flex-1 text-center py-4 focus:outline-none ${
                emailType === "rewardEmail" &&
                "border-b-2 border-blue-600 text-blue-600 font-bold"
              } `}
            >
              Reward Email
            </button>
            {/* <button
              onClick={() => setEmailType("giftEmail")}
              className={`flex-1 text-center py-4 focus:outline-none ${
                emailType === "giftEmail" &&
                "border-b-2 border-blue-600 text-blue-600 font-bold"
              } `}
            >
              Gift Email
            </button> */}
          </div>
          {emailType === "rewardEmail" ? (
            <>
              <div
                key="1"
                className="max-w-xl py-4 border-b py-5 desktop:px-8 px-4"
              >
                <h3 className="font-bold desktop:mb-3 laptop:mb-1">
                  Reward Emails
                </h3>
                <p>
                  A reward email is sent to a referrer once a reward target is
                  unlocked. This email will contain the reward coupon which he
                  can redeem.
                </p>
              </div>
              <div className=" desktop:py-3 desktop:px-8 px-4">
                <CustomiserUnit
                key="emailSubject"

                  title="Email Subject"
                  description="Subject line for the email"
                >
                  <TextArea
                    value={
                      props.values.settings_config.emailConfig.subject
                    }
                    onChange={(e) => {
                      props.setFieldValue(
                        "settings_config.emailConfig.subject",
                        e.target.value
                      );
                    }}
                    name="basic"
                    minimumRows={1}
                  />
                </CustomiserUnit>

                <CustomiserUnit
                  title="Theme color"
                  description="Select the theme color of the email"
                >
                  <ColorPicker
                    color={props.values.settings_config.emailConfig.themeColor}
                    onChange={(color) => {
                      props.setFieldValue(
                        "settings_config.emailConfig.themeColor",
                        convertRGB(color.rgb)
                      );
                    }}
                  />
                </CustomiserUnit>
                <CustomiserUnit
                  title="Foreground color"
                  description="Select the foreground color"
                >
                  <TabRadioButton
                    defaultValue={
                      props.values.settings_config.emailConfig.fgColor
                    }
                    options={[
                      { value: "white", label: "Light" },
                      { value: "black", label: "Dark" },
                    ]}
                    onChange={(e) => {
                      props.setFieldValue(
                        "settings_config.emailConfig.fgColor",
                        e
                      );
                    }}
                  />
                </CustomiserUnit>

                <CustomiserUnit
                  title="Logo URL"
                  description="Image URL of your logo. This image will be showcased above the email"
                >
                  <Textfield
                    value={
                      props.values.settings_config.emailConfig.logoUrl
                    }
                    onChange={(e) => {
                      props.setFieldValue(
                        "settings_config.emailConfig.logoUrl",
                        e.target.value
                      );
                    }}
                    name="basic"
                  />
                </CustomiserUnit>

                {/* <CustomiserUnit title="Shop Name">
                  <Textfield
                    value={
                      props.values.settings_config.emailConfig.shopName
                    }
                    onChange={(e) => {
                      props.setFieldValue(
                        "settings_config.emailConfig.shopName",
                        e.target.value
                      );
                    }}
                    name="basic"
                  />
                </CustomiserUnit> */}

                <CustomiserUnit title="Title">
                  <Textfield
                    value={
                      props.values.settings_config.emailConfig.primaryTitle
                    }
                    onChange={(e) => {
                      props.setFieldValue(
                        "settings_config.emailConfig.primaryTitle",
                        e.target.value
                      );
                    }}
                    name="basic"
                  />
                </CustomiserUnit>

                <CustomiserUnit title="Greeting">
                  <Textfield
                    value={
                      props.values.settings_config.emailConfig.greeting
                    }
                    onChange={(e) => {
                      props.setFieldValue(
                        "settings_config.emailConfig.greeting",
                        e.target.value
                      );
                    }}
                    name="basic"
                  />
                </CustomiserUnit>

                <CustomiserUnit title="Main Content">
                  <TextArea
                    value={
                      props.values.settings_config.emailConfig.content
                    }
                    onChange={(e) => {
                      props.setFieldValue(
                        "settings_config.emailConfig.content",
                        e.target.value
                      );
                    }}
                    name="basic"
                    minimumRows={7}
                  />
                </CustomiserUnit>

                <CustomiserUnit
                  title="Coupon code label"
                  description="This text will be shown above the coupon code followed by the coupon value"
                >
                  <Textfield
                    value={
                      props.values.settings_config.emailConfig.couponTitle
                    }
                    onChange={(e) => {
                      props.setFieldValue(
                        "settings_config.emailConfig.couponTitle",
                        e.target.value
                      );
                    }}
                    name="basic"
                  />
                </CustomiserUnit>

                <CustomiserUnit
                  title="CTA Button Text"
                  description="This button will take the user to your website (with coupon applied)"
                >
                  <Textfield
                    value={props.values.settings_config.emailConfig.cta}
                    onChange={(e) => {
                      props.setFieldValue(
                        "settings_config.emailConfig.cta",
                        e.target.value
                      );
                    }}
                    name="basic"
                  />
                </CustomiserUnit>

                <CustomiserUnit title="Text Below CTA Button">
                  <TextArea
                    value={
                      props.values.settings_config.emailConfig.subCta
                    }
                    onChange={(e) => {
                      props.setFieldValue(
                        "settings_config.emailConfig.subCta",
                        e.target.value
                      );
                    }}
                    name="basic"
                    minimumRows={1}
                  />
                </CustomiserUnit>
              </div>
            </>
          ) : (
            <>
              <div
                key="2"
                className="max-w-xl py-4 border-b py-5 desktop:px-8 px-4 "
              >
                <h3 className="font-bold mb-3">Gift Emails</h3>
                <p>
                  A gift email is sent to a referrer once they signs up to a
                  viral referral program. This email will contain the gift
                  coupon which they are supposed to share with their friends
                </p>
              </div>
              <div className=" desktop:py-3 desktop:px-8 px-4">
                <CustomiserUnit
                  key="giftEmailSubject"
                  title="Email Subject"
                  description="Subject line for the email"
                >
                  <TextArea
                    value={
                      props.values.settings_config.giftEmailConfig.subject
                    }
                    onChange={(e) => {
                      props.setFieldValue(
                        "settings_config.giftEmailConfig.subject",
                        e.target.value
                      );
                    }}
                    name="basic"
                    minimumRows={1}
                  />
                </CustomiserUnit>

                <CustomiserUnit
                  title="Theme color"
                  description="Select the theme color of the email"
                >
                  <ColorPicker
                    color={
                      props.values.settings_config.giftEmailConfig.themeColor
                    }
                    onChange={(color) => {
                      props.setFieldValue(
                        "settings_config.giftEmailConfig.themeColor",
                        convertRGB(color.rgb)
                      );
                    }}
                  />
                </CustomiserUnit>
                <CustomiserUnit
                  title="Foreground color"
                  description="Select the foreground color"
                >
                  <TabRadioButton
                    defaultValue={
                      props.values.settings_config.giftEmailConfig.fgColor
                    }
                    options={[
                      { value: "white", label: "Light" },
                      { value: "black", label: "Dark" },
                    ]}
                    onChange={(e) => {
                      props.setFieldValue(
                        "settings_config.giftEmailConfig.fgColor",
                        e
                      );
                    }}
                  />
                </CustomiserUnit>

                <CustomiserUnit
                  title="Logo URL"
                  description="Image URL of your logo. This image will be showcased above the email"
                >
                  <Textfield
                    value={
                      props.values.settings_config.giftEmailConfig.logoUrl
                    }
                    onChange={(e) => {
                      props.setFieldValue(
                        "settings_config.giftEmailConfig.logoUrl",
                        e.target.value
                      );
                    }}
                    name="basic"
                  />
                </CustomiserUnit>

                <CustomiserUnit title="Shop Name">
                  <Textfield
                    value={
                      props.values.settings_config.giftEmailConfig.shopName
                    }
                    onChange={(e) => {
                      props.setFieldValue(
                        "settings_config.giftEmailConfig.shopName",
                        e.target.value
                      );
                    }}
                    name="basic"
                  />
                </CustomiserUnit>

                <CustomiserUnit title="Title">
                  <Textfield
                    value={
                      props.values.settings_config.giftEmailConfig.primaryTitle
                    }
                    onChange={(e) => {
                      props.setFieldValue(
                        "settings_config.giftEmailConfig.primaryTitle",
                        e.target.value
                      );
                    }}
                    name="basic"
                  />
                </CustomiserUnit>

                <CustomiserUnit title="Greeting">
                  <Textfield
                    value={
                      props.values.settings_config.giftEmailConfig.greeting
                    }
                    onChange={(e) => {
                      props.setFieldValue(
                        "settings_config.giftEmailConfig.greeting",
                        e.target.value
                      );
                    }}
                    name="basic"
                  />
                </CustomiserUnit>

                <CustomiserUnit title="Main Content">
                  <TextArea
                    value={
                      props.values.settings_config.giftEmailConfig.content
                    }
                    onChange={(e) => {
                      props.setFieldValue(
                        "settings_config.giftEmailConfig.content",
                        e.target.value
                      );
                    }}
                    name="basic"
                    minimumRows={7}
                  />
                </CustomiserUnit>

                <CustomiserUnit
                  title="Coupon code label"
                  description="This text will be shown above the coupon code followed by the coupon value"
                >
                  <Textfield
                    value={
                      props.values.settings_config.giftEmailConfig.couponTitle
                    }
                    onChange={(e) => {
                      props.setFieldValue(
                        "settings_config.giftEmailConfig.couponTitle",
                        e.target.value
                      );
                    }}
                    name="basic"
                  />
                </CustomiserUnit>

                {/* <CustomiserUnit
                  title="CTA Button Text"
                  description="This button will take the user to your website (with coupon applied)"
                >
                  <Textfield
                    value={
                      props.values.settings_config.giftEmailConfig.cta
                    }
                    onChange={(e) => {
                      props.setFieldValue(
                        "settings_config.giftEmailConfig.cta",
                        e.target.value
                      );
                    }}
                    name="basic"
                  />
                </CustomiserUnit> */}

                <CustomiserUnit title="Text Below CTA Button">
                  <TextArea
                    value={
                      props.values.settings_config.giftEmailConfig.subCta
                    }
                    onChange={(e) => {
                      props.setFieldValue(
                        "settings_config.giftEmailConfig.subCta",
                        e.target.value
                      );
                    }}
                    name="basic"
                    minimumRows={1}
                  />
                </CustomiserUnit>
              </div>
            </>
          )}
        </div>
        <div className="flex relative justify-center grow bg-gray-100 items-start">
          {emailType === "rewardEmail" ? (
            <EmailPreview
              themeColor={props.values.settings_config.emailConfig.themeColor}
              primaryTitle={
                props.values.settings_config.emailConfig.primaryTitle
              }
              greeting={props.values.settings_config.emailConfig.greeting}
              content={props.values.settings_config.emailConfig.content}
              couponTitle={props.values.settings_config.emailConfig.couponTitle}
              // shopName={props.values.settings_config.emailConfig.shopName}
              cta={props.values.settings_config.emailConfig.cta}
              subCta={props.values.settings_config.emailConfig.subCta}
              logoUrl={props.values.settings_config.emailConfig.logoUrl}
              fgColor={props.values.settings_config.emailConfig.fgColor}
              subject={props.values.settings_config.emailConfig.subject}
            />
          ) : (
            <EmailPreview
              themeColor={
                props.values.settings_config.giftEmailConfig.themeColor
              }
              primaryTitle={
                props.values.settings_config.giftEmailConfig.primaryTitle
              }
              greeting={props.values.settings_config.giftEmailConfig.greeting}
              content={props.values.settings_config.giftEmailConfig.content}
              couponTitle={
                props.values.settings_config.giftEmailConfig.couponTitle
              }
              shopName={props.values.settings_config.giftEmailConfig.shopName}
              // cta={props.values.settings_config.giftEmailConfig.cta}
              subCta={props.values.settings_config.giftEmailConfig.subCta}
              logoUrl={props.values.settings_config.giftEmailConfig.logoUrl}
              fgColor={props.values.settings_config.giftEmailConfig.fgColor}
              subject={props.values.settings_config.giftEmailConfig.subject}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default EmailSettings;
