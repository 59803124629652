import React, { useState, useEffect } from "react";
import {
  RiSmartphoneLine,
  RiSmartphoneFill,
  RiComputerFill,
  RiComputerLine,
  RiRefreshFill,
} from "react-icons/ri";

import { useWorkspace } from "../../../../../WorkspaceContext";
import LoadingSpinner from "../../../../../components/LoadingSpinner";

const ThankYouPreview = (props) => {
  const workspace = useWorkspace();
  const [previewMode, setPreviewMode] = useState("desk");
  const [basicConfig, setBasicConfig] = useState();

  useEffect(() => {
    const url = `${process.env.REACT_APP_STARLINK_URL}/offer/config/${workspace.data.storeMyShopifyUrl}/conf.json`;

    const fetchData = async () => {
      try {
        const response = await fetch(url);
        const json = await response.json();
        setBasicConfig(json);
      } catch (error) {
        console.error("Failed to fetch data in preview", error);
      }
    };

    fetchData();
  }, []);

  let previewConfig = basicConfig && {
    config: {
      referral: {
        featureFlag: {
          corner_widget: false,
          is_billing_activated: true,
        },
        viralReferrals: [
          {
            campaignId: 56,
            priority: 1,
            type: "VIRAL_REFERRAL",
            rewardStage: props.rewardConfig,
            giftConfig: props.giftConfig,
            name: "Viral Referral 2",
            offerTerms: props.offerTerms,
            thankyouPageConfig: props.config,
            placement: {
              cornerWidget: false,
              thankyouPage: true,
            },
            giftPageColor: "rgb(62,119,255)",
          },
        ],
        simpleReferrals: [],
      },
      widgetConfig: {
        commonConfig: basicConfig.config.widgetConfig.commonConfig,
        launcherConfig: basicConfig.config.widgetConfig.launcherConfig,
        langConfig: {
          defaultLanguage: workspace.data.default_language,
          offerWidget: basicConfig.config.widgetConfig.langConfig.offerWidget,
          offerPage: basicConfig.config.widgetConfig.langConfig.offerPage,
        },
      },
    },
  };

  const triggerPreviewConfigChange = () => {
    if (document.getElementById("preview-iframe")) {
      document
        .getElementById("preview-iframe")
        .contentWindow.postMessage(JSON.stringify(previewConfig), "*");
    }
  };

  useEffect(() => {
    triggerPreviewConfigChange();
  }, [props]);

  if (basicConfig) {
    return (
      <div
        style={{
          height: "700px",
          width: previewMode === "desk" ? "700px" : "350px",
        }}
        className=" bg-white shadow-lg rounded-lg overflow-hidden flex flex-col transition-all "
      >
        <div className="w-full h-8 bg-blue-100 flex pt-1">
          <div className="flex h-full items-center mb-2">
            <div className="w-2 h-2 mr-2 ml-2 rounded-full bg-red-500" />
            <div className="w-2 h-2 mr-2 rounded-full bg-amber-400" />
            <div className="w-2 h-2 mr-2 rounded-full bg-green-400" />
          </div>
          <div className="flex items-end ml-3">
            <div className="bg-white w-40 h-4/5 rounded-t-md" />
          </div>
        </div>

        <div className="flex border-b p-3 shadow-sm ">
          <button
            onClick={() => setPreviewMode("desk")}
            className={`flex items-center border rounded-l-full py-1 pl-2 pr-2 focus:outline-none ${
              previewMode === "desk" && "border-blue-600 text-blue-600"
            }`}
          >
            {previewMode === "desk" ? <RiComputerFill /> : <RiComputerLine />}
            <span className="ml-1 text-xs">Desktop</span>
          </button>
          <button
            onClick={() => setPreviewMode("mob")}
            className={`flex items-center border rounded-r-full py-1 pr-2 pl-2 focus:outline-none ${
              previewMode === "mob" && "border-blue-600 text-blue-600 "
            }`}
          >
            {previewMode === "mob" ? (
              <RiSmartphoneFill />
            ) : (
              <RiSmartphoneLine />
            )}
            <span className="ml-1 text-xs">Mobile</span>
          </button>
          <div className="grow py-1 px-4 mx-3 font-bold rounded-full border text-xs">
            Preview
          </div>
          <button
            onClick={triggerPreviewConfigChange}
            className="border rounded-full px-3 text-gray-500 hover:border-blue-400 hover:text-blue-400 flex items-center focus:outline-none"
          >
            <span className="text-md">
              <RiRefreshFill />{" "}
            </span>
            {previewMode === "desk" && (
              <span className="ml-2 font-bold text-xs ">Reload Preview</span>
            )}
          </button>
        </div>
        <div id="iframe-wrapper" className="flex flex-col w-full">
          <iframe
            id="preview-iframe"
            className="transition-all "
            onLoad={() => {
              setTimeout(triggerPreviewConfigChange, 1000);
            }}
            style={{ transform: "scale(.66)", transformOrigin: "0% 0%" }}
            width={previewMode === "desk" ? "1060px" : "530px"}
            height="1024px"
            src={`https://corner-thankyou-page-preview.netlify.app/?currency=${workspace.data.storeCurrency}`}
            title="Thank you page"
          />
        </div>
      </div>
    );
  } else return <LoadingSpinner />;
};

export default ThankYouPreview;
