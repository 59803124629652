import React, { useState } from "react";
import Button from "../../../../components/Button";
import { BsLink45Deg } from "react-icons/bs";
import { RiCheckboxBlankCircleFill } from "react-icons/ri";

/**
 * An UI element designed to show Integration card.
 * @component
 * @param {String} title - The title
 * @param {String} description - description
 * @param {Boolean} onDisconnect - To disconnect integration api
 * @param {Node} children - contents of the integrationcard
 */

export default function IntegrationCard({title, description, onDisconnect, children}) {
  
  return (
      <div>
            <div className="border rounded-md p-4 my-3 desktop:flex items-center justify-between max-w-4xl">
                <div className="flex items-center desktop:text-3xl text-2xl text-black-600">
                    <div>
                        <h3 className="font-bold text-base desktop:text-lg">
                        {title}
                        </h3>
                        <p className="text-sm desktop:text-base text-gray-600 w-96">
                        {description}
                        </p>
                    </div>
                </div>
                <div className="flex mobile:mt-3 laptop:mt-0">
                    <span className="flex items-center mt-2 mr-1 border  border-green-400 rounded-xl p-3 h-5">
                        <RiCheckboxBlankCircleFill className="flex-none text-green-400 mr-1 text-xs" />{" "}
                        <span className="text-green-400">Active</span>{" "}
                    </span>
                    <Button
                        onClick={() => onDisconnect()}
                        type="link"
                        icon={<BsLink45Deg />}
                    >
                        Disconnect
                    </Button>
                </div> 
            </div>
      </div>
  );
}
